import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const Callback = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    useEffect(() => {
        const opener = window.opener as Window | null;
        opener?.postMessage(code, opener.origin);
        window.close();
    }, []);

    return <></>;
};

export default Callback;
