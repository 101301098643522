/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { $isTableRowNode, TableCellNode, TableNode } from '@lexical/table';
import invariant from '../invariant';
import { LexicalNode } from 'lexical';

export type TableMapValueType = {
    cell: TableCellNode;
    startRow: number;
    startColumn: number;
};
export type TableMapType = Array<Array<TableMapValueType>>;

export function $computeTableMapSkipCellCheck(
    grid: TableNode,
    cellA: null | TableCellNode,
    cellB: null | TableCellNode
): [TableMapType, TableMapValueType | null, TableMapValueType | null] {
    const tableMap: TableMapType = [];
    let cellAValue: null | TableMapValueType = null;
    let cellBValue: null | TableMapValueType = null;
    function write(startRow: number, startColumn: number, cell: TableCellNode) {
        const value = {
            cell,
            startColumn,
            startRow
        };
        const rowSpan = cell.__rowSpan;
        const colSpan = cell.__colSpan;
        for (let i = 0; i < rowSpan; i++) {
            if (tableMap[startRow + i] === undefined) {
                tableMap[startRow + i] = [];
            }
            for (let j = 0; j < colSpan; j++) {
                tableMap[startRow + i][startColumn + j] = value;
            }
        }
        if (cellA !== null && cellA.is(cell)) {
            cellAValue = value;
        }
        if (cellB !== null && cellB.is(cell)) {
            cellBValue = value;
        }
    }
    function isEmpty(row: number, column: number) {
        return tableMap[row] === undefined || tableMap[row][column] === undefined;
    }

    const gridChildren = grid.getChildren();
    for (let i = 0; i < gridChildren.length; i++) {
        const row = gridChildren[i];
        invariant($isTableRowNode(row), 'Expected GridNode children to be TableRowNode');
        const rowChildren = row.getChildren();
        let j = 0;
        for (const cell of rowChildren) {
            invariant($isTableCellNode(cell), 'Expected TableRowNode children to be TableCellNode');
            while (!isEmpty(i, j)) {
                j++;
            }
            write(i, j, cell);
            j += cell.__colSpan;
        }
    }
    return [tableMap, cellAValue, cellBValue];
}
export function $isTableCellNode(node: LexicalNode | null | undefined): node is TableCellNode {
    return node instanceof TableCellNode;
}
