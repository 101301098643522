import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

function Error() {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error') ?? '';
    useEffect(() => {
        const opener = window.opener as Window | null;
        if (opener) {
            opener?.postMessage(error, opener.origin);
            window.close();
        }
    }, []);
    return (
        <MainCard title="error">
            <Stack direction="column">
                {error && (
                    <Typography variant="h2">
                        <FormattedMessage id={error} defaultMessage={error} />
                    </Typography>
                )}
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        <FormattedMessage id="backToHomePage" />
                    </Button>
                </div>
            </Stack>
        </MainCard>
    );
}

export default Error;
