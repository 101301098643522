import { useState, createContext, FC, Dispatch, SetStateAction } from 'react';

import { Loading } from 'ui-component/Loading';

const LoadingContext = createContext<Dispatch<SetStateAction<boolean>>>(() => false);

const LoadingProvider: FC = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <LoadingContext.Provider value={setOpen}>
            {children}
            <Loading open={open} />
        </LoadingContext.Provider>
    );
};

export { LoadingProvider, LoadingContext };
