import React, { Fragment } from 'react';
import { Formik, FormikHelpers, FormikErrors } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { inspect } from 'util';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Button, Step, Stepper, StepLabel, Stack, Typography, Box, Grid } from '@mui/material';
import { Print } from '@mui/icons-material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { RenderSection, RenderSectionProps } from './RenderSection';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { SignatureSection } from './SignatureSection';
import { OrganizationSignatureSection } from './OrganizationSignatureSection';
import { Form, Section, SignatureMethod } from 'types/form';
import useAuth from '../../../hooks/useAuth';
import { FormContext } from './FormContext';
import { Home } from 'types/home';
import useApiClient from '../../../hooks/useApiClient';
import useConfig from '../../../hooks/useConfig';
import { useLoading } from 'hooks/useLoading';
import { saveAs } from 'file-saver';

interface ValidationWizardProps {
    metadata: Form;
    data: any;
    next?: (values: any) => void;
    preview?: boolean;
    handleSubmitForm?: (values: any, signature: any, organizationSignature: any) => void;
    handleSave?: (values: any, jump?: boolean) => void;
    enableSubmit?: boolean;
    enableSave?: boolean;
    handleSign?: (values: any) => Promise<{ hkic?: string; application?: any; applicationObj?: any }>;
    getHash?: (values: any) => Promise<string>;
    formId: string;
}

// ==============================|| FORMS WIZARD - BASIC ||============================== //

const ValidationWizard = ({
    metadata,
    data,
    next,
    preview = false,
    handleSubmitForm,
    handleSave,
    enableSubmit = true,
    enableSave = false,
    handleSign,
    getHash,
    formId
}: ValidationWizardProps) => {
    const navigate = useNavigate();
    const loading = useLoading();
    const confirmDialog = useConfirmDialog();
    const [activeStep, setActiveStep] = React.useState(0);
    const [errorIndex, setErrorIndex] = React.useState<number | null>(null);
    const [signature, setSignature] = React.useState<any | undefined>(undefined);
    const [organizationSignature, setOrganizationSignature] = React.useState<any | undefined>(undefined);
    const { isLoggedIn, user } = useAuth();
    const intl = useIntl();
    const { applicationService, publicService } = useApiClient();
    const homeType = formId.split('_')[1];
    const appType = formId.split('_')[0];

    const onSubmitForm = (values: any, action: FormikHelpers<any>) => {
        setActiveStep(activeStep + 1);
        setErrorIndex(null);
        next && next(values);
    };

    const handleBack = () => {
        setErrorIndex(null);
        setActiveStep(activeStep - 1);
        setSignature(undefined);
        setOrganizationSignature(undefined);
    };

    const handleNext = async (
        validateForm: (values?: any) => Promise<FormikErrors<any>>,
        handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
        touched: any
    ) => {
        // console.info(`props:${inspect(props)}`);
        const errors = await validateForm();
        console.info(`ValidationWizard handleNext errors:${inspect(errors)}`);
        console.info(`ValidationWizard handleNext touched:${inspect(touched)}`);
        if (isEmpty(errors)) {
            handleSubmit();
        } else {
            confirmDialog?.setDialog({
                title: 'error',
                titleSx: { color: 'red' },
                content: 'error.dataValidation',
                cancelBtn: 'ok'
            });
            setErrorIndex(activeStep);
        }
    };

    const submitApp = async (values: any) => {
        console.log(values);
        console.info(`submitApp`);
        if (handleSubmitForm) {
            handleSubmitForm(values, signature, organizationSignature);
        }
    };

    const saveAsDraft = async (values: any, jump = true) => {
        console.log(values);
        console.info(`save`);
        if (handleSave) {
            handleSave(values, jump);
        }
    };

    const handleExit = () => {
        if (['NEW', 'OTHER', 'RENEWAL', 'CLOSURE'].some((item) => formId.includes(item))) {
            navigate(isLoggedIn ? '/user/licence-application' : '/licence-application', {
                replace: true
            });
            return;
        }
        navigate('/user/home-task-submission', { replace: true });
    };

    const handleGeneratePDF = (formData: any) => {
        const exportPDF = async () => {
            try {
                loading(true);

                const encodedData = Buffer.from(JSON.stringify({ values: formData })).toString('base64');

                const requestParam = {
                    unstructureDatas: [{ data_content: encodedData, data_type_key: 'values' }],
                    applicationType: appType
                };
                const response: { fileContent: string; fileExt: string; fileName: string } =
                    await applicationService.generatePDFOfPendingForm(requestParam);

                if (response) {
                    const { fileContent, fileExt, fileName } = response;
                    const filename = fileName.concat('.', fileExt);
                    const decodedFileContent = Buffer.from(fileContent, 'base64');
                    saveAs(new Blob([decodedFileContent]), filename);
                    // download(new Blob([decodedFileContent]), filename, 'application/octet-stream');
                }
            } catch (e) {
                console.error(e);
            } finally {
                loading(false);
            }
        };

        exportPDF();
    };

    const onSignClick: any = (values: any) => {
        if (handleSign) {
            return handleSign(values);
        }
        return undefined;
    };

    const getHashFallback = async (values: any) => {
        const dataToSign = { ...values };
        Object.keys(dataToSign).forEach((key) => {
            const current = dataToSign[key];
            if (current?.signature) {
                const dataWithOutSignature = (({ signature: s, ...rest }) => rest)(current);
                dataToSign[key] = dataWithOutSignature;
            }
        });
        const result = await publicService.getApplicationHashCode({ applicationObj: { values: dataToSign, applicationType: appType } });
        if (result) {
            return result.hash;
        }
        return undefined;
    };

    const onSignSuccess = (values: any) => {
        setSignature(values);
    };

    const onSignCancel = () => {
        setSignature(undefined);
    };

    const onOrganizationSignSuccess = (values: any) => {
        setOrganizationSignature(values);
    };

    const onOrganizationSignCancel = () => {
        setOrganizationSignature(undefined);
    };

    const needSign = (type: SignatureMethod, values: any) => {
        const signatureMethod = metadata.signatures?.find((s) => s.type === type);
        if (signatureMethod) {
            if (signatureMethod.condition) {
                return signatureMethod.condition(values);
            }
            return true;
        }
        return false;
    };

    const dynamicSections = (sections: Section[], values: any): RenderSectionProps[] => {
        const list: RenderSectionProps[] = [];
        sections.forEach((s) => {
            if (s.dataExtractor && s.dataExtractor({ values }) instanceof Array) {
                s.dataExtractor({ values }).forEach((d, index) => {
                    list.push({ section: s, data: d, index });
                });
            } else {
                list.push({ section: s });
            }
        });
        return list;
    };

    return (
        <FormContext.Provider value={{ formId, appType, saveAsDraft, getUnstructuredFormHash: getHashFallback }}>
            {!preview && (
                <MainCard
                    title={`${metadata.name}.form.name`}
                    sx={{
                        flexBasis: '100%',
                        maxWidth: '100%',
                        '& .MuiTypography-root.MuiCardHeader-title': {
                            fontSize: '2.5em',
                            fontWeight: 700
                        }
                    }}
                >
                    {data && (
                        <Formik
                            initialValues={data}
                            onSubmit={(values, action) => {
                                onSubmitForm(values, action);
                            }}
                            validateOnChange
                        >
                            {({ handleSubmit, touched, validateForm, values }) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Box>
                                        <Stepper activeStep={activeStep} sx={{ pb: 5 }} orientation="vertical">
                                            {dynamicSections(metadata.sections, values).map((sect, index) => {
                                                const labelProps: { error?: boolean; optional?: React.ReactNode } = {};
                                                const label = intl.formatMessage(
                                                    { id: sect.section.labelKey },
                                                    sect.section.labelKeyParameter?.({ index: sect.index }) || {}
                                                );
                                                if (index === errorIndex) {
                                                    labelProps.optional = (
                                                        <Typography key={index} variant="caption" color="error">
                                                            <FormattedMessage id="Error" />
                                                        </Typography>
                                                    );

                                                    labelProps.error = true;
                                                }

                                                return (
                                                    <Step
                                                        onClick={() => {
                                                            if (index < activeStep) {
                                                                setActiveStep(index);
                                                            }
                                                        }}
                                                    >
                                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })}
                                            {formId !== 'MONTHLYSTAT_rchd' &&
                                                formId !== 'MONTHLYSTAT_rche' &&
                                                formId !== 'TE_rchd' &&
                                                formId !== 'TE_rche' &&
                                                formId !== 'SID_rchd' &&
                                                formId !== 'SID_rche' && (
                                                    <Step key="revieww">
                                                        <StepLabel>
                                                            <FormattedMessage id="appOrgign.header" />
                                                        </StepLabel>
                                                    </Step>
                                                )}
                                            {formId === `NEW_${homeType}` && (
                                                <Step key="acknowledge">
                                                    <StepLabel>
                                                        <FormattedMessage id="acknowledgement.header" />
                                                    </StepLabel>
                                                </Step>
                                            )}
                                        </Stepper>
                                    </Box>

                                    <Box paddingLeft={3} width="100%">
                                        {activeStep < dynamicSections(metadata.sections, values).length &&
                                            !preview &&
                                            dynamicSections(metadata.sections, values).map((sect, index) => {
                                                if (activeStep === index) {
                                                    // console.info(`matched form section activeStep:${activeStep} index:${index}`);
                                                    return (
                                                        <>
                                                            <RenderSection key={index} {...sect} />
                                                            <Stack direction="row" justifyContent="flex-end">
                                                                <Button variant="contained" onClick={handleExit} sx={{ my: 3, ml: 1 }}>
                                                                    <FormattedMessage id="exit" />
                                                                </Button>
                                                                {/** SER Exporting PDF */}
                                                                {appType === 'SER' && sect.section.name === 'declaration' && (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="info"
                                                                        onClick={() => handleGeneratePDF(values)}
                                                                        sx={{ my: 3, ml: 1 }}
                                                                    >
                                                                        <Print />
                                                                        <Typography variant="h6" color="white">
                                                                            <FormattedMessage id="exportPDF" />
                                                                        </Typography>
                                                                    </Button>
                                                                )}
                                                                {activeStep !== 0 && (
                                                                    <Button variant="contained" onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                                                        <FormattedMessage id="back" />
                                                                    </Button>
                                                                )}
                                                                {formId !== `NEW_${homeType}` && formId !== `TE_${homeType}` && enableSave && (
                                                                    <AnimateButton>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => saveAsDraft(values)}
                                                                            sx={{ my: 3, ml: 1 }}
                                                                        >
                                                                            <FormattedMessage id="save" />
                                                                        </Button>
                                                                    </AnimateButton>
                                                                )}
                                                                <AnimateButton>
                                                                    <Button
                                                                        variant="contained"
                                                                        onClick={() => handleNext(validateForm, handleSubmit, touched)}
                                                                        sx={{ my: 3, ml: 1 }}
                                                                    >
                                                                        <FormattedMessage id="next" />
                                                                    </Button>
                                                                </AnimateButton>
                                                            </Stack>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        {/* // sign page */}
                                        {(activeStep === dynamicSections(metadata.sections, values).length || preview) && (
                                            <>
                                                {dynamicSections(metadata.sections, values).map((sect, index) =>
                                                    sect.section.name === 'disclaimer' ? (
                                                        <></>
                                                    ) : (
                                                        <Fragment key={index}>
                                                            <RenderSection {...sect} disabled />
                                                            <Box mb={5} />
                                                        </Fragment>
                                                    )
                                                )}
                                                {enableSubmit && needSign('organization', values) && (
                                                    <>
                                                        <OrganizationSignatureSection
                                                            metadata={metadata.signatures?.find((s) => s.type === 'organization')}
                                                            onSignClick={() => onSignClick(values)}
                                                            onSignSuccess={onOrganizationSignSuccess}
                                                            onSignCancel={onOrganizationSignCancel}
                                                            getHash={getHash || getHashFallback}
                                                        />
                                                        <Box mb={5} />
                                                    </>
                                                )}
                                                {enableSubmit && needSign('iamsmart', values) && (
                                                    <>
                                                        <SignatureSection
                                                            metadata={metadata.signatures?.find((s) => s.type === 'iamsmart')}
                                                            onSignClick={() => onSignClick(values)}
                                                            onSignSuccess={onSignSuccess}
                                                            onSignCancel={onSignCancel}
                                                            getHash={getHash || getHashFallback}
                                                        />
                                                        <Box mb={5} />
                                                    </>
                                                )}
                                                {metadata.applicantSections?.map((sect, index) => (
                                                    <RenderSection key={index} section={sect} />
                                                ))}
                                                <Stack direction="row" justifyContent="flex-end">
                                                    {/** SID Exporting PDF */}
                                                    {appType === 'SID' && !preview && (
                                                        <Button
                                                            variant="contained"
                                                            color="info"
                                                            onClick={() => handleGeneratePDF(values)}
                                                            sx={{ my: 3, ml: 1 }}
                                                        >
                                                            <Print />
                                                            <Typography variant="h6" color="white">
                                                                <FormattedMessage id="exportPDF" />
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                    <Button variant="contained" onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                                        <FormattedMessage id="back" />
                                                    </Button>
                                                    {enableSubmit && (
                                                        <AnimateButton>
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => submitApp(values)}
                                                                sx={{ my: 3, ml: 1 }}
                                                                disabled={
                                                                    (needSign('iamsmart', values) && signature === undefined) ||
                                                                    (needSign('organization', values) &&
                                                                        organizationSignature === undefined)
                                                                }
                                                            >
                                                                <FormattedMessage id="submit" />
                                                            </Button>
                                                        </AnimateButton>
                                                    )}
                                                </Stack>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Formik>
                    )}
                </MainCard>
            )}
            {preview && data && (
                <>
                    <FormattedMessage id={`${metadata.name}.form.name`} />
                    <Formik
                        initialValues={data}
                        onSubmit={(values, action) => {
                            onSubmitForm(values, action);
                        }}
                        validateOnChange
                    >
                        <>
                            {metadata.sections.map((sect, index) => {
                                if (sect.name === 'disclaimer' || (appType === 'SH' && sect.name === 'applicant' && !data.applicant)) {
                                    return <></>;
                                }
                                return (
                                    <MainCard key={index} sx={{ mt: 3 }}>
                                        <RenderSection section={sect} disabled />
                                    </MainCard>
                                );
                            })}
                        </>
                    </Formik>
                </>
            )}
            <Grid sx={{ paddingTop: '10px' }} container justifyContent="flex-end">
                <Typography>
                    <span style={{ color: 'red' }}>*</span>
                    <span> </span>
                    <FormattedMessage id="mandateField" />
                </Typography>
            </Grid>
        </FormContext.Provider>
    );
};

export { ValidationWizard };
