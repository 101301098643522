import { useNavigate } from 'react-router-dom';

// material-ui
import { gridSpacing } from '../../../../store/constant';
import { Button, Divider, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';

// third party
import * as Yup from 'yup';
import { useLoading } from 'hooks/useLoading';
// project imports
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useFormik } from 'formik';

// assets
import { FormattedMessage, useIntl } from 'react-intl';
import useApiClient from '../../../../hooks/useApiClient';
import { Pattern } from 'constants/Pattern';

// ============================|| LOGIN ||============================ //
const ChangePasswordPage = () => {
    const confirmDialog = useConfirmDialog();
    const loading = useLoading();
    const { userService } = useApiClient();
    const intl = useIntl();

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        opassword: Yup.string().max(255).required('error.required'),
        password: Yup.string().max(255).required('error.required').matches(Pattern.password, 'update.password.policy'),
        password2: Yup.string()
            .required('error.required')
            .oneOf([Yup.ref('password'), null], 'update.password.notmatch')
    });

    const nagativeToHome = () => {
        navigate('/my-account/application', {});
    };
    const changePassword = async (values: any) => {
        const changePasswordParam: any = {
            old_password: values.opassword,
            password: values.password
        };
        try {
            loading(true);
            const result = await userService.changePassword(changePasswordParam);
            if (result) {
                confirmDialog?.setDialog({
                    title: 'confirmation',
                    content: 'update.password.box.popup',
                    actionBtn: 'ok',
                    onAction: nagativeToHome
                });
            }
        } finally {
            loading(false);
        }
    };
    const formik = useFormik({
        initialValues: {
            opassword: '',
            password: '',
            password2: '',
            submit: null
        },
        validationSchema,
        onSubmit: (values) => {
            changePassword(values);
        }
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12}>
                    <Stack>
                        <InputLabel htmlFor="outlined-adornment-password-login">
                            <FormattedMessage id="update.password.box.oldpassword" />:
                        </InputLabel>
                        <TextField
                            type="password"
                            id="opassword"
                            name="opassword"
                            value={formik.values.opassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.opassword && Boolean(formik.errors.opassword)}
                            helperText={
                                formik.touched.opassword && formik.errors.opassword && intl.formatMessage({ id: formik.errors.opassword })
                            }
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Stack>
                        <InputLabel htmlFor="outlined-adornment-password-login">
                            <FormattedMessage id="update.password.box.password" />:
                        </InputLabel>
                        <TextField
                            type="password"
                            id="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={
                                formik.touched.password && formik.errors.password && intl.formatMessage({ id: formik.errors.password })
                            }
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Stack>
                        <InputLabel htmlFor="outlined-adornment-password-login">
                            <FormattedMessage id="update.password.box.confrim.password" />:
                        </InputLabel>
                        <TextField
                            type="password"
                            id="password2"
                            name="password2"
                            value={formik.values.password2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password2 && Boolean(formik.errors.password2)}
                            helperText={
                                formik.touched.password2 &&
                                formik.errors.password2 &&
                                intl.formatMessage({ id: formik.errors.password2 }, { length: 8 })
                            }
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                        <pre style={{ fontFamily: 'inherit' }}>
                            <FormattedMessage id="hint" />
                            {'\n'}
                            <FormattedMessage id="update.password.policy" />
                        </pre>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                    <Button variant="contained" type="submit">
                        <FormattedMessage id="update.password.box.submit" />
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
export default ChangePasswordPage;
