import { memo, ChangeEvent } from 'react';
import { FormControl, OutlinedInput, FormLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';
import { acceptedIntegerPattern } from '../converter/integer';

interface IntegerFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const IntegerField = memo((props: IntegerFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        const numericValue = newValue.replace(/[^0-9]/g, '');
        if (acceptedIntegerPattern.test(numericValue)) {
            onChange(numericValue);
        } else {
            onChange(value);
        }
    };

    const inputProps = {
        maxLength: metadata.maxLength
    };

    return (
        <FormControl fullWidth>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <Grid item sm={metadata.width || 12}>
                <OutlinedInput id={name} name={name} value={value} onChange={onValueChange} inputProps={inputProps} disabled={disabled} />
            </Grid>
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { IntegerField };
