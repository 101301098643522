const acceptedIntegerPattern = /^[-0-9]*$/;
const integerPattern = /^[-]{0,1}[0-9]+$/;

const integer = (value: any) => {
    let result;
    if (typeof value === 'string') {
        if (integerPattern.test(value.trim())) {
            result = Number(value);
        }
    } else if (typeof value === 'number' && Number.isInteger(value)) {
        result = value;
    }

    return result;
};

export { integer, acceptedIntegerPattern };
