import { KeyedObject } from '../types';
import { ApplicationTypeCode } from '../types/selectionType';
import useConfig from '../hooks/useConfig';
import useGlobalConfig from '../hooks/useGlobalConfig';

interface ApplicationTypeValueProps extends KeyedObject {
    type: ApplicationTypeCode;
}

const ApplicationTypeValue = ({ type }: ApplicationTypeValueProps) => {
    const { locale } = useConfig();
    const { getApplicationTypeByTypeCode } = useGlobalConfig();

    const getValueByLang = (lang: string) => {
        const applicationType = getApplicationTypeByTypeCode(type);

        if (!applicationType) {
            return type;
        }
        switch (lang) {
            case 'en':
                return applicationType?.typeNameEn;
            case 'zh-HK':
                return applicationType?.typeNameTc;
            case 'zh-CN':
                return applicationType?.typeNameSc;
            default:
                return applicationType?.typeNameEn;
        }
    };

    return <>{getValueByLang(locale)}</>;
};

export { ApplicationTypeValue };
