import { FormControl, FormHelperText, TextField, FormLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { memo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { TimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface DateTimeFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const TimeField = memo((props: DateTimeFieldProps) => {
    const { name, value = null, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (date: any) => {
        const newValue = date;
        onChange(newValue);
    };

    // useEffect(() => {
    //     if (!value || JSON.stringify(value) === '{}') {
    //         onValueChange(null);
    //     }
    // }, []);

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    format="hh:mm a"
                    value={!value || JSON.stringify(value) === '{}' ? null : new Date(value)}
                    onChange={onValueChange}
                    disabled={disabled}
                    minTime={metadata.minTime}
                    maxTime={metadata.maxTime}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock
                    }}
                    slotProps={{
                        textField: {
                            name,
                            value: !value || JSON.stringify(value) === '{}' ? null : new Date(value),
                            onChange: onValueChange
                        },
                        actionBar: {
                            sx: { display: 'none' }
                        }
                    }}
                />
            </LocalizationProvider>
            {Boolean(getFieldMeta(name).error) && (
                <FormHelperText error id={name}>
                    <FormattedMessage id={getFieldMeta(name).error} />
                </FormHelperText>
            )}
        </FormControl>
    );
});

export { TimeField };
