import { useContext } from 'react';

import { LoadingContext } from 'contexts/LoadingContext';

const useLoading = () => {
    const context = useContext(LoadingContext);
    if (context === undefined) {
        throw new Error('useConfirmDialog must be used within a ConfirmDialogContext');
    }

    return context;
};

export { useLoading };
