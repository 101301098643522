import { Typography, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, getIn, useFormikContext } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';

import { RenderQuestion } from './RenderQuestion';
import { Section, LabelKeys } from 'types/form';
import { SignatureSection } from './SignatureSection';
import { FormContext } from './FormContext';
import { ErrorDisplay } from './Field/util';
import { verifySignature } from 'utils/xmlsig';

export interface RenderSectionProps {
    section: Section;
    disabled?: boolean;
    data?: any;
    index?: number;
}

const rednerLabelKeys = (_keys: LabelKeys[], values: any) =>
    _keys.map((_key) => {
        if (_key.condition) {
            const condition = _key.condition({ values });
            if (!condition) {
                return null;
            }
        }

        return (
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                <FormattedMessage id={_key.labelKey} />
            </Typography>
        );
    });

export const RenderSection = ({ data, section, disabled = false, index }: RenderSectionProps) => {
    // console.info(`RenderSection section:${JSON.stringify(section)} data:${data}`);
    const { values, setFieldValue, getFieldMeta, errors, validateForm } = useFormikContext<any>();
    const [shown, setShown] = useState(true);
    const name = useMemo(() => (section.name instanceof Function ? section.name(data, index) : section.name), [section, data, index]);
    const { getUnstructuredFormHash, appType } = useContext(FormContext);
    const intl = useIntl();
    const label = useMemo(
        () => intl.formatMessage({ id: section.labelKey }, section.labelKeyParameter ? section.labelKeyParameter({ index }) : {}),
        [index, intl, section]
    );
    useEffect(() => {
        if (section.condition) {
            const condition = section.condition({ values });
            // console.info(`result:${condition}`);
            setShown(condition);
            if (!condition) {
                setFieldValue(name, undefined, false);
            }
        }
    }, [section, setFieldValue, values]);

    // useEffect(() => {
    //     console.log('errors', errors);
    // }, [errors]);

    // useEffect(() => {
    //     if (shown) {
    //         validateForm();
    //     }
    // }, [shown]);
    return (
        <>
            {section.messageKey && (
                <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
                    <FormattedMessage id={section.messageKey} />
                </Typography>
            )}
            {section.labelKey && (
                <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
                    {label}
                </Typography>
            )}
            {section.labelKeys && section.labelKeys.length > 0 && rednerLabelKeys(section.labelKeys, values)}
            {!shown && (
                <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                    <FormattedMessage id="notApplicable" />
                </Typography>
            )}
            {shown && (
                <Grid container spacing={3}>
                    {section.questions
                        ?.filter((q) => !disabled || !q.protected)
                        .map((q, i) => (
                            <RenderQuestion key={i} name={name} question={q} disabled={disabled} appType={appType} />
                        ))}
                    {section?.signatures
                        ?.filter((s) => s.type === 'iamsmart')
                        .map((sectionSignature, i) => {
                            const signatureSectionName = `${name}.signature`;
                            console.log(signatureSectionName);
                            return (
                                <Grid item xs={12} sm={12} key={i}>
                                    <Field
                                        name={signatureSectionName}
                                        validate={async (_value: any) => {
                                            console.log(`${signatureSectionName}_value`, _value);
                                            if (!_value) {
                                                return 'error.required';
                                            }

                                            const sectionData = getIn(values, name);

                                            const hash = await getUnstructuredFormHash?.(
                                                sectionSignature?.dataToSign({
                                                    values,
                                                    data: sectionData,
                                                    index
                                                })
                                            );
                                            if (hash) {
                                                const verifyResult = verifySignature(_value.cert, hash, _value.signature);
                                                if (!verifyResult) {
                                                    return 'error.signAgain';
                                                }
                                            }
                                            return '';
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                    <SignatureSection
                                        metadata={sectionSignature}
                                        title={sectionSignature.title}
                                        name={signatureSectionName}
                                        disabled={disabled}
                                        value={getIn(values, signatureSectionName)}
                                        onSignClick={async () => ({
                                            hkic: sectionSignature!.hkic({ values, data: values[name], index }),
                                            applicationObj: {
                                                values: sectionSignature?.dataToSign({ values, data: values[name], index }),
                                                applicationType: appType
                                            }
                                        })}
                                        getHash={getUnstructuredFormHash}
                                        onSignSuccess={(v) => {
                                            console.log('onSignSuccess', signatureSectionName);
                                            setFieldValue(signatureSectionName, v);
                                        }}
                                        onSignCancel={() => {
                                            setFieldValue(signatureSectionName, undefined);
                                        }}
                                    />
                                    {Boolean(getFieldMeta(signatureSectionName).error) &&
                                        ErrorDisplay({ error: getFieldMeta(signatureSectionName).error, name })}
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </>
    );
};
