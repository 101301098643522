export interface IUserRole {
    id?: number;
    code?: string;
    roleNameEn?: string;
    roleNameTc?: string;
    roleNameSc?: string;
}

export class UserRole implements IUserRole {
    constructor(
        public id?: number,
        public code?: string,
        public roleNameEn?: string,
        public roleNameTc?: string,
        public roleNameSc?: string
    ) {
        this.id = id;
        this.code = code;
        this.roleNameEn = roleNameEn;
        this.roleNameTc = roleNameTc;
        this.roleNameSc = roleNameSc;
    }
}
