import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material-ui
import { Box, Grid, Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import { FormattedMessage } from 'react-intl';
import SWDLogo from 'assets/images/icons/swd_logo.svg';
// import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-start', width: { md: 400, lg: 600 } }}>
            <Grid container spacing={0}>
                {/* <Logo /> */}
                <Grid item xs={12}>
                    <Box onClick={() => navigate(DASHBOARD_PATH, { replace: true })} component="img" src={SWDLogo} alt="no image" />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                    <FormattedMessage id="header.logo" />
                </Grid>
            </Grid>
        </Box>
    );
};

export default LogoSection;
