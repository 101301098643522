import { ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { GenericCardProps } from 'types';
import { IMenuControl } from '../../../../../types/user/user-detail';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface SingleMenuItem {
    main: string;
    seq: number;
}
export interface MenuPermission extends SingleMenuItem {
    sub?: SingleMenuItem[];
}

export interface NavGroupProps {
    item: {
        id?: string;
        type?: string;
        icon?: GenericCardProps['iconPrimary'];
        children?: NavGroupProps['item'][];
        title?: ReactNode | string;
        caption?: ReactNode | string;
        color?: 'primary' | 'secondary' | 'default' | undefined;
    };
    permissions: IMenuControl[] | undefined | null;
}

const NavGroup = ({ item, permissions }: NavGroupProps) => {
    const theme = useTheme();

    const items = permissions?.map((p) => {
        // avoid menu permission with items not in local

        const localItem = item.children?.find((i) => i.id === p?.main);
        if (localItem?.type === 'collapse') {
            return <NavCollapse key={localItem.id} menu={localItem} level={1} permission={p} />;
        }
        if (localItem?.type === 'item') {
            return <NavItem key={localItem.id} item={localItem} level={1} />;
        }

        return null;
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

export default NavGroup;
