const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串

// Android
function AndroidBtType() {
    /*
    if (userAgent.indexOf('Baidu') > -1) { // 百度浏览器
      return 'Android_Baidu';
    }
    if (userAgent.indexOf('MQQBrowser') > -1) { // QQ浏览器
      return 'Android_QQ';
    }
    if (userAgent.indexOf('UCBrowser') > -1 || userAgent.indexOf('UCWEB') > -1) { // UC浏览器
      return 'Android_UC';
    }
   */
    if (userAgent.indexOf('SamsungBrowser') > -1) {
        // 三星
        return 'Android_Samsung';
    }
    if (userAgent.indexOf('MiuiBrowser') > -1 || userAgent.indexOf('XiaoMi') > -1) {
        // 小米
        return 'Android_Xiaomi';
    }
    if (userAgent.indexOf('HUAWEI') > -1 || userAgent.indexOf('HONORHRY') > -1 || userAgent.indexOf('Huawei') > -1) {
        // 华为浏览器
        return 'Android_Huawei';
    }
    if (userAgent.indexOf('EdgA') > -1) {
        // 微软Edge浏览器
        return 'Android_Edge';
    }
    if (userAgent.indexOf('Firefox') > -1) {
        // Firefox浏览器
        return 'Android_Firefox';
    }
    if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
        // chrome浏览器
        return 'Android_Chrome';
    }
    return 'Android_Other';
}

// iOS
function iOSBtType() {
    /*
  if (userAgent.indexOf('UCBrowser') > -1) { // UC浏览器
    return 'iOS_UC';
  }
  */
    if (userAgent.indexOf('FxiOS') > -1) {
        // Firefox浏览器
        return 'iOS_Firefox';
    }
    if (userAgent.indexOf('EdgiOS') > -1) {
        // 微软Edge浏览器
        return 'iOS_Edge';
    }
    if (userAgent.indexOf('CriOS') > -1) {
        // chrome浏览器
        return 'iOS_Chrome';
    }
    if (userAgent.indexOf('Safari') > -1) {
        // Safari浏览器
        return 'iOS_Safari';
    }
    return 'iOS_Other';
}

function check() {
    const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1; // android终端
    const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
        return 1;
    }
    if (isiOS) {
        return 2;
    }
    return 0;
}

export function browserType() {
    // alert(userAgent);
    const mobileType = check();
    if (mobileType === 1) {
        return AndroidBtType();
    }
    if (mobileType === 2) {
        return iOSBtType();
    }
    return 'PC_Browser';
}
