import { useContext } from 'react';

import { ConfirmDialogContext } from 'contexts/ConfirmDialogContext';

const useConfirmDialog = () => {
    const context = useContext(ConfirmDialogContext);
    if (context === undefined) {
        throw new Error('useConfirmDialog must be used within a ConfirmDialogContext');
    }

    return context;
};

export { useConfirmDialog };
