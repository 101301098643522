export interface ISelectionValue {
    id?: number;
    dataValue?: string;
    displayValueEn?: string;
    displayValueTc?: string;
    displayValueSc?: string;
    status?: string;
    updatedBy?: string;
    updatedDate?: Date;
}

export class SelectionValue implements ISelectionValue {
    constructor(
        public id?: number,
        public dataValue?: string,
        public displayValueEn?: string,
        public displayValueTc?: string,
        public displayValueSc?: string,
        public status?: string,
        public updatedBy?: string,
        public updatedDate?: Date
    ) {
        this.id = id;
        this.dataValue = dataValue;
        this.displayValueEn = displayValueEn;
        this.displayValueTc = displayValueTc;
        this.displayValueSc = displayValueSc;
        this.status = status;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
    }
}
