import { memo, useRef } from 'react';
import { Field, useFormikContext, getIn } from 'formik';

import { StringField } from './Field/StringField';
import { CheckBox } from './Field/CheckBox';
import { RadioField } from './Field/Radio';
import { Dropdown } from './Field/Dropdown';
import { HkidField } from './Field/HkidField';
import { Textarea } from './Field/TextArea';
import { DateField } from './Field/DateField';
import { DateTimeField } from './Field/DateTimeField';
import { TimeField } from './Field/TimeField';
import { FileUpload } from './Field/FileUpload';
import { NumberField } from './Field/NumberField';
import { IntegerField } from './Field/IntegerField';
import { SumField } from './Field/SumField';
import { AnyField } from './Field/AnyField';
import { validator } from './validator';
import { LabelField } from './Field/Label';
import { LabelList } from './Field/LabelList';
import { ADIField } from './Field/ADIField';
import { ValidationListener } from './ValidationListener';
import { useEventCallback } from '@mui/material';
import { FileDownload } from './Field/FileDownload';
import { RenderEventDateTimeField } from './Field/RenderEventDateTimeField';
import { RenderEventDateTimeStringField } from './Field/RenderEventDateTimeStringField';

interface FieldRendererProps {
    index?: number;
    name: string;
    // data: any;
    metadata: any;
    parentName: string;
    disabled: boolean;
    dependsFieldName?: string;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
}

const FieldRenderer = memo(({ index, name, metadata, parentName, disabled, dependsFieldName, variant }: FieldRendererProps) => {
    // console.info(`FieldRenderer name:${name} metadata:${JSON.stringify(metadata)}`);
    const { setFieldValue, setFieldTouched, values } = useFormikContext();
    const value = getIn(values, name);
    const staffPerson = getIn(values, 'staffInfo.staffPerson') || [];
    const dependsValue = dependsFieldName ? getIn(values, dependsFieldName) : undefined;
    const childRef = useRef<ValidationListener>();
    const fieldChildElement = () => {
        if (metadata.type === 'String') {
            return <StringField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Checkbox') {
            return <CheckBox name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'OptionList') {
            let optionValue = value;
            if (!value && metadata.defaultValue) {
                optionValue = metadata.defaultValue;
                setFieldValue(name, optionValue);
                setFieldTouched(name, false, false);
            }

            return (
                <RadioField
                    name={name}
                    value={optionValue}
                    metadata={metadata}
                    onChange={onChange}
                    parentName={parentName}
                    disabled={disabled}
                    variant={variant}
                />
            );
        }
        if (metadata.type === 'Dropdown') {
            return <Dropdown name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} variant={variant} />;
        }
        if (metadata.type === 'Hkid') {
            return <HkidField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Textarea') {
            return <Textarea name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} variant={variant} />;
        }
        if (metadata.type === 'Date') {
            return <DateField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Time') {
            return <TimeField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'DateTime') {
            return <DateTimeField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'File') {
            return <FileUpload name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Number') {
            return <NumberField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Integer') {
            return <IntegerField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'Sum') {
            return <SumField name={name} metadata={metadata} />;
        }
        if (metadata.type === 'Label') {
            return <LabelField metadata={metadata} />;
        }
        if (metadata.type === 'LabelList') {
            return <LabelList metadata={metadata} />;
        }
        if (metadata.type === 'ADI') {
            return <ADIField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} ref={childRef} />;
        }
        if (metadata.type === 'Any') {
            return <AnyField name={name} metadata={metadata} onChange={onChange} />;
        }
        if (metadata.type === 'FileDownload') {
            return <FileDownload name={name} value={value} metadata={metadata} />;
        }
        if (metadata.type === 'RenderEventDateTime') {
            return <RenderEventDateTimeField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        if (metadata.type === 'RenderEventDateTimeString') {
            return <RenderEventDateTimeStringField name={name} value={value} metadata={metadata} onChange={onChange} disabled={disabled} />;
        }
        return null;
    };

    const onChange = (_value: any) => {
        // console.info(`Field renderer onChange name:${name} value:${JSON.stringify(_value)}`);
        metadata.setOtherValues && metadata.setOtherValues({ name, setFieldValue, value: _value });
        setFieldValue(name, _value);
        setFieldTouched(name, true, false);
        if (metadata.targetField) {
            metadata?.targetField?.forEach((eachField: any) => setFieldValue(eachField, _value));
        }
    };

    const validatorCallback = useEventCallback((v) => validator(v, metadata, dependsValue, staffPerson, index));

    return (
        <Field
            name={name}
            validate={(_value: any) => {
                if (metadata.type === 'ADI') {
                    return childRef.current?.onValidate();
                }

                return validatorCallback(_value);
            }}
        >
            {fieldChildElement}
        </Field>
    );
});

export { FieldRenderer };
