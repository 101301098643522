import { memo, ChangeEvent, createRef } from 'react';
import { FormControl, FormLabel, Box, Typography, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import path from 'path';

import { ErrorDisplay } from './util';
import { saveFile, displayFileSize } from 'utils/file';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import useApiClient from 'hooks/useApiClient';

interface FileUploadProps {
    name: string;
    value: any;
    metadata: {
        labelKey: string;
        required: boolean;
        maxFiles: number;
        maxFileSize: number;
        format: string[];
        messageKey?: string;
    };
    onChange: (v: any) => void;
    disabled: boolean;
}

const previewExt = ['.jpg', '.jpeg', '.png'];

const FileUpload = memo((props: FileUploadProps) => {
    const { name, value, metadata, onChange, disabled } = props;
    const fileRef = createRef<HTMLInputElement>();
    const confirmDialog = useConfirmDialog();
    const { systemService } = useApiClient();

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        const filesNumber = files?.length || 0;
        const file = files?.item(0);
        // currently only support single file upload
        if (!files || !file || filesNumber > 1) {
            // gov-form-core multiple files dragged into single file upload results in no response
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            onFileUploaded(file.name, file.size, reader.result);
        };
    };

    const onFileUploaded = (fileName: string, fileSize: number, dataUrl: string | ArrayBuffer | null) => {
        const fileExt = path.extname(fileName);
        const extAllowed = metadata.format.includes(fileExt);

        if (!extAllowed) {
            // TODO show popup
            confirmDialog?.setDialog({
                title: 'error',
                content: 'error.file.extension',
                contentParams: {
                    format: metadata.format.join(',')
                },
                actionBtn: 'ok'
            });
            console.info(`Extension not allow, file name:${fileName} extension:${fileExt}`);
            return;
        }

        if (fileSize > metadata.maxFileSize) {
            // TODO show popup

            const fileSizeObj = size(metadata.maxFileSize);
            confirmDialog?.setDialog({
                title: 'error',
                content: 'error.file.size',
                contentParams: {
                    ...fileSizeObj
                },
                actionBtn: 'ok'
            });
            console.info(`File size not allow, file size:${fileSize} limit:${metadata.maxFileSize}`);
            return;
        }

        // read as base64 data url
        dataUrl = dataUrl as string;
        // skip base64 metadata
        dataUrl = dataUrl.split(',')[1];

        onChange({
            fileName,
            fileSize,
            dataUrl
        });
    };

    const triggerFileUpload = () => {
        fileRef.current?.click();
    };

    const deleteFile = () => {
        onChange(undefined);
    };

    const donwloadFile = async () => {
        if (!isEmpty(value)) {
            let content = value.dataUrl;
            if (!content) {
                try {
                    const resp = await systemService.getDocumentContentById(value.id);
                    content = resp.file_content;
                } catch (error) {
                    console.error('File Retrieve Error');
                }
            }
            saveFile({
                content,
                name: value.fileName
            });
        }
    };

    const inputFormatLimit = metadata.format.join(',');

    const size = (bytes: number) => {
        const params: { [key: string]: any } = {};
        params.size = displayFileSize(bytes);
        return params;
    };

    const previewable = previewExt.includes(path.extname(value?.fileName));

    return (
        <FormControl fullWidth>
            <FormLabel>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
                <br />
                {metadata.messageKey && <FormattedMessage id={metadata.messageKey} />}
            </FormLabel>
            {value && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <i className="icon-attach" />
                        <Button
                            sx={{
                                backgroundColor: '#f6f6f6'
                            }}
                            onClick={donwloadFile}
                        >{`${value.fileName} (${size(value.fileSize).size})`}</Button>
                        {!disabled && (
                            <Button onClick={deleteFile}>
                                <i className="icon-cancel" />
                            </Button>
                        )}
                    </Box>
                    {/** TODO: id && youcannotpreview */}
                    {previewable && value.dataUrl && (
                        <Box
                            component="img"
                            src={`data:image/${path.extname(value.fileName)};base64,${value.dataUrl}`}
                            sx={{ maxHeight: '300px', height: 'auto', width: 'fit-content' }}
                        />
                    )}
                </>
            )}
            {!value &&
                (!disabled ? (
                    <Box onClick={triggerFileUpload}>
                        <Box
                            sx={{
                                outlineColor: '#aaa',
                                outlineWidth: 1,
                                outlineStyle: 'dashed',
                                outlineOffset: -3,
                                backgroundColor: '#f6f6f6',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                display: 'inline-flex'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <Box>
                                    <i className="icon-download" style={{ fontSize: '80px' }} />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography align="center">
                                        <FormattedMessage id="file.choose" />
                                    </Typography>
                                    <Typography align="center">
                                        <FormattedMessage id="file.acceptFormat" values={{ format: inputFormatLimit }} />
                                    </Typography>
                                    <Typography align="center">
                                        <FormattedMessage id="file.sizeLimit" values={size(metadata.maxFileSize)} />
                                    </Typography>
                                    <input ref={fileRef} type="file" hidden accept={inputFormatLimit} onChange={onValueChange} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: '#f6f6f6',
                            minHeight: '30px'
                        }}
                    />
                ))}
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { FileUpload };
