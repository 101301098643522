import { Box, Button, FormLabel, FormControl, FormHelperText, Stack, Typography, Link } from '@mui/material';
import { memo, useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useConfig from 'hooks/useConfig';
import { browserType } from 'utils/checkBrowser';
import SignaturePopup from './SignaturePopup';
import { useLoading } from 'hooks/useLoading';
import { saveAs } from 'file-saver';
import { b64toBlob } from 'utils/crypto';
import IamSmartButton from './IamSmartButton';
import { format } from 'date-fns';
import useApiClient from '../../../hooks/useApiClient';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { xmlSignDetachedFiles } from 'utils/xmlsig';
import { CertificateType } from 'utils/certAuthority';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import { FormContext } from './FormContext';
import { SignResult, Signature } from 'types/form';
import { flatten } from 'flat';

interface SignSectionProps {
    name?: string;
    value?: SignResult | undefined;
    onSignClick: () => Promise<{ hkic: string; application?: any; applicationObj?: any }> | undefined;
    onSignSuccess: (v: SignResult) => void;
    onSignCancel: () => void;
    metadata?: Signature;
    disabled?: boolean;
    title?: string;
    getHash: (values: any) => Promise<string>;
}

type SignRequest = {
    businessId: string;
    redirectURI: string;
    signCode: string;
};

const SignatureSection = memo((props: SignSectionProps) => {
    const {
        onSignClick,
        onSignSuccess,
        onSignCancel,
        value,
        metadata,
        disabled = false,
        name,
        title = 'signature.applicantSign',
        getHash
    } = props;
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const { locale } = useConfig();
    const [signPopup, setSignPopup] = useState(false);
    const [request, setRequest] = useState<SignRequest | undefined>(undefined);
    const intl = useIntl();
    const [result, setResult] = useState<SignResult | undefined>(value);
    const [signButton, disableSignButton] = useState(false);
    const [newTab, setNewTab] = useState<Window | null>(null);
    const { publicService } = useApiClient();
    const loading = useLoading();
    const dialog = useConfirmDialog();
    const { values, validateForm } = useFormikContext();
    const { formId, appType } = useContext(FormContext);
    const confirmDialog = useConfirmDialog();
    const createSignRequest = async () => {
        if (!(await checkForm())) {
            return;
        }
        let data = await onSignClick();
        if (!data && metadata) {
            const dataToSignCallback = metadata.dataToSign;
            const hkicCallback = metadata.hkic;
            if (dataToSignCallback && hkicCallback) {
                const dataToSign = dataToSignCallback({ values });
                const hkic = hkicCallback({ values });
                data = { hkic, applicationObj: { values: dataToSign, applicationType: appType } };
            }
        }
        if (data && data.hkic && (data.application || data.applicationObj)) {
            const app: any = _.pick(data, ['application', 'applicationObj']);
            Object.keys(app.applicationObj?.values || {}).forEach((key) => {
                const current = app.applicationObj?.values[key];
                if (current?.signature) {
                    const dataWithOutSignature = (({ signature: s, ...rest }) => rest)(current);
                    app.applicationObj.values[key] = dataWithOutSignature;
                }
            });
            const body = {
                hkicNumber: data.hkic,
                source: browserType(),
                lang: locale,
                department: intl.formatMessage({ id: 'swd' }),
                serviceName: intl.formatMessage({ id: `${formId}.serviceName` }),
                documentName: intl.formatMessage({ id: `${formId}.documentName` }),
                ...app
            };
            try {
                disableSignButton(true);
                loading(true);
                const resp = await publicService.createAnonymousSigningRequest(body);
                if (resp) {
                    if (resp.code !== 'D00000') {
                        setErrorMsg('iamsmart.signing.error.general');
                        onError();
                    } else {
                        setError(false);
                        setRequest(resp.content);
                        setSignPopup(true);
                    }
                } else {
                    disableSignButton(false);
                }
            } finally {
                loading(false);
            }
        }
    };

    const getSignResult = async (businessId: string) => {
        const resp = await publicService.getAnonymousSigningResult(businessId);
        if (resp) {
            if (resp.code === 'D00000') {
                setError(false);
                setResult(resp.content);
                onSignSuccess(resp.content);
                setSignPopup(false);
                setRequest(undefined);
                newTab?.close();
            } else if (resp.code === 'D79402' || resp.code === 'D79403') {
                setErrorMsg('error.system');
                onError();
            } else if (resp.code === 'D70004') {
                dialog?.setDialog({
                    title: 'signature.noCapability.title',
                    content: 'signature.noCapability.content',
                    actionBtn: 'signature.noCapability.viewDetails',
                    onAction: () => {
                        window.open(intl.formatMessage({ id: 'signature.noCapability.viewDetailsUrl' }));
                    },
                    cancelBtn: 'cancel'
                });
                setErrorMsg(`iamsmart.${resp.code}`);
                onError();
            } else if (resp.code !== 'D79404') {
                setErrorMsg(`iamsmart.${resp.code}`);
                onError();
            }
        }
    };

    const onError = () => {
        setError(true);
        setRequest(undefined);
        disableSignButton(false);
        setSignPopup(false);
        newTab?.close();
    };

    useEffect(() => {
        if (request && newTab) {
            const interval = setInterval(() => {
                getSignResult(request.businessId);
            }, 2000);

            return () => clearInterval(interval);
        }
        return () => {};
    }, [request, newTab]);

    const downloadCert = (content: string) => {
        const blob = b64toBlob(content, 'application/octet-stream');
        saveAs(blob, 'cert.cer');
    };

    const openQRCodeTab = (uri: string) => {
        setNewTab(window.open(uri));
    };

    const cancelSign = () => {
        setError(false);
        setResult(undefined);
        disableSignButton(false);
        onSignCancel();
    };

    const cancelRequest = () => {
        disableSignButton(false);
        setRequest(undefined);
        newTab?.close();
        setNewTab(null);
    };

    const checkForm = async () => {
        const errors = await validateForm();
        const flat: any = flatten(errors);
        for (const key of Object.keys(flat)) {
            if (name && key !== name) {
                confirmDialog?.setDialog({
                    title: 'error',
                    titleSx: { color: 'red' },
                    content: 'error.dataValidation',
                    cancelBtn: 'ok'
                });
                return false;
            }
        }
        return true;
    };

    return (
        <FormControl fullWidth sx={{ alignItems: 'start' }}>
            <FormLabel id="signature">
                <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
                    <FormattedMessage id={title} />
                </Typography>
            </FormLabel>
            {result ? (
                <Stack>
                    <Box
                        mb={2}
                        pl={1}
                        p={2}
                        sx={{
                            backgroundColor: '#f6f6f6'
                        }}
                    >
                        <Stack>
                            <Typography>
                                <FormattedMessage id="signature.signedBy" values={{ CN: result.commonName }} />
                            </Typography>
                            <Typography>
                                <FormattedMessage id="date:" />
                                {` ${format(new Date(result.timestamp), 'yyyy-MM-dd HH:mm:ss')}`}
                            </Typography>
                        </Stack>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        {/* <Button
                            variant="contained"
                            onClick={() => {
                                downloadCert(result.cert);
                            }}
                        >
                            <FormattedMessage id="cert" />
                        </Button> */}
                        {!disabled && (
                            <Button variant="contained" onClick={cancelSign}>
                                <FormattedMessage id="cancel" />
                            </Button>
                        )}
                    </Stack>
                </Stack>
            ) : (
                <Stack spacing={1}>
                    <IamSmartButton label="signature.iamSmartSigning" onClick={createSignRequest} disabled={signButton} />
                    <Link href={intl.formatMessage({ id: 'introduction.iamsamart.url' })} target="new">
                        <FormattedMessage id="iamsmart.moreInfo" />
                    </Link>
                    {['DEV', 'SIT'].includes(`${window.config.ENV}`) && window.config.TRIAL_CERT && window.config.TRIAL_CERT_PASSWORD && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                if (!(await checkForm())) {
                                    return;
                                }
                                let data = await onSignClick();
                                if (data) {
                                    if (data.application) {
                                        data = data.application;
                                    } else {
                                        data = data.applicationObj.values;
                                    }
                                }
                                if (!data && metadata) {
                                    data = metadata.dataToSign?.({ values });
                                }
                                if (!data) {
                                    return;
                                }
                                const hash = await getHash(data);
                                const keystore = window.config.TRIAL_CERT;
                                const password = window.config.TRIAL_CERT_PASSWORD;
                                if (hash) {
                                    xmlSignDetachedFiles(
                                        {},
                                        hash,
                                        keystore,
                                        password,
                                        {
                                            certificateTypes: [CertificateType.ORGANIZATIONAL]
                                        },
                                        true
                                    )
                                        .catch((err) => {
                                            console.error(err);
                                        })
                                        .then((signResult) => {
                                            const r = signResult as SignResult;
                                            setResult(r);
                                            onSignSuccess(r);
                                        });
                                }
                            }}
                        >
                            Sign (For DEV)
                        </Button>
                    )}
                </Stack>
            )}
            {error && (
                <FormHelperText error id="signature_error">
                    <FormattedMessage id={errorMsg} defaultMessage={errorMsg} />
                </FormHelperText>
            )}
            {request && (
                <SignaturePopup
                    isMobile={browserType() !== 'PC_Browser'}
                    open={signPopup}
                    onClose={() => {
                        setSignPopup(false);
                        cancelRequest();
                    }}
                    onAction={() => {
                        openQRCodeTab(request.redirectURI);
                    }}
                    signCode={request.signCode}
                    formId={formId}
                />
            )}
        </FormControl>
    );
});

export { SignatureSection };
