import { memo, useContext, useEffect, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Drawer,
    Grid,
    // Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
// import MenuCard from './MenuCard';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth, gridSpacing } from 'store/constant';
// import Chip from 'ui-component/extended/Chip';
import { AccountCircle } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';
import { GlobalVariableContext } from '../../../contexts/GlobalVariableContext';
import useConfig from '../../../hooks/useConfig';

// ==============================|| SIDEBAR DRAWER ||============================== //

interface SidebarProps {
    window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { locale } = useConfig();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { user } = useAuth();
    const { globalVariable } = useContext(GlobalVariableContext);
    const [userName, setUserName] = useState<string | undefined>('Anonymous');
    const [usernameAlias, setUsernameAlias] = useState('');
    const [roleName, setRoleName] = useState<string | undefined>('');

    useEffect(() => {
        const userProfile = user?.userProfile;

        if (userProfile) {
            setUserName(locale === 'en' ? userProfile.userNameEn?.toUpperCase() : userProfile.userNameChi?.toUpperCase());
            if (locale === 'en') {
                const username = userProfile.userNameEn?.toUpperCase();
                setUserName(username);

                if (username) {
                    const alias = username.split(' ').reduce((previous, current) => `${previous.charAt(0)}${current.charAt(0)}`);
                    console.log(`Username Alias: ${usernameAlias}`);
                    setUsernameAlias(alias);
                }
            } else {
                const username = userProfile.userNameChi?.toUpperCase();
                setUserName(username);
                if (username) {
                    setUsernameAlias(username);
                }
            }
        }

        const userRole = user?.role;

        if (userRole) {
            switch (locale) {
                case 'en':
                    setRoleName(userRole?.userRoleNameEn);
                    break;
                case 'zh-HK':
                    setRoleName(userRole?.userRoleNameTc);
                    break;
                case 'zh-CN':
                    setRoleName(userRole?.userRoleNameSc);
                    break;
                default:
                    setRoleName(userRole?.userRoleNameEn);
                    break;
            }
        }
    }, [locale]);

    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
        ),
        []
    );

    const drawer = (
        <PerfectScrollbar
            component="div"
            style={{
                height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 120px)',
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: `rgb(${globalVariable.homeThemeVariable?.headerColor})`
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: {
                        md: 10
                    }
                }}
            >
                <Box
                    sx={{
                        marginTop: {
                            md: 5
                        },
                        marginBottom: {
                            md: 5
                        }
                    }}
                />

                <Avatar
                    variant="circular"
                    sx={{
                        width: { md: 100 },
                        height: { md: 100 },
                        backgroundColor: theme.palette.common.white,
                        marginBottom: 2,
                        color: theme.palette.common.black
                    }}
                >
                    {usernameAlias}
                </Avatar>

                <Grid container spacing={gridSpacing}>
                    <Grid sx={{ textAlign: 'center' }} item xs={12}>
                        <Typography variant="h3">{userName}</Typography>
                    </Grid>
                    <Grid sx={{ textAlign: 'center' }} item xs={12}>
                        <Typography variant="h5">{roleName}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <MenuList />
        </PerfectScrollbar>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '120px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {drawerOpen && drawer}
            </Drawer>
        </Box>
    );
};

export default memo(Sidebar);
