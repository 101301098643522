import { memo, useEffect, useState } from 'react';
import { FormControl, OutlinedInput, FormLabel } from '@mui/material';
import { useFormikContext, getIn } from 'formik';
import { FormattedMessage } from 'react-intl';
import { ErrorDisplay } from './util';

interface AnyProps {
    name: string;
    metadata: {
        labelKey: string;
        value: (values: any) => any;
        required: boolean;
    };
    onChange: (v: any) => void;
}

const AnyField = memo((props: AnyProps) => {
    const { name, metadata, onChange } = props;
    // console.info(`SumField props:${JSON.stringify(props)}`);

    const { values, getFieldMeta } = useFormikContext();
    const [value, setValue] = useState<any>(undefined);

    useEffect(() => {
        const newValue = metadata.value(values);
        if (newValue !== value) {
            setValue(newValue);
            onChange(newValue);
        }
    }, [metadata, onChange, value, values]);

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <OutlinedInput id={name} name={name} value={value} disabled />
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { AnyField };
