import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const DirectLoginCookie = () => {
    const [searchParams] = useSearchParams();
    const redirectURI = searchParams.get('redirectURI');
    const eserviceName = searchParams.get('eserviceName');
    const clientID = searchParams.get('clientID');
    const scope = searchParams.get('scope');
    useEffect(() => {
        window.location.href = `${window.config.AUTH_SERVICE_DOMAIN}/iamsmart/getState?redirectURI=${redirectURI}&eserviceName=${eserviceName}&clientID=${clientID}&scope=${scope}`;
    }, []);
    return <></>;
};

export default DirectLoginCookie;
