import { b64toBlob } from 'utils/crypto';
import { saveAs } from 'file-saver';

const saveFile = (fileValue: { content: string; name: string }) => {
    const blob = b64toBlob(fileValue.content, 'application/octet-stream');
    saveAs(blob, fileValue.name);
};

const displayFileSize = (bytes: number) => {
    let result;

    if (bytes === 0) {
        result = '0 B';
    } else {
        const kBytes = bytes / 1024.0;
        const mBytes = kBytes / 1024.0;

        if (kBytes < 1) {
            result = `${bytes.toFixed(0)} B`;
        } else if (mBytes < 1) {
            result = `${kBytes.toFixed(1)} KB`;
        } else {
            result = `${mBytes.toFixed(1)} MB`;
        }
    }

    return result;
};

export { saveFile, displayFileSize };
