export const SIGNER = {
    GET_SIGNER: `${window.config.API_BASE}/sysservicepublic/sys/signer/%s`,
    VALIDATE_PASSCODE: `${window.config.API_BASE}/sysservicepublic/sys/signer/%s/validate/passcode`
};

export const APPLICATION = {
    GET_APPLICATIONS: `${window.config.API_BASE}/appservicepublic/app/applications`,
    // GET_APPLICATIONS: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount`,
    SEARCH_LICENCES: `${window.config.API_BASE}/appservicepublic/record/licences`,
    GET_HOME_INFO: `${window.config.API_BASE}/appservicepublic/record/home/%s/`,
    CREATE_APPLICATION: `${window.config.API_BASE}/appservicepublic/app/application`,
    GET_APPLICATION_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/%s/`,
    DELETE_DRAFT_APPLICATION_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/%s/draft`,
    GET_APPLICATION_HASH: `${window.config.API_BASE}/appservicepublic/app/application/hash`,
    UPDATE_DOCUMENTS_BY_APPLICATION_ID: `${window.config.API_BASE}/appservicepublic/app/application/%s/documents`,
    UPDATE_APPLICATION_STATUS: `${window.config.API_BASE}/appservicepublic/app/application/%s/status`
};

export const UNSTRUCTURED_APPLICATION = {
    SAVE_DRAFT_UNSTRUCTURED_APPLICATION: `${window.config.API_BASE}/appservicepublic/app/draft/`,
    PUT_UNSTRUCTURED_APPLICATION: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/new/`,
    GET_DRAFT_UNSTRUCTURED_APPLICATION_BY_ID: `${window.config.API_BASE}/appservicepublic/app/draft/%s`,
    GET_UNSTRUCTURED_APPLICATION_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/%s`,
    DELETE_DRAFT_UNSTRUCTURED_APPLICATION_BY_ID: `${window.config.API_BASE}/appservicepublic/app/draft/%s`,
    GET_IS_HOME_CLOSURE_BY_ID: `${window.config.API_BASE}/appservicepublic/record/home/%s/closure`,
    SURRENDER_ALL_LICENCE: `${window.config.API_BASE}/appservicepublic/record/home/%s/surrenderLicence`,

    GET_ADHOC_FORMS: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/adhoc/forms`,
    CREATE_ADHOC_FORM: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/adhoc/forms`,
    GET_ADHOC_FORM_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/adhoc/forms/%s`,
    UPDATE_ADHOC_FORM_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/adhoc/forms/%s`,
    GET_UNSTRUCTURED_RECORD_OF_HOME_BY_ID: `${window.config.API_BASE}/appservicepublic/record/home/%s/%s`,
    SUBMIT_ADHOC_FORM: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/adhoc/forms/%s/new`,
    GET_ALL_TRAINING_FORMS: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/trainings`,
    GET_TRAINING_FORM_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/trainings/%s`,
    UPDATE_TRAINING_FORM_BY_ID: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/trainings/%s`,
    GENERATE_PDF_OF_PENDING_FORM: `${window.config.API_BASE}/appservicepublic/app/application/unstructure/generate`
};

export const SYSTEM = {
    GET_DOCUMENT_BY_ID: `${window.config.API_BASE}/sysservicepublic/doc/document/%s/`,
    GET_DOCUMENT_CONTENT_BY_ID: `${window.config.API_BASE}/sysservicepublic/doc/document/%s/content`,
    GET_ALL_SELECTION: `${window.config.API_BASE}/sysservicepublic/sys/selections`,
    GET_ALL_APPLICATION_TYPES: `${window.config.API_BASE}/sysservicepublic/sys/application-types`,
    GET_NOTICES: `${window.config.API_BASE}/sysservicepublic/sys/public/notices`,
    GET_NOTICE_BY_ID: `${window.config.API_BASE}/sysservicepublic/sys/public/notices/%s`,
    MARK_NOTICE_READ: `${window.config.API_BASE}/sysservicepublic/sys/public/notices/%s`,
    GET_ALL_SUPPORTING_DOCUMENTS: `${window.config.API_BASE}/sysservicepublic/sys/supporting-doc`,
    GET_SUPPORTING_DOCUMENTS_BY_APPLICATION_TYPE_ID: `${window.config.API_BASE}/sysservicepublic/sys/supporting-doc/app-type/%s`,
    GET_APPLICATION_NAME_AND_TYPE_ID_BY_TYPE_CODE: `${window.config.API_BASE}/sysservicepublic/sys/application-types/code/%s`
};

export const PUPLIC = {
    GET_LICENCE_BY_ID: `${window.config.API_BASE}/publicservice/public/eLicence/%s/`,
    DOWNLOAD_LICENCE_BY_ID: `${window.config.API_BASE}/publicservice/public/eLicence/%s/download`,
    ANONYMOUS_SIGNING_CALLBACK: `${window.config.API_BASE}/publicservice/public/iamsmart/anonymous/signing/callback`,
    CREATE_ANONYMOUS_SIGNING_REQUEST: `${window.config.API_BASE}/publicservice/public/iamsmart/anonymous/signing/signature`,
    GET_ANONYMOUS_SIGNING_RESULT: `${window.config.API_BASE}/publicservice/public/iamsmart/anonymous/signing/result`,
    CREATE_NEW_LICENCE_APPLICATION: `${window.config.API_BASE}/publicservice/public/app/application`,
    GET_ALL_SELECTIONS_FOR_NEW_LICENCE_APPLICATION: `${window.config.API_BASE}/publicservice/public/sys/selections`,
    GENERATE_APPLICATION_PDF: `${window.config.API_BASE}/publicservice/public/app/application/generatePdf`,
    GET_APPLICATION_HASH_CODE: `${window.config.API_BASE}/publicservice/public/app/application/hash`
};

export const USER = {
    GET_HOME_USERS: `${window.config.API_BASE}/userservicepublic/user/public/homeAccounts`,
    UPDATE_HOME_USER_STATUS: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s`,
    CREATE_HOME_USER: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount`,
    UPDATE_HOME_USER: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s`,
    GET_USER_ROLES: `${window.config.API_BASE}/userservicepublic/user/public/homeUserRoles`,
    GET_USER_BY_ID: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s/`,
    VERIFY_TOKEN: `${window.config.API_BASE}/userserviceinit/public/user/token/verify/`,
    INIT_USER: `${window.config.API_BASE}/userserviceinit/public/user/initialization/`,
    GET_ASSOCIATED_ACCOUNTS: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s/association`,
    CREATE_ASSOCIATED_ACCOUNT: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s/association`,
    DELETE_ASSOCIATED_ACCOUNT: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/%s/association`,
    CHANGE_PASSWORD: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/password`,
    GET_OTP: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/requestOTP`,
    FORGOT_PASSWORD: `${window.config.API_BASE}/userserviceinit/public/user/homeAccount/forgotpassword`,
    VERIFY_FORGOT_PASSWORD_TOKEN: `${window.config.API_BASE}/userserviceinit/public/user/homeAccount/token/verify`,
    RESET_ACCOUNT_PASSWORD: `${window.config.API_BASE}/userserviceinit/public/user/homeAccount/password/reset`,
    UNLINK_IAMSMART: `${window.config.API_BASE}/userservicepublic/user/public/homeAccount/iamsmart/unlink`,
    GET_LINKUP_RESULT: `${window.config.API_BASE}/userservicepublic/user/public/iamsmart/user/link/%s`
};
