import { memo, ChangeEvent } from 'react';
import { FormControl, OutlinedInput, FormLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';
import { acceptedNumberPattern } from '../converter/number';

interface NumberFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const NumberField = memo((props: NumberFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        if (acceptedNumberPattern.test(newValue)) {
            onChange(newValue);
        } else {
            onChange(value);
        }
    };

    const inputProps = {
        maxLength: metadata.maxLength
    };

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <OutlinedInput id={name} name={name} value={value} onChange={onValueChange} inputProps={inputProps} disabled={disabled} />
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { NumberField };
