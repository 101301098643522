import { FormControl, FormHelperText, TextField, FormLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import { useFormikContext } from 'formik';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface DateTimeFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const DateTimeField = memo((props: DateTimeFieldProps) => {
    const { name, value = null, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (date: any) => {
        const newValue = date;
        onChange(newValue);
    };

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    mask="____-__-__ __:__ _M"
                    inputFormat="yyyy-MM-dd hh:mm aa"
                    value={value}
                    onChange={onValueChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disabled}
                />
            </LocalizationProvider>
            {Boolean(getFieldMeta(name).error) && (
                <FormHelperText error id={name}>
                    <FormattedMessage id={getFieldMeta(name).error} />
                </FormHelperText>
            )}
        </FormControl>
    );
});

export { DateTimeField };
