import { Checkbox, FormControlLabel, FormControl, FormHelperText, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { memo, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { LabelList } from './LabelList';

interface StringFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const CheckBox = memo((props: StringFieldProps) => {
    const { name, value = false, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        onChange(newValue);
    };

    return (
        <FormControl>
            <Stack direction="row">
                <Stack>
                    <FormControlLabel
                        control={<Checkbox id={name} checked={!!value} onChange={onValueChange} disabled={disabled} />}
                        label={
                            <>
                                {metadata.labelKey && <FormattedMessage id={metadata.labelKey} />}
                                {metadata.required && (
                                    <>
                                        <span> </span>
                                        <span style={{ color: 'red' }}>*</span>
                                    </>
                                )}
                            </>
                        }
                    />
                    {Boolean(getFieldMeta(name).error) && (
                        <FormHelperText error id={name}>
                            <FormattedMessage id={getFieldMeta(name).error} />
                        </FormHelperText>
                    )}
                </Stack>
                {metadata.labelList && <LabelList metadata={metadata} />}
            </Stack>
        </FormControl>
    );
});

export { CheckBox };
