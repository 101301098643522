import { memo, useEffect, useState } from 'react';
import { FormControl, OutlinedInput, FormLabel, Grid, Box, FormGroup } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';

interface RenderEventDateTimeStringFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const RenderEventDateTimeStringField = memo((props: RenderEventDateTimeStringFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: any) => {
        const newValue = event.target.value;
        setValue(newValue);
        setChanged(true);
    };

    const [_value, setValue] = useState<any>(value);
    const [changed, setChanged] = useState<boolean>(false);

    const onBlur = (event: React.FocusEvent<any>) => {
        if (changed) {
            onChange(_value);
            setChanged(false);
        }
    };

    const inputProps = {
        maxLength: metadata.maxLength
    };

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <FormGroup>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            {value.length > 0 &&
                value.map((dateTime: any, index: any) => (
                    <Grid item sm={metadata.width || 12} key={dateTime.seq}>
                        <OutlinedInput
                            id={name}
                            name={name}
                            value={dateTime.event_date_time_info}
                            onChange={onValueChange}
                            onBlur={onBlur}
                            rows={metadata.rows}
                            multiline={!!metadata.rows}
                            inputProps={inputProps}
                            disabled={disabled}
                            fullWidth
                        />
                        {value.length > 1 && <Box sx={{ mb: 0.9 }} />}
                    </Grid>
                ))}
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormGroup>
    );
});

export { RenderEventDateTimeStringField };
