// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'swd-public-portal-group',
    type: 'group',
    children: [
        {
            id: 'MY_ACC',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.my-account" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/',
            breadcrumbs: false
        },
        {
            id: 'USER_PROFILE',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.user.profile" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/user-management/user-profile',
            external: true,
            target: true
        },
        {
            id: 'LICENCE_APP',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.licence.application" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/user/licence-application',
            external: true,
            target: true
        },
        {
            id: 'HOME_TASK',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.home.task.submission" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/user/home-task-submission',
            external: true,
            target: true
        },
        {
            id: 'ACC_MGMT',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.account.management" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: 'account-management',
            external: true,
            target: true
        },
        {
            id: 'HOME_RECORD',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.submission.record" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/home-records'
        },
        {
            id: 'NOTICE',
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormattedMessage id="menu-list.notice-board" />
                    <KeyboardArrowRight />
                </Box>
            ),
            type: 'item',
            url: '/notice-board',
            breadcrumbs: false
        }
    ]
};

export default other;
