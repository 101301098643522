import { memo, useEffect, useState } from 'react';
import { FormControl, OutlinedInput, FormLabel } from '@mui/material';
import { useFormikContext, getIn, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

interface SumProps {
    name: string;
    metadata: {
        labelKey: string;
        maxLength?: number;
        fields?: string[];
    };
}

const SumField = memo((props: SumProps) => {
    const { name, metadata } = props;
    // console.info(`SumField props:${JSON.stringify(props)}`);

    const { values, setFieldValue } = useFormikContext();
    const inputProps = {
        maxLength: metadata.maxLength
    };

    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        let v = 0;
        metadata?.fields?.forEach((dependent) => {
            const temp = getIn(values, dependent);
            const num = Number(temp);
            if (!Number.isNaN(num)) {
                v += num;
            }
        });
        if (v !== value) {
            setValue(v);
            setFieldValue(name, v.toString());
        }
    }, [metadata?.fields, name, setFieldValue, value, values]);

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
            </FormLabel>
            <OutlinedInput id={name} name={name} value={value} disabled inputProps={inputProps} />
        </FormControl>
    );
});

export { SumField };
