import { useLoading } from '../../../../hooks/useLoading';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Divider, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { gridSpacing } from '../../../../store/constant';
import { FormattedMessage } from 'react-intl';
import MainCard from '../../../../ui-component/cards/MainCard';
import * as Yup from 'yup';
import useApiClient from '../../../../hooks/useApiClient';
import useAuth from '../../../../hooks/useAuth';
import PlainTextPopup from '../../../../ui-component/popup/PlainTextPopup';

const charsetRe = {
    alphanumeric: /^[\x00-\x7F]*$/,
    alphabets: /^[ A-Za-z ]*$/,
    number: /^[0-9.-]*$/,
    digits: /^[0-9]*$/,
    chinese:
        /^([\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FEF\uF900-\uFA6D\uFA70-\uFAD9]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|\uD87E[\uDC00-\uDE1D])*$/
};
const hkidPattern = /^([A-Z]{1,2})([0-9]{6})\(([A0-9])\)$/;

const UserProfilePage = () => {
    const initUserInfo = {
        nameOfAcctEng: '',
        nameOfAcctChi: '',
        hkid: '',
        mobile: '',
        officeTel: '',
        post: '',
        hkidNumber: '',
        hkidDigit: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        email: ''
    };

    const validationSchema = Yup.object({
        nameOfAcctEng: Yup.string().required('User Name(English) is required').matches(charsetRe.alphabets, 'Alphabets only'),
        nameOfAcctChi: Yup.string().matches(charsetRe.chinese, 'Chinese only'),
        hkid: Yup.string()
            .required('User HKID is required')
            .matches(hkidPattern, 'HKID format is invalid')
            .test('check-ID-number', 'ID number format is not valid', (hkid: string | undefined) => checkIDNumber(hkid))
            .test('check-ID-digit', 'ID Digit format is not valid', (hkid: string | undefined) => checkIDDigit(hkid)),
        mobile: Yup.number()
            .required('User Mobile is required')
            .test('check-mobile', 'User Mobile must be exactly 8 numbers', (mobile: number | undefined) => checkTel(mobile)),
        officeTel: Yup.number().test(
            'check-office-tel',
            'User Office Telephone Number must be exactly 8 numbers',
            (mobile: number | undefined) => checkTel(mobile)
        ),
        post: Yup.string(),
        address1: Yup.string(),
        address2: Yup.string(),
        address3: Yup.string(),
        address4: Yup.string(),
        email: Yup.string().email('Email Format is invalid')
    });

    const [userInfo, setUserInfo] = useState(initUserInfo);
    const [userId, setUserId] = useState<number>();
    const loading = useLoading();
    const { userService } = useApiClient();
    const { user } = useAuth();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            const { userProfile } = user;
            if (userProfile) {
                const { userId: id } = userProfile;

                if (id) {
                    setUserId(id);
                    fetchUserData(id);
                }
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            nameOfAcctEng: userInfo.nameOfAcctEng,
            nameOfAcctChi: userInfo.nameOfAcctChi,
            hkid: userInfo.hkid,
            mobile: userInfo.mobile,
            officeTel: userInfo.officeTel,
            post: userInfo.post,
            hkidNumber: userInfo.hkidNumber,
            hkidDigit: userInfo.hkidDigit,
            address1: userInfo.address1,
            address2: userInfo.address2,
            address3: userInfo.address3,
            address4: userInfo.address4,
            email: userInfo.email
        },
        validationSchema,
        onSubmit: (values) => {
            if (userId) {
                const {
                    nameOfAcctEng: user_name_eng,
                    nameOfAcctChi: user_name_chi,
                    mobile: tel,
                    email,
                    hkid,
                    post,
                    officeTel: office_tel,
                    address1: corr_addr_1,
                    address2: corr_addr_2,
                    address3: corr_addr_3,
                    address4: corr_addr_4
                } = values;

                const requestBody = {
                    user_name_eng,
                    user_name_chi,
                    tel,
                    email,
                    hkid,
                    post,
                    office_tel,
                    corr_addr_1,
                    corr_addr_2,
                    corr_addr_3,
                    corr_addr_4
                };

                updateUser(userId, requestBody);
            }
        }
    });

    const checkTel = (mobile: number | undefined): boolean => mobile == null || (mobile != null && `${mobile}`.length === 8);

    const checkIDNumber = (hkid: string = ''): boolean => {
        const idNumber = hkid.substring(0, 7);

        return charsetRe.alphanumeric.test(idNumber);
    };

    const checkIDDigit = (hkid: string = ''): boolean => {
        let valid = true;

        const matchArray = hkid.toUpperCase().match(hkidPattern);

        if (matchArray === null) {
            valid = false;
        } else {
            const charPart = matchArray[1];
            const numPart = matchArray[2];
            const idDigit = matchArray[3];

            if (!charsetRe.alphanumeric.test(idDigit)) {
                return false;
            }

            // calculate the checksum for character part
            const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let checkSum = 0;
            if (charPart.length === 2) {
                checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
                checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
            } else {
                checkSum += 9 * 36;
                checkSum += 8 * (10 + strValidChars.indexOf(charPart));
            }

            // calculate the checksum for numeric part
            for (let i = 0, j = 7; i < numPart.length; i += 1, j -= 1) {
                checkSum += j * parseInt(numPart.charAt(i), 10);
            }

            // verify the check digit
            const remaining = checkSum % 11;
            const verify = remaining === 0 ? 0 : 11 - remaining;

            valid = verify.toString() === idDigit || (verify === 10 && idDigit === 'A');
        }

        return valid;
    };

    const fetchUserData = async (id: number) => {
        try {
            loading(true);
            const userResponse = await userService.getUserById(id);
            if (userResponse) {
                const updatedUserInfo = convertUserResponseToUserFormData(userResponse);

                setUserInfo(updatedUserInfo);

                console.log(updatedUserInfo.officeTel);

                formik.setFieldValue('nameOfAcctEng', updatedUserInfo.nameOfAcctEng || '');
                formik.setFieldValue('nameOfAcctChi', updatedUserInfo.nameOfAcctChi || '');
                formik.setFieldValue('hkid', updatedUserInfo.hkid || '');
                formik.setFieldValue('mobile', updatedUserInfo.mobile || '');
                formik.setFieldValue('officeTel', updatedUserInfo.officeTel || '');
                formik.setFieldValue('post', updatedUserInfo.post || '');
                formik.setFieldValue('hkidNumber', updatedUserInfo.hkidNumber || '');
                formik.setFieldValue('hkidDigit', updatedUserInfo.hkidDigit || '');
                formik.setFieldValue('address1', updatedUserInfo.address1 || '');
                formik.setFieldValue('address2', updatedUserInfo.address2 || '');
                formik.setFieldValue('address3', updatedUserInfo.address3 || '');
                formik.setFieldValue('address4', updatedUserInfo.address4 || '');
                formik.setFieldValue('email', updatedUserInfo.email || '');
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    };

    const convertUserResponseToUserFormData = (response: any): any => {
        const {
            user_name_eng: nameOfAcctEng,
            user_name_chi: nameOfAcctChi,
            hkid,
            tel: mobile,
            office_tel: officeTel,
            post,
            corr_addr_1: address1,
            corr_addr_2: address2,
            corr_addr_3: address3,
            corr_addr_4: address4,
            email
        } = response;

        const hkidNumber = hkid && hkid.slice(0, hkid.indexOf('('));
        const hkidDigit = hkid && hkid.slice(hkid.indexOf('(') + 1, hkid.indexOf(')'));

        const updatedUserInfo = {
            nameOfAcctEng,
            nameOfAcctChi,
            hkid,
            mobile,
            officeTel,
            post,
            address1,
            address2,
            address3,
            address4,
            hkidNumber,
            hkidDigit,
            email
        };
        return updatedUserInfo;
    };

    const handleCombineHKID = (type: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;

        const hkid = `${type === 'hkidNumber' ? value : formik.values.hkidNumber}(${
            type === 'hkidDigit' ? value : formik.values.hkidDigit
        })`;
        console.log(hkid);

        formik.setFieldValue('hkid', hkid);
        formik.handleChange(event);
    };

    const updateUser = async (id: number, requestBody: any) => {
        try {
            loading(true);

            const updatedUserResponse = await userService.updateHomeUser(requestBody, id);
            if (updatedUserResponse) {
                const updatedUser = convertUserResponseToUserFormData(updatedUserResponse);

                setUserInfo(updatedUser);
                setOpen(true);
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    };

    return (
        <>
            <MainCard title="user-profile.header">
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="user-profile.account-name-en" />
                                </InputLabel>
                                <TextField
                                    required
                                    fullWidth
                                    id="nameOfAcctEng"
                                    name="nameOfAcctEng"
                                    value={formik.values.nameOfAcctEng}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.nameOfAcctEng && Boolean(formik.errors.nameOfAcctEng)}
                                    disabled
                                />
                                {formik.errors.nameOfAcctEng && <FormHelperText error>{formik.errors.nameOfAcctEng}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="user-profile.account-name-chi" />
                                </InputLabel>
                                <TextField
                                    required
                                    id="nameOfAcctChi"
                                    name="nameOfAcctChi"
                                    value={formik.values.nameOfAcctChi}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.nameOfAcctChi && Boolean(formik.errors.nameOfAcctChi)}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled
                                />
                                {formik.errors.nameOfAcctChi && <FormHelperText error>{formik.errors.nameOfAcctChi}</FormHelperText>}
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="user-profile.hkid" />
                                </InputLabel>
                                <Grid container alignItems="center" justifyContent="space-around" spacing={0}>
                                    <Grid item xs={9}>
                                        <TextField
                                            required
                                            id="hkidNumber"
                                            name="hkidNumber"
                                            value={formik.values.hkidNumber}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.hkid && Boolean(formik.errors.hkid)}
                                            onChange={(event) => handleCombineHKID('hkidNumber', event)}
                                            inputProps={{ maxLength: 8 }}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={0.5} component="span" textAlign="center">
                                        &#40;
                                    </Grid>
                                    <Grid item xs={2} component="span">
                                        <TextField
                                            required
                                            id="hkidDigit"
                                            name="hkidDigit"
                                            value={formik.values.hkidDigit}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.hkid && Boolean(formik.errors.hkid)}
                                            onChange={(event) => handleCombineHKID('hkidDigit', event)}
                                            inputProps={{ maxLength: 1 }}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={0.5} component="span" textAlign="center">
                                        &#41;
                                    </Grid>
                                </Grid>

                                {formik.errors.hkid && <FormHelperText error>{formik.errors.hkid}</FormHelperText>}
                            </Stack>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="user-profile.mobile" />
                                </InputLabel>
                                <TextField
                                    required
                                    id="mobile"
                                    name="mobile"
                                    value={formik.values.mobile}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                    onChange={formik.handleChange}
                                    inputProps={{ maxLength: 8 }}
                                    fullWidth
                                />
                                {formik.errors.mobile && <FormHelperText error>{formik.errors.mobile}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>
                                    <FormattedMessage id="user-profile.office-telephone" />
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    id="officeTel"
                                    name="officeTel"
                                    value={formik.values.officeTel}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.officeTel && Boolean(formik.errors.officeTel)}
                                    inputProps={{ maxLength: 8 }}
                                />
                                {formik.errors.officeTel && <FormHelperText error>{formik.errors.officeTel}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>
                                    <FormattedMessage id="user-profile.email" />
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                {formik.errors.email && <FormHelperText error>{formik.errors.email}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>
                                    <FormattedMessage id="user-profile.post" />
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    id="post"
                                    name="post"
                                    value={formik.values.post}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.post && Boolean(formik.errors.post)}
                                />
                                {formik.errors.post && <FormHelperText error>{formik.errors.post}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="user-profile.correspondence-address" />
                                </InputLabel>
                                <TextField
                                    required
                                    fullWidth
                                    id="address1"
                                    name="address1"
                                    value={formik.values.address1}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                />
                                {formik.errors.address1 && <FormHelperText error>{formik.errors.address1}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    id="address2"
                                    name="address2"
                                    value={formik.values.address2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                />
                                {formik.errors.address2 && <FormHelperText error>{formik.errors.address2}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    id="address3"
                                    name="address3"
                                    value={formik.values.address3}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address3 && Boolean(formik.errors.address3)}
                                />
                                {formik.errors.address3 && <FormHelperText error>{formik.errors.address3}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    id="address4"
                                    name="address4"
                                    value={formik.values.address4}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.address4 && Boolean(formik.errors.address4)}
                                />
                                {formik.errors.address4 && <FormHelperText error>{formik.errors.address4}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                            <Grid item xs={10} />
                            <Grid item xs={1}>
                                <Button variant="contained" type="submit" color="success">
                                    <FormattedMessage id="user-profile.submit" />
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" color="inherit">
                                    <FormattedMessage id="user-profile.cancel" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
            <PlainTextPopup
                open={open}
                onClose={() => setOpen(false)}
                title="user-management.popup-title"
                actionBtn="ok"
                onAction={() => setOpen(false)}
            >
                <FormattedMessage id="user-management.popup-message" />
            </PlainTextPopup>
        </>
    );
};

export default UserProfilePage;
