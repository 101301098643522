import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import IamSmartButton from './IamSmartButton';
import { FormattedMessage, useIntl } from 'react-intl';
import TapImage from 'assets/images/tap.png';

export interface SignaturePopupProps {
    open: boolean;
    onClose: () => void;
    signCode: string;
    onAction?: () => void;
    formId: string;
    isMobile: boolean;
}

const SignaturePopup = ({ open, signCode, onClose, onAction, formId, isMobile }: SignaturePopupProps) => {
    const intl = useIntl();
    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title" onClose={onClose}>
            <DialogTitle id="customized-dialog-title" sx={{}}>
                <Grid container>
                    <Grid item lg={10} md={10} sm={10} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <FormattedMessage id="signature.signWithIamSmart" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Close onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent sx={{}} dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                                <FormattedMessage id="signature.serviceName" />
                            </Typography>
                            <Typography variant="h4">
                                <FormattedMessage id={`${formId}.serviceName`} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                                <FormattedMessage id="signature.documentName" />
                            </Typography>
                            <Typography variant="h4">
                                <FormattedMessage id={`${formId}.documentName`} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                                <FormattedMessage id="signature.identificationCode" />
                            </Typography>
                            <Typography variant="h3" color="#2b7367">
                                {signCode}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            <FormattedMessage id="signature.instruction.title" />
                        </Typography>

                        <Typography variant="body2" sx={{ color: '#2b7367' }}>
                            <FormattedMessage id="signature.instruction.1" />
                        </Typography>
                        {isMobile ? (
                            <Typography variant="body2">
                                <FormattedMessage id="signature.instruction.2" />
                            </Typography>
                        ) : (
                            <>
                                <Typography variant="body2">
                                    <FormattedMessage id="signature.instruction.2.diffenrentDevice" />
                                </Typography>
                                <img src={TapImage} style={{ height: '100px' }} />
                            </>
                        )}
                        <Typography variant="body2">
                            <FormattedMessage id="signature.instruction.3" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <IamSmartButton label="signature.continueWithIamSmart" onClick={onAction} sx={{ margin: 'auto' }} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="primary" autoFocus onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SignaturePopup;
