import {
    Box,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';

const ContactSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    const handleToggle = () => {
        navigate(isLoggedIn ? '/user/contact' : '/contact', { replace: true });
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
                onClick={handleToggle}
            >
                <Typography variant="h4">
                    <FormattedMessage id="header.contact" />
                </Typography>
            </Box>
        </>
    );
};

export default ContactSection;
