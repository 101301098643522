import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import MainTable, { MainTableHeader } from 'ui-component/tables/MainTable';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, Stack, InputLabel, TextField, Divider, Button } from '@mui/material';
import { Box } from '@mui/system';
import { SortKeyMapping } from 'constants/SortKeyMapping';
import useApiClient from 'hooks/useApiClient';
import { useLoading } from 'hooks/useLoading';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { GetNoticesRequest } from 'types/request/get-notices-request';
import { IGetNoticeResponse } from 'types/response/get-notice-response';

const validationSchema = Yup.object({
    title: Yup.string(),
    sentDate: Yup.date()
});

const headers: MainTableHeader[] = [
    {
        id: 'notice_title',
        label: 'noticeBoard.title',
        styles: [
            {
                sx: 'font-weight: bold;',
                condition: (item: any) => item.read_date == null
            }
        ]
    },
    {
        id: 'sent_date',
        label: 'noticeBoard.sentDate',
        type: 'date',
        styles: [
            {
                sx: 'font-weight: bold;',
                condition: (item: any) => item.read_date == null
            }
        ]
    },
    { id: 'read_date', label: 'noticeBoard.readDate', type: 'date' }
];

const NoticeBoardPage = () => {
    const [notices, setNotices] = useState<IGetNoticeResponse[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortingProperty, setSortingProperty] = useState<string>('sentDate');
    const [sortingSequence, setSortingSequence] = useState<string>('desc');
    const [pageNo, setPageNo] = useState(0);
    const loading = useLoading();
    const navigate = useNavigate();
    const pagesize = 10;
    const { systemService } = useApiClient();

    const handleChangePage = (newPageNo: number) => {
        setPageNo(newPageNo);
    };

    const handleSort = (property: string, isAsc: boolean) => {
        setSortingProperty(SortKeyMapping[property]);
        setSortingSequence(isAsc ? 'asc' : 'desc');
    };

    const onRowSelected = (row: any) => navigate('/notice', { state: { id: row.id } });

    const formik = useFormik({
        initialValues: {
            title: '',
            sentDate: undefined
        },
        validationSchema,
        onSubmit: async (values) => {
            await retrieveNotices(values);
        }
    });

    const handleClear = () => {
        formik.resetForm({
            errors: undefined,
            isSubmitting: false,
            isValidating: false,
            status: undefined,
            submitCount: 0,
            touched: undefined,
            values: {
                title: '',
                sentDate: undefined
            }
        });

        formik.submitForm();
    };

    useEffect(() => {
        formik.submitForm();
    }, [pageNo, sortingProperty, sortingSequence]);

    const retrieveNotices = async (values: any) => {
        const { title, sentDate } = values;

        const requestParams = new GetNoticesRequest(
            pageNo,
            pagesize,
            sortingProperty || undefined,
            sortingSequence || undefined,
            sentDate || undefined,
            title || undefined
        );

        try {
            loading(true);
            const response = await systemService.getNotices(requestParams);
            const { notices: newNotices, total: newRowCount } = response;
            setNotices(newNotices || []);
            setRowCount(newRowCount || 0);
        } catch (e) {
            console.log(e);
        } finally {
            loading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={12}>
                    <MainCard title="noticeBoard.header" contentSX={{ p: 2 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel required>
                                            <FormattedMessage id="noticeBoard.searchField.title" />
                                        </InputLabel>
                                        <TextField
                                            id="title"
                                            name="title"
                                            value={formik.values.title}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.title && Boolean(formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            onChange={formik.handleChange}
                                            fullWidth
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel required>
                                            <FormattedMessage id="noticeBoard.searchField.sentDate" />
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(props) => <TextField fullWidth {...props} />}
                                                value={formik.values.sentDate}
                                                onChange={(newValue: Date | null) => {
                                                    formik.setFieldValue('sentDate', newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                                    <Grid item xs={6} sm={3}>
                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <Button variant="contained" type="submit">
                                                <FormattedMessage id="applicationInbox.searchField.filterBtn" />
                                            </Button>
                                            <Button variant="contained" color="error" onClick={handleClear}>
                                                <FormattedMessage id="applicationInbox.searchField.clearBtn" />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <MainTable
                                        headers={headers}
                                        rows={notices}
                                        rowCount={rowCount}
                                        onSelect={onRowSelected}
                                        enablePagination
                                        rowsPerPage={pagesize}
                                        onPageChange={handleChangePage}
                                        onSort={handleSort}
                                        enableSorting
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NoticeBoardPage;
