// material-ui
import { Grid } from '@mui/material';

// project imports
import AuthWrapper1 from '../../authentication/AuthWrapper1';
import AuthCardWrapper from '../../authentication/AuthCardWrapper';
import UpdatePassword from './change-password';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const UpdateHomeAccountPasswordPage = () => (
    <AuthWrapper1>
        <Grid container justifyContent="center" alignItems="center">
            <AuthCardWrapper title="update.password.box.header" darkTitle>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <UpdatePassword />
                </Grid>
            </AuthCardWrapper>
        </Grid>
    </AuthWrapper1>
);

export default UpdateHomeAccountPasswordPage;
