/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { createCommand, LexicalCommand, LexicalEditor } from 'lexical';
import { useEffect, useRef, useState } from 'react';

import TextInput from '../TextInput';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import FileInput from '../TextInput/FileInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { INSERT_IMAGE_COMMAND } from '../ImagePlugin';

export type InsertImagePayload = Readonly<any>;

export function InsertImageUriDialogBody({ onClick }: { onClick: (payload: InsertImagePayload) => void }) {
    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');
    const { formatMessage } = useIntl();
    const isDisabled = src === '';

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px'
            }}
        >
            <TextInput
                label={formatMessage({ id: 'lexical.imageUrl' })}
                placeholder="i.e. https://source.unsplash.com/random"
                onChange={setSrc}
                value={src}
                data-test-id="image-modal-url-input"
            />
            <TextInput
                label={formatMessage({ id: 'lexical.imageAltText' })}
                placeholder={formatMessage({ id: 'lexical.imageAltTextPlaceholderUrl' })}
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />
            <DialogActions>
                <Button
                    data-test-id="image-modal-confirm-btn"
                    color="primary"
                    variant="contained"
                    disabled={isDisabled}
                    onClick={() => onClick({ altText, src })}
                >
                    <FormattedMessage id="lexical.confirm" />
                </Button>
            </DialogActions>
        </Box>
    );
}

export function InsertImageUploadedDialogBody({ onClick }: { onClick: (payload: InsertImagePayload) => void }) {
    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');
    const { formatMessage } = useIntl();
    const isDisabled = src === '';

    const loadImage = (files: FileList | null) => {
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                setSrc(reader.result);
            }
            return '';
        };
        if (files !== null) {
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px'
            }}
        >
            <FileInput
                label={formatMessage({ id: 'lexical.imageUpload' })}
                onChange={loadImage}
                accept="image/*"
                data-test-id="image-modal-file-upload"
            />
            <TextInput
                label={formatMessage({ id: 'lexical.imageAltText' })}
                placeholder={formatMessage({ id: 'lexical.imageAltTextPlaceholderFile' })}
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />
            <DialogActions>
                <Button
                    data-test-id="image-modal-file-upload-btn"
                    color="primary"
                    variant="contained"
                    disabled={isDisabled}
                    onClick={() => onClick({ altText, src })}
                >
                    <FormattedMessage id="lexical.confirm" />
                </Button>
            </DialogActions>
        </Box>
    );
}

function InsertImageDialog({
    activeEditor,
    onClose,
    open
}: {
    activeEditor: LexicalEditor;
    onClose: () => void;
    open: boolean;
}): JSX.Element {
    const [mode, setMode] = useState<null | 'url' | 'file'>(null);
    const hasModifier = useRef(false);

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e: KeyboardEvent) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [activeEditor]);

    const onClick = (payload: InsertImagePayload) => {
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
        onClose();
    };

    return (
        <Dialog
            onClose={() => {
                setMode(null);
                onClose();
            }}
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '708px'
                    }
                }
            }}
        >
            {!mode && (
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingBottom: '20px'
                    }}
                >
                    <DialogTitle id="customized-dialog-title">
                        <FormattedMessage id="lexical.imageType" />
                    </DialogTitle>
                    <Button
                        size="large"
                        data-test-id="image-modal-option-url"
                        variant="contained"
                        color="primary"
                        onClick={() => setMode('url')}
                    >
                        <FormattedMessage id="lexical.url" />
                    </Button>
                    <Button
                        size="large"
                        data-test-id="image-modal-option-file"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: '20px' }}
                        onClick={() => setMode('file')}
                    >
                        <FormattedMessage id="lexical.file" />
                    </Button>
                </Box>
            )}
            {mode === 'url' && <InsertImageUriDialogBody onClick={onClick} />}
            {mode === 'file' && <InsertImageUploadedDialogBody onClick={onClick} />}
        </Dialog>
    );
}

export default InsertImageDialog;
