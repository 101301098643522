import { memo, ChangeEvent, createRef } from 'react';
import { FormControl, FormLabel, Box, Typography, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import path from 'path';

import { ErrorDisplay } from './util';
import { saveFile, displayFileSize } from 'utils/file';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import useApiClient from 'hooks/useApiClient';
import { useLoading } from 'hooks/useLoading';

interface FileDownloadProps {
    name: string;
    value: any;
    metadata: {
        labelKey: string;
        // required: boolean;
        // maxFiles: number;
        // maxFileSize: number;
        // format: string[];
        // messageKey?: string;
    };
    // onChange?: (v: any) => void;
    // disabled: boolean;
}

const previewExt = ['.jpg', '.jpeg', '.png'];

const FileDownload = memo((props: FileDownloadProps) => {
    const { name, value, metadata } = props;
    const loading = useLoading();
    const { systemService } = useApiClient();
    console.log(value);

    const size = (bytes: any) => {
        const params = {
            size: displayFileSize(bytes)
        };
        return params;
    };

    const previewable = previewExt.includes(path.extname(value?.fileName));

    const downloadFile = async (fileId: any) => {
        try {
            loading(true);
            const response = await systemService.getDocumentContentById(fileId);
            console.log(response);
            saveFile({
                content: response.file_content,
                name: `${response.doc_name}.${response.doc_extension}`
            });
        } catch (err) {
            console.error('File Download Error');
        } finally {
            loading(false);
        }
    };

    return (
        <FormControl fullWidth>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
            </FormLabel>
            {value.length > 0 &&
                value.map((file: any, index: any) => (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            key={file.id}
                        >
                            <i className="icon-attach" />
                            <Button
                                sx={{
                                    backgroundColor: '#f6f6f6'
                                }}
                                onClick={() => downloadFile(file.id)}
                            >
                                {`${file.doc_name}.${file.doc_extension} (${size(file.doc_size).size})`}
                            </Button>
                        </Box>
                        <br />
                    </>
                ))}
        </FormControl>
    );
});

export { FileDownload };
