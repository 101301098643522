/* eslint-disable @typescript-eslint/dot-notation */
/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

// TODO inject api key from runtime source
axiosServices.defaults.headers.common['apikey'] = `${window.config.API_KEY}`;

export default axiosServices;
