import { useContext } from 'react';

// auth provider
import { GlobalVariableContext } from '../contexts/GlobalVariableContext';

// ==============================|| AUTH HOOKS ||============================== //

const useGlobalConfig = () => {
    const context = useContext(GlobalVariableContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useGlobalConfig;
