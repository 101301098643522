import { useLocation, useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import * as Yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Pattern } from '../../../../constants/Pattern';
import { useLoading } from 'hooks/useLoading';
import useConfig from 'hooks/useConfig';
import { Button, Divider, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage, useIntl } from 'react-intl';
import { IUserRole } from '../../../../types/global-variable/user-role';
import useApiClient from '../../../../hooks/useApiClient';
import useGlobalConfig from '../../../../hooks/useGlobalConfig';
import { useConfirmDialog } from 'hooks/useConfirmDialog';

export interface userRoleI {
    code: string;
    role_name_sc: string;
    role_name_en: string;
    role_name_tc: string;
    id: number;
}
export interface FormValuesI {
    accountType: string;
    nameOfAcctEng?: string;
    nameOfAccountChi?: string;
    hkid?: string;
    mobile?: string;
    officeTel?: string;
    email?: string;
    post?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    address4?: string;
}
const validationSchema = Yup.object({
    accountType: Yup.string(),
    nameOfAccountEng: Yup.string(),
    nameOfAccountChi: Yup.string(),
    hkid: Yup.string()
        .required('User HKID is required')
        .matches(Pattern.hkidPattern, 'HKID format is invalid')
        .test('check-ID-number', 'ID number format is not valid', (hkid: string | undefined) => checkIDNumber(hkid))
        .test('check-ID-digit', 'ID Digit format is not valid', (hkid: string | undefined) => checkIDDigit(hkid)),
    mobile: Yup.string().matches(Pattern.digits, 'error.digitOnly').min(8, 'error.length').max(8, 'error.length'),
    officeTel: Yup.string().matches(Pattern.digits, 'error.digitOnly').min(8, 'error.length').max(8, 'error.length'),
    email: Yup.string().email('error.email.format'),
    post: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
    address3: Yup.string(),
    address4: Yup.string()
});
const checkIDNumber = (hkid: string = ''): boolean => {
    const idNumber = hkid.substring(0, 7);

    return Pattern.alphanumeric.test(idNumber);
};

const checkIDDigit = (hkid: string = ''): boolean => {
    let valid = true;

    const matchArray = hkid.toUpperCase().match(Pattern.hkidPattern);

    if (matchArray === null) {
        valid = false;
    } else {
        const charPart = matchArray[1];
        const numPart = matchArray[2];
        const idDigit = matchArray[3];

        if (!Pattern.alphanumeric.test(idDigit)) {
            return false;
        }

        // calculate the checksum for character part
        const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let checkSum = 0;
        if (charPart.length === 2) {
            checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
            checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
        } else {
            checkSum += 9 * 36;
            checkSum += 8 * (10 + strValidChars.indexOf(charPart));
        }

        // calculate the checksum for numeric part
        for (let i = 0, j = 7; i < numPart.length; i += 1, j -= 1) {
            checkSum += j * parseInt(numPart.charAt(i), 10);
        }

        // verify the check digit
        const remaining = checkSum % 11;
        const verify = remaining === 0 ? 0 : 11 - remaining;

        valid = verify.toString() === idDigit || (verify === 10 && idDigit === 'A');
    }

    return valid;
};
const CreateHomeUserPage = () => {
    const location = useLocation();
    const { id } = (location.state as any) || {};
    const navigate = useNavigate();
    const loading = useLoading();
    const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
    const { globalVariable } = useGlobalConfig();
    const { userService } = useApiClient();
    const intl = useIntl();
    const dialog = useConfirmDialog();

    useEffect(() => {
        if (globalVariable.userRolesWithoutAdmin) {
            setUserRoles(globalVariable.userRolesWithoutAdmin);
        }
        const getUserById = async () => {
            try {
                if (id) {
                    loading(true);
                    const dbUserData = await userService.getUserById(id);
                    const hkid = dbUserData.hkid;
                    const hkidNumber = hkid && hkid.slice(0, hkid.indexOf('('));
                    const hkidDigit = hkid && hkid.slice(hkid.indexOf('(') + 1, hkid.indexOf(')'));
                    formik.setFieldValue('accountType', dbUserData.role_info.id);
                    formik.setFieldValue('nameOfAcctEng', dbUserData.user_name_eng || undefined);
                    formik.setFieldValue('nameOfAccountChi', dbUserData.user_name_chi || undefined);
                    formik.setFieldValue('hkid', dbUserData.hkid || undefined);
                    // formik.setFieldValue('hkidNumber', hkidNumber || undefined);
                    // formik.setFieldValue('hkidDigit', hkidDigit || undefined);
                    // formik.setFieldValue('mobile', dbUserData.tel || undefined);
                    formik.setFieldValue('officeTel', dbUserData.office_tel || undefined);
                    formik.setFieldValue('email', dbUserData.email || undefined);
                    formik.setFieldValue('post', dbUserData.post || undefined);
                    formik.setFieldValue('address1', dbUserData.corr_addr_1 || undefined);
                    formik.setFieldValue('address2', dbUserData.corr_addr_2 || undefined);
                    formik.setFieldValue('address3', dbUserData.corr_addr_3 || undefined);
                    formik.setFieldValue('address4', dbUserData.corr_addr_4 || undefined);
                }
            } finally {
                loading(false);
            }
        };
        getUserById();
    }, [globalVariable.userRolesWithoutAdmin]);
    const formik = useFormik({
        initialValues: {
            accountType: '',
            nameOfAcctEng: undefined,
            nameOfAccountChi: undefined,
            hkid: undefined,
            hkidNumber: undefined,
            hkidDigit: undefined,
            mobile: undefined,
            officeTel: undefined,
            email: undefined,
            post: undefined,
            address1: undefined,
            address2: undefined,
            address3: undefined,
            address4: undefined
        },
        validationSchema,
        onSubmit: (values: FormValuesI) => {
            dialog.setDialog({
                title: 'confirmation',
                content: id ? 'account.edit.confirm' : 'account.creation.confirm',
                cancelBtn: 'no',
                actionBtn: 'yes',
                onAction: () => {
                    if (id) {
                        updateHomeUser(values);
                    } else {
                        createHomeUser(values);
                    }
                }
            });
        }
    });
    const createHomeUser = async (formValues: FormValuesI) => {
        const {
            accountType,
            nameOfAcctEng,
            nameOfAccountChi,
            hkid,
            mobile,
            officeTel,
            email,
            post,
            address1,
            address2,
            address3,
            address4
        } = formValues;
        const params = {
            user_name_eng: nameOfAcctEng,
            user_name_chi: nameOfAccountChi,
            tel: officeTel,
            mobile,
            corr_addr_1: address1,
            corr_addr_2: address2,
            corr_addr_3: address3,
            corr_addr_4: address4,
            email,
            hkid,
            user_role_id: accountType
        };
        try {
            loading(true);
            const resp = await userService.createHomeUser(params);
            if (resp) {
                navigate('/account-management', {});
            }
        } finally {
            loading(false);
        }
    };
    const updateHomeUser = async (formValues: FormValuesI) => {
        const {
            accountType,
            nameOfAcctEng,
            nameOfAccountChi,
            hkid,
            mobile,
            officeTel,
            email,
            post,
            address1,
            address2,
            address3,
            address4
        } = formValues;
        const params = {
            user_name_eng: nameOfAcctEng,
            user_name_chi: nameOfAccountChi,
            tel: mobile,
            office_tel: officeTel,
            post,
            corr_addr_1: address1,
            corr_addr_2: address2,
            corr_addr_3: address3,
            corr_addr_4: address4,
            email,
            hkid,
            role_info: {
                id: accountType
            }
        };
        try {
            loading(true);
            const resp = await userService.updateHomeUser(params, id);
            if (resp) {
                navigate('/account-management', {});
            }
        } finally {
            loading(false);
        }
    };

    const { locale } = useConfig();

    const getValueByLang = (userRole: IUserRole, lang: string) => {
        switch (lang) {
            case 'zh-HK':
                return userRole.roleNameTc;
            case 'zh-CN':
                return userRole.roleNameSc;
            case 'en':
            default:
                return userRole.roleNameEn;
        }
    };

    const handleCombineHKID = (type: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;

        const hkid = `${type === 'hkidNumber' ? value : formik.values.hkidNumber}(${
            type === 'hkidDigit' ? value : formik.values.hkidDigit
        })`;
        console.log(hkid);

        formik.setFieldValue('hkid', hkid);
        formik.handleChange(event);
    };

    return (
        <MainCard title="menu-list.account.management">
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.account-type" />
                            </InputLabel>
                            <Select
                                required
                                id="accountType"
                                name="accountType"
                                defaultValue={formik.values.accountType}
                                value={formik.values.accountType}
                                onChange={formik.handleChange}
                            >
                                {userRoles.map((userRole, index) => (
                                    <MenuItem key={index} value={userRole.id}>
                                        {getValueByLang(userRole, locale)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.account-name-en" />
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="nameOfAcctEng"
                                name="nameOfAcctEng"
                                value={formik.values.nameOfAcctEng}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.nameOfAcctEng && Boolean(formik.errors.nameOfAcctEng)}
                                helperText={formik.touched.nameOfAcctEng && formik.errors.nameOfAcctEng}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.account-name-chi" />
                            </InputLabel>
                            <TextField
                                required
                                id="nameOfAccountChi"
                                name="nameOfAccountChi"
                                value={formik.values.nameOfAccountChi}
                                onBlur={formik.handleBlur}
                                error={formik.touched.nameOfAccountChi && Boolean(formik.errors.nameOfAccountChi)}
                                helperText={formik.touched.nameOfAccountChi && formik.errors.nameOfAccountChi}
                                onChange={formik.handleChange}
                                fullWidth
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.hkid" />
                            </InputLabel>
                            <Grid container alignItems="center" justifyContent="space-around" spacing={0}>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="hkidNumber"
                                        name="hkidNumber"
                                        value={formik.values.hkidNumber}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.hkid && Boolean(formik.errors.hkid)}
                                        onChange={(event) => handleCombineHKID('hkidNumber', event)}
                                        inputProps={{ maxLength: 8 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={0.5} component="span" textAlign="center">
                                    &#40;
                                </Grid>
                                <Grid item xs={2} component="span">
                                    <TextField
                                        required
                                        id="hkidDigit"
                                        name="hkidDigit"
                                        value={formik.values.hkidDigit}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.hkid && Boolean(formik.errors.hkid)}
                                        onChange={(event) => handleCombineHKID('hkidDigit', event)}
                                        inputProps={{ maxLength: 1 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={0.5} component="span" textAlign="center">
                                    &#41;
                                </Grid>
                            </Grid>

                            {formik.errors.hkid && <FormHelperText error>{formik.errors.hkid}</FormHelperText>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.mobile" />
                            </InputLabel>
                            <TextField
                                required
                                id="mobile"
                                name="mobile"
                                value={formik.values.mobile}
                                onBlur={formik.handleBlur}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={
                                    formik.touched.mobile &&
                                    formik.errors.mobile &&
                                    intl.formatMessage({ id: formik.errors.mobile }, { length: 8 })
                                }
                                onChange={formik.handleChange}
                                fullWidth
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel>
                                <FormattedMessage id="account.creation.office-telephone" />
                            </InputLabel>
                            <TextField
                                fullWidth
                                id="officeTel"
                                name="officeTel"
                                value={formik.values.officeTel}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.officeTel && Boolean(formik.errors.officeTel)}
                                helperText={
                                    formik.touched.officeTel &&
                                    formik.errors.officeTel &&
                                    intl.formatMessage({ id: formik.errors.officeTel }, { length: 8 })
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.email" />
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={
                                    formik.touched.email &&
                                    formik.errors.email &&
                                    intl.formatMessage({ id: formik.errors.email }, { length: 8 })
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <InputLabel>
                                <FormattedMessage id="account.creation.post" />
                            </InputLabel>
                            <TextField
                                fullWidth
                                id="post"
                                name="post"
                                value={formik.values.post}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.post && Boolean(formik.errors.post)}
                                helperText={formik.touched.post && formik.errors.post}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack>
                            <InputLabel required>
                                <FormattedMessage id="account.creation.correspondence-address" />
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="address1"
                                name="address1"
                                value={formik.values.address1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                helperText={formik.touched.address1 && formik.errors.address1}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack>
                            <TextField
                                fullWidth
                                id="address2"
                                name="address2"
                                value={formik.values.address2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                helperText={formik.touched.address2 && formik.errors.address2}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack>
                            <TextField
                                fullWidth
                                id="address3"
                                name="address3"
                                value={formik.values.address3}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address3 && Boolean(formik.errors.address3)}
                                helperText={formik.touched.address3 && formik.errors.address3}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack>
                            <TextField
                                fullWidth
                                id="address4"
                                name="address4"
                                value={formik.values.address4}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address4 && Boolean(formik.errors.address4)}
                                helperText={formik.touched.address4 && formik.errors.address4}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                        <Stack spacing={1} direction="row">
                            <Button variant="contained" type="submit" color="success">
                                <FormattedMessage id="account.creation.submit" />
                            </Button>
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={() => {
                                    navigate('/account-management', {});
                                }}
                            >
                                <FormattedMessage id="account.creation.cancel" />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </MainCard>
    );
};

export default CreateHomeUserPage;
