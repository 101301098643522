export const SortKeyMapping: { [key: string]: string } = {
    app_ref_no: 'applicationRefNo',
    app_type: 'applicationType',
    home_name_en: 'applicationHome.homeName',
    submission_date: 'submissionDate',
    applicant_name_eng: 'applicantInfo.applicantName',
    status: 'applicationStatus',
    last_updated_date: 'updatedDate',
    creation_date: 'createdDate'
};
