import { toByteArray } from 'base64-js';
import * as forge from 'node-forge';
import isEqual from 'lodash/isEqual';

/**
 * Adopt from google closure library:
 * https://github.com/google/closure-library/blob/master/closure/goog/crypt/crypt.js#L117
 */
/* eslint-disable no-bitwise,no-plusplus,eqeqeq */
// const stringToUtf8ByteArray = (str) => {
//   // TODO(user): Use native implementations if/when available
//   const out = [];
//   let p = 0;
//   for (let i = 0; i < str.length; i += 1) {
//     let c = str.charCodeAt(i);
//     if (c < 128) {
//       out[p++] = c;
//     } else if (c < 2048) {
//       out[p++] = (c >> 6) | 192;
//       out[p++] = (c & 63) | 128;
//     } else if (
//         ((c & 0xFC00) == 0xD800) && (i + 1) < str.length &&
//         ((str.charCodeAt(i + 1) & 0xFC00) == 0xDC00)) {
//       // Surrogate Pair
//       c = 0x10000 + ((c & 0x03FF) << 10) + (str.charCodeAt(++i) & 0x03FF);
//       out[p++] = (c >> 18) | 240;
//       out[p++] = ((c >> 12) & 63) | 128;
//       out[p++] = ((c >> 6) & 63) | 128;
//       out[p++] = (c & 63) | 128;
//     } else {
//       out[p++] = (c >> 12) | 224;
//       out[p++] = ((c >> 6) & 63) | 128;
//       out[p++] = (c & 63) | 128;
//     }
//   }
//   return out;
// };

/**
 * Calculate sha256 hash of a utf-8 string and return the base64 encoded hash value
 */
// const sha1Hash = (str) => {
//   const md = forge.sha1.create();
//   md.update(str, 'utf8');
//   return base64Encode(md.digest().getBytes());
// };

/**
 * Convert bytes to a base64 encoded string
 */
const base64Encode = forge.util.encode64;

/**
 * Convert a base64 encoded string to decoded string
 */
const base64Decode = forge.util.decode64;

const base64EncodeString = (str: string) => base64Encode(forge.util.encodeUtf8(str));

const base64DecodeString = (str: string) => forge.util.decodeUtf8(base64Decode(str));

// const b64toBlob = (b64Data, inContentType, inSliceSize) => {
//   const contentType = inContentType || '';
//   const sliceSize = inSliceSize || 512;

//   const byteCharacters = stringToUtf8ByteArray(base64DecodeString(b64Data));
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i += 1) {
//       // byteNumbers[i] = slice.charCodeAt(i);
//       byteNumbers[i] = slice[i];
//     }

//     const byteArray = new Uint8Array(byteNumbers);

//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// };

const b64toBlob = (b64Data: string, inContentType: string) => {
    const contentType = inContentType || '';
    const byteArray = toByteArray(b64Data);
    const byteArrays = [byteArray];
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

/**
 * Calculate sha256 digest of a string, returns base64 encoded digest
 */
const makeSHA256DigestAsB64 = (binaryData: string) => {
    const md = forge.md.sha256.create();
    // const data = String.fromCharCode.apply(null, base64DecodeToByteArray(b64data));
    // md.update(str, 'utf8');
    md.update(binaryData);
    return base64Encode(md.digest().bytes());
};

const loadPkcs12 = (p12B64: string, password: string | undefined): forge.pkcs12.Pkcs12Pfx => {
    const p12Der = forge.util.decode64(p12B64);
    const p12Asn1 = forge.asn1.fromDer(p12Der);
    return forge.pkcs12.pkcs12FromAsn1(p12Asn1, false, password);
};

const getPrivateKeyFromP12 = (p12B64: any, password: any) => {
    const forgeP12 = loadPkcs12(p12B64, password);
    let privateKey = null;

    for (let sci = 0; sci < forgeP12.safeContents.length; sci += 1) {
        const safeContents = forgeP12.safeContents[sci];

        for (let sbi = 0; sbi < safeContents.safeBags.length; sbi += 1) {
            const safeBag = safeContents.safeBags[sbi];

            // this bag has a private key
            if (safeBag.type === forge.pki.oids.keyBag) {
                // Found plain private key
                privateKey = safeBag.key;
            } else if (safeBag.type === forge.pki.oids.pkcs8ShroudedKeyBag) {
                // found encrypted private key
                privateKey = safeBag.key;
            }
        }
    }

    return privateKey;
};

const sign = (privateKey: any, stringToSign: string) => {
    const md = forge.md.sha256.create();
    md.update(stringToSign, 'utf8');
    const signature = privateKey.sign(md);
    return base64Encode(signature);
};

const signWithBytes = (privateKey: any, stringToSign: string) => {
    const md = forge.md.sha256.create();
    md.update(base64Decode(stringToSign));
    const signature = privateKey.sign(md);
    return base64Encode(signature);
};

const getPublicCerts = (p12B64: any, password: any) => {
    const forgeP12 = loadPkcs12(p12B64, password);
    const certBags = forgeP12.getBags({ bagType: forge.pki.oids.certBag })[forge.pki.oids.certBag];
    return certBags ? certBags.map((certBag) => certBag.cert) : [];
};

const getBase64DerPublicCert = (publicCert: forge.pki.Certificate) =>
    forge.util.encode64(forge.asn1.toDer(forge.pki.certificateToAsn1(publicCert)).data);

const getCertFromBase64Der = (base64Der: string) => forge.pki.certificateFromAsn1(forge.asn1.fromDer(forge.util.decode64(base64Der)));

const escapeValue = (val: string | any[], forceQuote: boolean) => {
    let out = '';
    let cur = 0;
    const len = val.length;
    let quoted = false;
    /* BEGIN JSSTYLED */
    const escaped = /[\\\"]/;
    const special = /[,=+<>#;]/;
    /* END JSSTYLED */

    if (len > 0) {
        // Wrap strings with trailing or leading spaces in quotes
        quoted = forceQuote || val[0] === ' ' || val[len - 1] === ' ';
    }

    while (cur < len) {
        if (escaped.test(val[cur]) || (!quoted && special.test(val[cur]))) {
            out += '\\';
        }
        out += val[cur];
        cur += 1;
    }
    if (quoted) {
        out = `"${out}"`;
    }
    return out;
};

const getRdn = (subject: { attributes: any[] }) => {
    let str = '';
    subject.attributes.forEach((attr: { shortName: string; value: any }) => {
        if (str.length) {
            str += ', ';
        }

        str += attr.shortName;
        str += `=${escapeValue(attr.value, false)}`;
    });

    return str;
};

const getCertInRdnFormat = (cert: { subject: any }) => getRdn(cert.subject);

const matchPrivateKeyWithPublicCert = (privateKey: { n: any }, publicCert: { publicKey: { n: any } }) => {
    const privateModulus = privateKey.n;
    const publicModulus = publicCert.publicKey.n;
    return isEqual(privateModulus, publicModulus);
};

const getPublicCertForPrivateKey = (privateKey: any, publicCerts: any[]) => {
    let matchedCert: any = null;
    publicCerts.forEach((publicCert: any) => {
        if (matchPrivateKeyWithPublicCert(privateKey, publicCert)) {
            matchedCert = publicCert;
        }
    });

    return matchedCert;
};

const checkCertValidity = (cert: { validity: { notAfter: number; notBefore: number } }, serverTime: number | Date) => {
    if (cert.validity.notAfter < serverTime) {
        throw new Error('certExpired');
    } else if (cert.validity.notBefore > serverTime) {
        throw new Error('certNotValidYet');
    }
};

const getCertFieldValue = (subject: { getField: (arg0: any) => { (): any; new (): any; value: any } }, fieldName: string) =>
    subject.getField(fieldName) && subject.getField(fieldName).value;

const getCertExtensionValue = (cert: { getExtension: (arg0: any) => { (): any; new (): any; value: any } }, option: { id: string }) =>
    cert.getExtension(option) && cert.getExtension(option).value;

const signId = (privateKey: { sign: (arg0: forge.md.MessageDigest) => any }, id: string) => {
    const md = forge.md.sha1.create();
    md.update(id, 'utf8');
    const signature = privateKey.sign(md);

    const sha1Md = forge.md.sha1.create();
    sha1Md.update(signature);
    const result = base64Encode(sha1Md.digest().bytes());

    return result;
};

const verify = (publicKey: any, hash: string, signature: string): boolean => {
    const md = forge.md.sha256.create();
    md.update(base64Decode(hash));
    return publicKey.publicKey.verify(md.digest().getBytes(), base64Decode(signature));
};
export {
    base64Encode,
    base64Decode,
    base64EncodeString,
    base64DecodeString,
    b64toBlob,
    makeSHA256DigestAsB64,
    getPublicCerts,
    getBase64DerPublicCert,
    getCertFromBase64Der,
    getPrivateKeyFromP12,
    sign,
    signWithBytes,
    getPublicCertForPrivateKey,
    getRdn,
    getCertInRdnFormat,
    checkCertValidity,
    getCertFieldValue,
    getCertExtensionValue,
    signId,
    verify
};
