import { lazy } from 'react';
// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'ui-component/Loadable';
import MainLayout from '../layout/MainLayout';
import Error from 'views/pages/public/Error';
import SelectUser from 'views/pages/public/SelectUser';
import Callback from 'views/pages/public/Callback';
import DirectLoginCookie from 'views/pages/public/DirectLoginCookie';
import DirectLoginCallback from 'views/pages/public/DirectLoginCallback';

// login routing
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const Licence = Loadable(lazy(() => import('views/pages/public/Licence')));
const AnonymousSigning = Loadable(lazy(() => import('views/pages/public/AnonymousSigning')));
const AccountInitializationPage = Loadable(lazy(() => import('../views/pages/account-management/account-initialization')));
const ContactPage = Loadable(lazy(() => import('../views/pages/contact')));
const CopyrightPage = Loadable(lazy(() => import('../views/pages/copyright')));
const DisclaimerPage = Loadable(lazy(() => import('../views/pages/disclaimer')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../views/pages/privacy-policy')));
const LicenceApplicationPage = Loadable(lazy(() => import('../views/pages/licence-application')));
const ApplicationPage = Loadable(lazy(() => import('../views/pages/application/ApplicationPage')));
const UnstructuredApplicationPage = Loadable(lazy(() => import('../views/pages/application/UnstructuredApplicationPage')));
const ResetPasswordPage = Loadable(lazy(() => import('../views/pages/reset-password')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MainLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/licence',
            element: <Licence />
        },
        {
            path: '/init-account',
            element: <AccountInitializationPage />
        },
        {
            path: '/amsSignCallback',
            element: <AnonymousSigning />
        },
        { path: '/reset-password', element: <ResetPasswordPage /> },
        {
            path: '/callback',
            element: <Callback />
        },
        {
            path: '/error',
            element: <Error />
        },
        {
            path: '/selectUser',
            element: <SelectUser />
        },
        {
            path: '/licence-application',
            element: <LicenceApplicationPage />
        },
        {
            path: '/application',
            element: <ApplicationPage />
        },
        {
            path: '/user/form',
            element: <UnstructuredApplicationPage />
        },
        { path: '/reset-password', element: <ResetPasswordPage /> },
        {
            path: '/contact',
            element: <ContactPage />
        },
        {
            path: '/copyright',
            element: <CopyrightPage />
        },
        {
            path: '/disclaimer',
            element: <DisclaimerPage />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicyPage />
        },
        {
            path: '/iamsmart/login/directLoginCookie',
            element: <DirectLoginCookie />
        },
        {
            path: '/iamsmart/login/directLoginCallback',
            element: <DirectLoginCallback />
        }
    ]
};

export default LoginRoutes;
