import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

const LockPlugin = ({ disabled }: { disabled?: boolean }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(!disabled);
    }, [disabled]);

    return <></>;
};

export default LockPlugin;
