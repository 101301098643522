import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AccountManagementPage from '../views/pages/account-management';
import CreateHomeUserPage from '../views/pages/account-management/createHomeUser';
import UpdateHomeAccountPasswordPage from '../views/pages/account-management/change-password';
import UserManagementPage from '../views/pages/user-management';
import AccountAssociationPage from '../views/pages/user-management/account-association';
import UserProfilePage from '../views/pages/user-management/user-profile';
import NoticeBoardPage from 'views/pages/notice-board/notice-list';
import NoticePage from 'views/pages/notice-board/notice';
import AdhocForm from 'views/pages/home-task-submission/AdhocForm';
import HomeRecords from 'views/pages/home-record';
import UnstructuredFormDisplay from 'views/pages/home-record/tabs/UnstructuredFormDisplay';

const MyAccountPage = Loadable(lazy(() => import('views/pages/my-account')));
const ExistingLicence = Loadable(lazy(() => import('views/pages/my-account/ExistingLicence')));
const OnGoingApplication = Loadable(lazy(() => import('views/pages/my-account/OnGoingApplication')));
const DraftApplication = Loadable(lazy(() => import('views/pages/my-account/DraftApplication')));
const LicenceApplicationPage = Loadable(lazy(() => import('../views/pages/licence-application')));
const HomeTaskSubmissionPage = Loadable(lazy(() => import('../views/pages/home-task-submission')));
const ApplicationPage = Loadable(lazy(() => import('../views/pages/application/ApplicationPage')));
const UnstructuredApplicationPage = Loadable(lazy(() => import('../views/pages/application/UnstructuredApplicationPage')));
const ContactPage = Loadable(lazy(() => import('../views/pages/contact')));
const CopyrightPage = Loadable(lazy(() => import('../views/pages/copyright')));
const DisclaimerPage = Loadable(lazy(() => import('../views/pages/disclaimer')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../views/pages/privacy-policy')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/my-account/application" replace />
        },
        {
            path: '/my-account',
            element: <MyAccountPage />,
            children: [
                {
                    path: 'licence',
                    element: <ExistingLicence />
                },
                {
                    path: 'application',
                    element: <OnGoingApplication />
                },
                {
                    path: 'draft',
                    element: <DraftApplication />
                }
            ]
        },
        {
            path: '/user/licence-application',
            element: <LicenceApplicationPage />
        },
        {
            path: '/user/home-task-submission',
            element: <HomeTaskSubmissionPage />
        },
        {
            path: '/ad-hoc-form',
            element: <AdhocForm />
        },
        {
            path: '/account-management',
            element: <AccountManagementPage />
        },
        {
            path: '/user/application',
            element: <ApplicationPage />
        },
        {
            path: '/user/form',
            element: <UnstructuredApplicationPage />
        },
        {
            path: '/create-home-user',
            element: <CreateHomeUserPage />
        },
        {
            path: '/change-password',
            element: <UpdateHomeAccountPasswordPage />
        },
        {
            path: '/user-management',
            element: <UserManagementPage />,
            children: [
                {
                    path: 'user-profile',
                    element: <UserProfilePage />
                },
                {
                    path: 'account-association',
                    element: <AccountAssociationPage />
                }
            ]
        },
        {
            path: '/home-records',
            element: <HomeRecords />,
            children: [
                {
                    path: 'ser',
                    element: <UnstructuredFormDisplay homeId={-1} appType="SER" />
                },
                {
                    path: 'sh',
                    element: <UnstructuredFormDisplay homeId={-1} appType="SH" />
                },
                {
                    path: 'licence',
                    element: <ExistingLicence />
                }
            ]
        },
        {
            path: '/notice-board',
            element: <NoticeBoardPage />
        },
        {
            path: '/notice',
            element: <NoticePage />
        },
        {
            path: '/user/contact',
            element: <ContactPage />
        },
        {
            path: '/user/copyright',
            element: <CopyrightPage />
        },
        {
            path: '/user/disclaimer',
            element: <DisclaimerPage />
        },
        {
            path: '/user/privacy-policy',
            element: <PrivacyPolicyPage />
        }
    ]
};

export default MainRoutes;
