import { DialogContext } from 'contexts/DialogContext';
import { useContext } from 'react';

const useDialog = () => {
    const context = useContext(DialogContext);

    if (!context) {
        throw new Error('useDialog must be used within a DialogContext');
    }

    return context;
};

export default useDialog;
