import {
    Add,
    FormatAlignCenterOutlined,
    FormatColorFill,
    FormatColorText,
    Image,
    TableChartOutlined,
    RedoOutlined,
    UndoOutlined,
    FormatBoldOutlined,
    FormatItalicOutlined,
    FormatUnderlinedOutlined,
    FormatAlignLeftOutlined,
    FormatAlignRightOutlined,
    FormatListBulleted,
    FormatListNumbered,
    InsertLink
} from '@mui/icons-material';

import FormatHeader1 from 'mdi-material-ui/FormatHeader1';
import FormatHeader2 from 'mdi-material-ui/FormatHeader2';
import FormatHeader3 from 'mdi-material-ui/FormatHeader3';
import {
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_NORMAL,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    KEY_MODIFIER_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { isFunction } from 'formik';

import FontSize from './component/FontSize';
import { $getSelectionStyleValueForProperty, $patchStyleText, $setBlocksType } from '@lexical/selection';
import DropdownColorPicker from './component/DropdownColorPicker';
import { IconButton } from '@mui/material';
import InsertImageDialog from './component/InsertDialog/InsertImageDialog';
import InsertTableDialog from './component/InsertDialog/InsertTableDialog';
import { $createHeadingNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import {
    $isListNode,
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from '@lexical/list';
import { $createCodeNode, $isCodeNode, CODE_LANGUAGE_FRIENDLY_NAME_MAP, CODE_LANGUAGE_MAP, getLanguageFriendlyName } from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { sanitizeUrl } from './tool/url';
import { getSelectedNode } from './tool/getSelectedNode';

export interface ToolBarContextProps {
    pluginsList: any;
    FontSizeComp: any;
    FontColorComp: any;
    FontBgColorComp: any;
    ImageComp: any;
    TableComp: any;
}
const LowPriority = 1;
const ToolBarContext = createContext<ToolBarContextProps | undefined>(undefined);
const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    check: 'Check List',
    code: 'Code Block',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote'
};
const ToolBarProvider: any = ({
    children,
    disabled,
    setIsLinkEditMode
}: {
    children: any;
    disabled?: boolean;
    setIsLinkEditMode: (value: any) => void;
}) => {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [fontColor, setFontColor] = useState<string>('#000');
    const [bgColor, setBgColor] = useState<string>('#fff');
    const [fontSize, setFontSize] = useState<string>('15px');
    const [fontColorOpen, setFontColorOpen] = useState(false);
    const [fontBgColorOpen, setFontBgColorOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);
    const [tabelOpen, setTabelOpen] = useState(false);
    const [blockType, setBlockType] = useState<any>('paragraph');
    const [selectedElementKey, setSelectedElementKey] = useState<any>(null);
    const [codeLanguage, setCodeLanguage] = useState<string>('');
    const [isLink, setIsLink] = useState(false);
    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
            // Handle buttons
            setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
            setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                    if ($isCodeNode(element)) {
                        const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
                        setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '');
                    }
                }
            }

            // let matchingParent;
            // if ($isLinkNode(parent)) {
            //     // If node is a link, we need to fetch the parent paragraph node to set format
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     matchingParent = $findMatchingParent(node, (parentNode) => $isElementNode(parentNode) && !parentNode.isInline());
            // }
        }
    }, [editor]);
    useEffect(() => {
        mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    $updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, $updateToolbar]);

    useEffect(
        () =>
            editor.registerCommand(
                KEY_MODIFIER_COMMAND,
                (payload) => {
                    const event: KeyboardEvent = payload;
                    const { code, ctrlKey, metaKey } = event;

                    if (code === 'KeyK' && (ctrlKey || metaKey)) {
                        event.preventDefault();
                        let url: string | null;
                        if (!isLink) {
                            setIsLinkEditMode(true);
                            url = sanitizeUrl('https://');
                        } else {
                            setIsLinkEditMode(false);
                            url = null;
                        }
                        return editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
                    }
                    return false;
                },
                COMMAND_PRIORITY_NORMAL
            ),
        [editor, isLink, setIsLinkEditMode]
    );

    const applyStyleText = useCallback(
        (styles: Record<string, string>, skipHistoryStack?: boolean) => {
            editor.update(
                () => {
                    const selection = $getSelection();
                    if (selection !== null) {
                        $patchStyleText(selection, styles);
                    }
                },
                skipHistoryStack ? { tag: 'historic' } : {}
            );
        },
        [editor]
    );

    const onFontColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ color: value }, skipHistoryStack);
        },
        [applyStyleText]
    );

    const onBgColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ 'background-color': value }, skipHistoryStack);
        },
        [applyStyleText]
    );

    const colorChange = (selectStatus: boolean) => (selectStatus ? 'primary' : 'inherit');

    const formatHeading = (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createHeadingNode(headingSize));
            });
        }
    };
    const formatBulletList = () => {
        if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };
    const insertLink = useCallback(() => {
        if (!isLink) {
            setIsLinkEditMode(true);
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
        } else {
            setIsLinkEditMode(false);
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink, setIsLinkEditMode]);
    const pluginsList: any = [
        {
            id: 1,
            disabled: canRedo,
            Icon: () => <UndoOutlined />,
            onClick: () => {
                editor.dispatchCommand(UNDO_COMMAND, undefined);
            }
        },

        {
            id: 2,
            disabled: canUndo,
            Icon: () => <RedoOutlined />,
            onClick: () => {
                editor.dispatchCommand(REDO_COMMAND, undefined);
            }
        },
        {
            id: 3,

            Icon: () => <FormatHeader1 color={colorChange(blockType === 'h1')} />,
            onClick: () => {
                formatHeading('h1');
            }
        },
        {
            id: 4,

            Icon: () => <FormatHeader2 color={colorChange(blockType === 'h2')} />,
            onClick: () => {
                formatHeading('h2');
            }
        },
        {
            id: 5,
            Icon: () => <FormatHeader3 color={colorChange(blockType === 'h3')} />,
            onClick: () => {
                formatHeading('h3');
            }
        },
        {
            id: 6,
            Icon: () => <FormatListBulleted color={colorChange(blockType === 'bullet')} />,
            onClick: () => {
                formatBulletList();
            }
        },
        {
            id: 7,
            Icon: () => <FormatListNumbered color={colorChange(blockType === 'number')} />,
            onClick: () => {
                formatNumberedList();
            }
        },
        {
            id: 10,
            Icon: () => <FormatBoldOutlined color={colorChange(isBold)} />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }
        },
        {
            id: 11,
            Icon: () => <FormatItalicOutlined color={colorChange(isItalic)} />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }
        },
        {
            id: 12,
            Icon: () => <FormatUnderlinedOutlined color={colorChange(isUnderline)} />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }
        },
        {
            id: 13,
            Icon: () => <InsertLink color={colorChange(isLink)} />,
            onClick: insertLink
        },

        {
            id: 15,
            Icon: () => <FormatAlignLeftOutlined />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            }
        },

        {
            id: 16,
            Icon: () => <FormatAlignCenterOutlined />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            }
        },
        {
            id: 17,
            Icon: () => <FormatAlignRightOutlined />,
            onClick: () => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
            }
        }
    ];

    const FontSizeComp = () => <FontSize selectionFontSize={fontSize.slice(0, -2)} editor={editor} disabled={disabled} />;

    const FontColorComp = useMemo(
        () => (
            <DropdownColorPicker
                open={fontColorOpen}
                IconComp={<FormatColorText />}
                disabled={disabled}
                color={fontColor}
                onChange={onFontColorSelect}
                onClick={() => {
                    setFontColorOpen(!fontColorOpen);
                }}
                handleClose={() => {
                    setFontColorOpen(false);
                }}
            />
        ),
        [fontColorOpen, disabled]
    );

    const FontBgColorComp = useMemo(
        () => (
            <DropdownColorPicker
                open={fontBgColorOpen}
                IconComp={<FormatColorFill />}
                disabled={disabled}
                color={bgColor}
                onChange={onBgColorSelect}
                onClick={() => {
                    setFontBgColorOpen(!fontBgColorOpen);
                }}
                handleClose={() => {
                    setFontBgColorOpen(false);
                }}
            />
        ),
        [fontBgColorOpen, disabled]
    );

    const ImageComp = useMemo(
        () => (
            <>
                <IconButton
                    disabled={disabled}
                    onClick={() => {
                        setImageOpen(true);
                    }}
                >
                    <Image />
                </IconButton>
                <InsertImageDialog
                    activeEditor={editor}
                    onClose={() => {
                        setImageOpen(false);
                    }}
                    open={imageOpen}
                />
            </>
        ),
        [imageOpen, editor, disabled]
    );
    const TableComp = useMemo(
        () => (
            <>
                <IconButton
                    disabled={disabled}
                    onClick={() => {
                        setTabelOpen(true);
                    }}
                >
                    <TableChartOutlined />
                </IconButton>
                <InsertTableDialog
                    activeEditor={editor}
                    onClose={() => {
                        setTabelOpen(false);
                    }}
                    open={tabelOpen}
                />
            </>
        ),
        [tabelOpen, editor, disabled]
    );

    const ToolBarValue: ToolBarContextProps = {
        pluginsList,
        FontSizeComp,
        FontColorComp,
        FontBgColorComp,
        ImageComp,
        TableComp
    };
    return (
        <ToolBarContext.Provider value={ToolBarValue}>
            {isFunction(children) ? children(ToolBarValue) : children} {children}
        </ToolBarContext.Provider>
    );
};

export { ToolBarContext, ToolBarProvider };
