import { ISelectionValue } from './selection-value';

export interface IApplicationType {
    id?: number;
    typeCode?: string;
    typeNameEn?: string;
    typeNameTc?: string;
    typeNameSc?: string;
    configurable?: boolean;
    updatedBy?: string;
    updatedDate?: Date;
}

export class ApplicationType implements IApplicationType {
    constructor(
        public id?: number,
        public typeCode?: string,
        public typeNameEn?: string,
        public typeNameTc?: string,
        public typeNameSc?: string,
        public configurable?: boolean,
        public updatedBy?: string,
        public updatedDate?: Date
    ) {
        this.id = id;
        this.typeCode = typeCode;
        this.typeNameEn = typeNameEn;
        this.typeNameTc = typeNameTc;
        this.typeNameSc = typeNameSc;
        this.configurable = configurable;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
    }
}
