import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// material-ui
import {
    Box,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow,
    Tooltip,
    Link
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { KeyedObject, ArrangementOrder, GetComparator } from 'types';
import { ApplicationTypeCode, SelectionTypeCode } from 'types/selectionType';
import { SelectionValue } from 'ui-component/SelectionValue';
import { useContext } from 'react';
import { GlobalVariableContext } from '../../../contexts/GlobalVariableContext';
import { ApplicationTypeValue } from '../../ApplicationTypeValue';

export type MainTableHeader = {
    id: string;
    type?: 'string' | 'number' | 'date' | 'actions' | 'id' | 'value' | undefined;
    label: string;
    disablePadding?: string | boolean | undefined;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
    actions?: MainTableAction[];
    selectionType?: SelectionTypeCode;
    applicationType?: boolean;
    styles?: MainTableCellStyle[];
};
export interface MainTableAction extends KeyedObject {
    label: string;
    // @ts-ignore
    handler: (item: any, event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | any>) => void;
    icon?: JSX.Element;
    condition?: (item: any) => boolean;
}

export interface MainTableCellStyle extends KeyedObject {
    sx: string;
    // @ts-ignore
    condition?: (item: any) => boolean;
}

// table filter
function descendingComparator(a: KeyedObject, b: KeyedObject, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator: GetComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array: any[], comparator: (a: KeyedObject, b: KeyedObject) => number) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return (a[1] as number) - (b[1] as number);
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| TABLE - HEADER ||============================== //
export interface TableDataEnhancedTableHead extends TableCellProps {
    mainTableHeaders: MainTableHeader[];
    onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
    order: ArrangementOrder;
    orderBy?: string;
    numSelected: number;
    rowCount: number;
    onRequestSort: (e: React.SyntheticEvent, p: string) => void;
    enableCheckBox: boolean;
    enableSorting: boolean;
}

function EnhancedTableHead({
    mainTableHeaders,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    enableCheckBox,
    enableSorting
}: TableDataEnhancedTableHead) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent) => {
        onRequestSort(event, property);
    };
    const { globalVariable } = useContext(GlobalVariableContext);

    return (
        <TableHead sx={{ backgroundColor: `rgb(${globalVariable.homeThemeVariable?.headerColor})` }}>
            <TableRow>
                {!enableCheckBox ?? (
                    <TableCell padding="checkbox" sx={{ pl: 3 }}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts'
                            }}
                        />
                    </TableCell>
                )}
                {mainTableHeaders.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.type === 'number' ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={enableSorting && orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!enableSorting || headCell.type === 'actions'}
                        >
                            <FormattedMessage id={headCell.label} defaultMessage={headCell.label} />
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export interface MainTableProps extends KeyedObject {
    headers: MainTableHeader[];
    rows: any[];
    enableCheckBox?: boolean;
    order?: ArrangementOrder;
    orderBy?: string;
    enablePagination?: boolean;
    enableSelection?: boolean;
    enableSorting?: boolean;
    onSelect?: (data: any) => void;
    onSort?: (column: string, isAsc: boolean) => void;
    onPageChange?: (page: number) => void;
    rowsPerPage?: number;
    rowCount?: number;
}
const MainTable = ({
    headers,
    rows,
    enableCheckBox = false,
    order = 'asc',
    orderBy,
    enablePagination = false,
    enableSelection = false,
    enableSorting = false,
    onSelect,
    onSort,
    onPageChange,
    rowsPerPage,
    rowCount
}: MainTableProps) => {
    const [_order, setOrder] = React.useState<ArrangementOrder>(order);
    const [_orderBy, setOrderBy] = React.useState<string | undefined>(undefined);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [_rowsPerPage, setRowsPerPage] = React.useState(rowsPerPage || 10);
    rows = rows || [];
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedId: string[] = rows.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event: React.SyntheticEvent, property: string) => {
        const isAsc = _orderBy === property && _order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (onSort) {
            onSort(property, isAsc);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLTableRowElement> | undefined, name: any) => {
        if (!(event?.target instanceof HTMLTableCellElement)) {
            return;
        }
        if (enableSelection) {
            const selectedIndex = selected.indexOf(name);
            let newSelected: string[] = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);
        }
        if (onSelect) {
            onSelect(name);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        if (onPageChange) {
            onPageChange(newPage);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        setRowsPerPage(parseInt(event?.target.value!, 10));
        setPage(0);
    };

    const isSelected = (name: any) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = 0;

    const createTableCell = (header: MainTableHeader, data: any) => {
        let align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined = 'left';
        if (header.align) {
            align = header.align;
        } else if (header.type && header.type === 'number') {
            align = 'right';
        }
        let content: any = header.type === 'actions' ? createActionItem(header, data) : data[header.id];
        if (header.type) {
            if (header.type === 'date') {
                if (content) {
                    content = format(new Date(content), 'yyyy-MM-dd');
                }
            }
            if (header.selectionType) {
                if (header.type === 'id') {
                    content = <SelectionValue type={header.selectionType} id={content} />;
                }
                if (header.type === 'value') {
                    content = <SelectionValue type={header.selectionType} value={content} />;
                }
            }
        }
        if (header.applicationType) {
            content = <ApplicationTypeValue type={content} />;
        }
        const sx: any = header.styles ? configStyle(header, data) : '';
        return (
            <TableCell key={`cell${header.id}`} align={align} sx={sx}>
                {content}
            </TableCell>
        );
    };

    const configStyle = (header: MainTableHeader, data: any) => {
        if (header.styles) {
            return header.styles
                .filter((style) => (style.condition ? style.condition(data) : true))
                .map((e) => e.sx)
                .join('');
        }
        return '';
    };
    const createActionItem = (header: MainTableHeader, data: any) =>
        header.actions ? (
            header.actions
                .filter((action) => (action.condition ? action.condition(data) : true))
                .map((action, index) =>
                    action.icon ? (
                        <Tooltip title={<FormattedMessage id={action.label} defaultMessage={action.label} />}>
                            <IconButton
                                aria-label={action.value}
                                onClick={(event) => {
                                    action.handler(data, event);
                                }}
                            >
                                {action.icon}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Link
                            key={`link${header.id}${index}`}
                            onClick={(event: any) => {
                                action.handler(data, event);
                            }}
                            sx={{ p: 0.5, mr: 1, cursor: 'pointer', whiteSpace: 'nowrap' }}
                        >
                            <FormattedMessage id={action.label} defaultMessage={action.label} />
                        </Link>
                    )
                )
        ) : (
            <> </>
        );

    const sortAndPage = (list: any[]) => {
        if (_orderBy && !onSort) {
            list = stableSort(rows, getComparator(_order, _orderBy || ''));
        }
        if (enablePagination && !onPageChange) {
            list = list.slice(page * _rowsPerPage, page * _rowsPerPage + _rowsPerPage);
        }
        return list;
    };

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                    <EnhancedTableHead
                        mainTableHeaders={headers}
                        numSelected={selected.length}
                        order={_order}
                        orderBy={_orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        enableCheckBox={enableCheckBox}
                        enableSorting={enableSorting}
                    />
                    <TableBody>
                        {sortAndPage(rows).map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={`row${index}`}
                                    selected={isItemSelected}
                                >
                                    {enableCheckBox && (
                                        <TableCell padding="checkbox" sx={{ pl: 3 }}>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    {headers.map((header) => createTableCell(header, row))}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table data */}
            {enablePagination && (
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rowCount || rows.length}
                    rowsPerPage={_rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};

export default MainTable;
