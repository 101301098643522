import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface DialogProps {
    open: boolean;
    title: string;
    onClose: () => void;
    onConfirm: () => void;
}

const RenderQuestionDeleteDialog = ({ open, title, onClose, onConfirm }: DialogProps) => (
    <Dialog
        sx={{
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                    width: '100%'
                }
            }
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">
            <FormattedMessage id={title} />
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-between', paddingInline: '1.5rem' }}>
            <Button variant="outlined" onClick={onClose}>
                <FormattedMessage id="no" />
            </Button>

            <Button variant="contained" color="error" onClick={onConfirm}>
                <FormattedMessage id="yes" />
            </Button>
        </DialogActions>
    </Dialog>
);
export default RenderQuestionDeleteDialog;
