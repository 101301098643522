import { memo } from 'react';
import { LabelField } from './Label';

interface LabelListProps {
    metadata: {
        variant:
            | 'body1'
            | 'body2'
            | 'button'
            | 'caption'
            | 'h1'
            | 'h2'
            | 'h3'
            | 'h4'
            | 'h5'
            | 'h6'
            | 'inherit'
            | 'overline'
            | 'subtitle1'
            | 'subtitle2';
        labelKey: string;
        labelList: any[];
    };
}

const LabelList = memo((props: LabelListProps) => {
    const { metadata } = props;

    return (
        <ol>
            {metadata.labelList.map((label) => (
                <li>
                    <LabelField metadata={{ ...label, variant: metadata.variant }} />
                </li>
            ))}
        </ol>
    );
});

export { LabelList };
