import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { GlobalVariable, IGlobalVariable } from '../types/global-variable/global-variable';
import { useLoading } from '../hooks/useLoading';
import { Selection } from '../types/global-variable/selection';
import { ApplicationTypeCode, SelectionTypeCode } from '../types/selectionType';
import { ISelectionValue, SelectionValue } from '../types/global-variable/selection-value';
import { UserRole } from '../types/global-variable/user-role';
import { HomeThemeVariable } from '../types/global-variable/home-theme-variable';
import { HomeThemeMapping } from '../constants/HomeThemeMapping';
import useAuth from '../hooks/useAuth';
import useApiClient from '../hooks/useApiClient';
import { ApplicationType, IApplicationType } from '../types/global-variable/application-type';

interface GlobalVariableContextProps {
    globalVariable: IGlobalVariable;
    refresh: Dispatch<SetStateAction<boolean>>;
    getOptionsByType: (type: SelectionTypeCode) => ISelectionValue[] | undefined;
    getOptionByTypeAndId: (type: SelectionTypeCode, id: number) => ISelectionValue | undefined;
    getOptionByTypeAndValue: (type: SelectionTypeCode, dataValue: string) => ISelectionValue | undefined;
    getApplicationTypeByTypeCode: (type: ApplicationTypeCode) => IApplicationType | undefined;
    fetchSelectionData: () => void;
}

const GlobalVariableContext = createContext<GlobalVariableContextProps>({
    globalVariable: new GlobalVariable(),
    refresh: () => false,
    getOptionsByType: (type) => [],
    getOptionByTypeAndId: (type, id) => new SelectionValue(),
    getOptionByTypeAndValue: (type, dataValue) => new SelectionValue(),
    getApplicationTypeByTypeCode: (type) => new ApplicationType(),
    fetchSelectionData: () => {}
});

const GlobalVariableProvider: FC = ({ children }) => {
    const [globalVariable, setGlobalVariable] = useState<IGlobalVariable>();
    const [refresh, setRefresh] = useState<boolean>(true);
    const context = useAuth();
    const { userService, systemService, publicService } = useApiClient();
    const loading = useLoading();

    const getOptionsByType = (type: SelectionTypeCode) => {
        const { selections } = globalVariable || {};

        const values = selections ? selections.find((selection) => selection.code === type)?.values : [];

        return values;
    };

    const getOptionByTypeAndId = (type: SelectionTypeCode, id: number) => {
        const { selections } = globalVariable || {};
        const values = selections ? selections.find((selection) => selection.code === type)?.values : undefined;

        const chosenValue = values ? values.find((value) => value.id === id) : undefined;

        return chosenValue;
    };

    const getOptionByTypeAndValue = (type: SelectionTypeCode, dataValue: string) => {
        const { selections } = globalVariable || {};

        const values = selections ? selections.find((selection) => selection.code === type)?.values : undefined;

        const chosenValue = values ? values.find((value) => value.dataValue === dataValue) : undefined;

        return chosenValue;
    };

    const getType = (type: SelectionTypeCode) => {
        const { selections } = globalVariable || {};

        return selections ? selections.find((selection) => selection.code === type) : undefined;
    };

    const getApplicationTypeByTypeCode = (type: ApplicationTypeCode) => {
        const { applicationTypes } = globalVariable || {};

        const chosenType = applicationTypes ? applicationTypes.find((applicationType) => applicationType.typeCode === type) : undefined;

        return chosenType;
    };

    useEffect(() => {
        if (refresh && context?.keycloak.token) {
            const initGlobalVariable = async () => {
                await fetchSelectionData();
                await fetchUserRolesWithoutAdmin();
                await fetchUserRolesWithAdmin();
                await fetchApplicationTypes();
            };
            initGlobalVariable();
        }
    }, [refresh, context?.keycloak.token]);

    useEffect(() => {
        if (refresh) {
            setupHomeTheme();
        }
    }, [refresh]);

    const setupHomeTheme = () => {
        const user = context.user;

        const defaultColor = HomeThemeMapping.default;
        const theme = new HomeThemeVariable(defaultColor);
        if (user) {
            const { home } = user;
            if (home) {
                const { homeType } = home;
                const color = HomeThemeMapping[homeType];

                theme.headerColor = color;
            }
        }

        setGlobalVariable(
            (oldGlobalVariable) =>
                new GlobalVariable(
                    oldGlobalVariable?.selections,
                    oldGlobalVariable?.signers,
                    oldGlobalVariable?.userRolesWithoutAdmin,
                    oldGlobalVariable?.userRolesWithAdmin,
                    theme,
                    oldGlobalVariable?.applicationTypes
                )
        );
    };

    const fetchApplicationTypes = async () => {
        try {
            loading(true);
            const applicationTypeResponses = await systemService.getAllApplicationTypes();
            console.log(applicationTypeResponses);
            if (applicationTypeResponses) {
                const applicationTypes: IApplicationType[] = applicationTypeResponses.map(
                    (applicationTypeResponse) =>
                        new ApplicationType(
                            applicationTypeResponse.id,
                            applicationTypeResponse.type_code,
                            applicationTypeResponse.type_name_en,
                            applicationTypeResponse.type_name_tc,
                            applicationTypeResponse.type_name_sc,
                            applicationTypeResponse.configurable,
                            applicationTypeResponse.updated_by,
                            applicationTypeResponse.updated_date
                        )
                );

                setGlobalVariable(
                    (oldGlobalVariable) =>
                        new GlobalVariable(
                            oldGlobalVariable?.selections,
                            oldGlobalVariable?.signers,
                            oldGlobalVariable?.userRolesWithoutAdmin,
                            oldGlobalVariable?.userRolesWithAdmin,
                            oldGlobalVariable?.homeThemeVariable,
                            applicationTypes
                        )
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
            setRefresh(false);
        }
    };

    const fetchUserRolesWithAdmin = async () => {
        const requestParams = {
            withoutAdmin: false
        };
        const userRoleResponses = await userService.getUserRoles(requestParams);

        if (userRoleResponses) {
            const userRolesWithAdmin = userRoleResponses.map(
                (userRoleResponse) =>
                    new UserRole(
                        userRoleResponse.id,
                        userRoleResponse.code,
                        userRoleResponse.role_name_en,
                        userRoleResponse.role_name_tc,
                        userRoleResponse.role_name_sc
                    )
            );

            setGlobalVariable(
                (oldGlobalVariable) =>
                    new GlobalVariable(
                        oldGlobalVariable?.selections,
                        oldGlobalVariable?.signers,
                        oldGlobalVariable?.userRolesWithoutAdmin,
                        userRolesWithAdmin,
                        oldGlobalVariable?.homeThemeVariable,
                        oldGlobalVariable?.applicationTypes
                    )
            );
        }
    };

    const fetchUserRolesWithoutAdmin = async () => {
        const requestParams = {
            withoutAdmin: true
        };
        const userRoleResponses = await userService.getUserRoles(requestParams);

        if (userRoleResponses) {
            const userRolesWithoutAdmin = userRoleResponses.map(
                (userRoleResponse) =>
                    new UserRole(
                        userRoleResponse.id,
                        userRoleResponse.code,
                        userRoleResponse.role_name_en,
                        userRoleResponse.role_name_tc,
                        userRoleResponse.role_name_sc
                    )
            );

            setGlobalVariable(
                (oldGlobalVariable) =>
                    new GlobalVariable(
                        oldGlobalVariable?.selections,
                        oldGlobalVariable?.signers,
                        userRolesWithoutAdmin,
                        oldGlobalVariable?.userRolesWithAdmin,
                        oldGlobalVariable?.homeThemeVariable,
                        oldGlobalVariable?.applicationTypes
                    )
            );
        }
    };

    const fetchSelectionData = async () => {
        try {
            loading(true);
            const selectionResponses = await publicService.getAllSelectionForNewLicenceApplication();
            // const selectionResponses = await systemService.getAllSelection();
            if (selectionResponses) {
                const selections: Selection[] = selectionResponses.map((selectionResponse) => {
                    const values = selectionResponse.values?.map(
                        (valueResponse) =>
                            new SelectionValue(
                                valueResponse.id,
                                valueResponse.data_value,
                                valueResponse.display_value_en,
                                valueResponse.display_value_tc,
                                valueResponse.display_value_sc,
                                valueResponse.status,
                                valueResponse.updated_by,
                                valueResponse.updated_date
                            )
                    );

                    return new Selection(
                        selectionResponse.code,
                        selectionResponse.id,
                        selectionResponse.name_en,
                        selectionResponse.name_tc,
                        selectionResponse.name_sc,
                        selectionResponse.allow_edit,
                        selectionResponse.updated_by,
                        selectionResponse.updated_date,
                        values
                    );
                });

                setGlobalVariable(
                    (oldGlobalVariable) =>
                        new GlobalVariable(
                            selections,
                            oldGlobalVariable?.signers,
                            oldGlobalVariable?.userRolesWithoutAdmin,
                            oldGlobalVariable?.userRolesWithAdmin,
                            oldGlobalVariable?.homeThemeVariable,
                            oldGlobalVariable?.applicationTypes
                        )
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
            setRefresh(false);
        }
    };

    return (
        <GlobalVariableContext.Provider
            value={{
                globalVariable: globalVariable || new GlobalVariable(),
                refresh: setRefresh,
                getOptionsByType,
                getOptionByTypeAndId,
                getOptionByTypeAndValue,
                getApplicationTypeByTypeCode,
                fetchSelectionData
            }}
        >
            {children}
        </GlobalVariableContext.Provider>
    );
};

export { GlobalVariableProvider, GlobalVariableContext };
