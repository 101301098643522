export interface IUserDetail {
    token: string;
    userProfile?: IUserProfile;
    functionControls?: IFunctionControl;
    menuControls?: IMenuControl[];
    home?: IUserHome;
    role?: IUserRole;
}

export interface IUserProfile {
    loginId: string;
    userId: number;
    mobile?: string;
    email?: string;
    userNameEn?: string;
    userNameChi?: string;
    iamsmart: boolean;
}

export interface IFunctionControl {
    [key: string]: any;
}

export interface IMenuControl {
    main: string;
    seq: number;
    sub?: IMenuControl[];
}

export interface IUserHome {
    homeId: number;
    homeType: string;
}

export interface IUserRole {
    userRoleId: number;
    userRoleNameEn?: string;
    userRoleNameTc?: string;
    userRoleNameSc?: string;
    managedUserRoles: number[];
}

export class UserDetail implements IUserDetail {
    constructor(
        public token: string,
        public userProfile?: IUserProfile,
        public functionControls?: IFunctionControl,
        public menuControls?: IMenuControl[],
        public home?: IUserHome,
        public role?: IUserRole
    ) {
        this.token = token;
        this.userProfile = userProfile;
        this.functionControls = functionControls;
        this.menuControls = menuControls;
        this.home = home;
        this.role = role;
    }
}
