export interface IGetNoticesRequest {
    pageNo?: number;
    pagesize?: number;
    orderby?: string;
    orderSequence?: string;
    sent_date?: string;
    title?: string;
}

export class GetNoticesRequest implements IGetNoticesRequest {
    constructor(
        public pageNo?: number,
        public pagesize?: number,
        public orderby?: string,
        public orderSequence?: string,
        public sent_date?: string,
        public title?: string
    ) {
        this.pageNo = pageNo;
        this.pagesize = pagesize;
        this.orderby = orderby;
        this.orderSequence = orderSequence;
        this.sent_date = sent_date;
        this.title = title;
    }
}
