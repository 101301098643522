import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import IamSmartButton from '../../forms-wizard/ValidationWizard/IamSmartButton';
import { FormattedMessage } from 'react-intl';
import { IamsmartContext } from 'contexts/IamsmartContext';
import { useContext } from 'react';
import TapImage from 'assets/images/tap.png';

export interface LinkupPopupProps {
    open: boolean;
    onClose: () => void;
}

const LinkupPopup = ({ open, onClose }: LinkupPopupProps) => {
    const { linkup, linkupStep, requestProfile, source, openIamSmartApp } = useContext(IamsmartContext);

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <RequestLinkup />;
            case 1:
                return <RequestProfile />;
            case 2:
                return <Instruction />;
            default:
                return 'Unknown step';
        }
    };

    const getStepTitle = (step: number): string => {
        switch (step) {
            case 0:
                return 'iamsmart.linkup';
            case 1:
                return 'iamsmart.profile.title';
            case 2:
                return 'iamsmart.profile.title';
            default:
                return 'Unknown step';
        }
    };

    const RequestLinkup = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    <FormattedMessage id="iamsmart.linkup.popup.content" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <IamSmartButton label="signature.continueWithIamSmart" onClick={linkup} />
            </Grid>
        </Grid>
    );

    const RequestProfile = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <FormattedMessage id="iamsmart.profile.request.header" />
                </Typography>
                <Typography variant="h4">
                    <FormattedMessage id="iamsmart.profile.request.content" />
                </Typography>
                <ul>
                    <li>
                        <Typography variant="h5" sx={{ listStyleType: 'circle' }}>
                            <FormattedMessage id="iamsmart.profile.request.field.hkid" />
                        </Typography>
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                <IamSmartButton label="iamsmart.profile" onClick={requestProfile} />
            </Grid>
        </Grid>
    );

    const Instruction = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    <FormattedMessage id="iamsmart.profile.authorize.instruction" />
                </Typography>
                <Typography variant="body2" sx={{ color: '#2b7367' }}>
                    <FormattedMessage id="iamsmart.profile.authorize.instruction1" />
                </Typography>
                {source === 'PC_Browser' && <img src={TapImage} style={{ height: '100px' }} />}
                <Typography variant="body2">
                    <FormattedMessage id="iamsmart.profile.authorize.instruction2" />
                </Typography>
                <Typography variant="body2">
                    <FormattedMessage id="iamsmart.profile.authorize.instruction3" />
                </Typography>
            </Grid>
            {source !== 'PC_Browser' && (
                <Grid item xs={12}>
                    <IamSmartButton label="iamsmart.open" onClick={openIamSmartApp} sx={{ margin: 'auto' }} />
                </Grid>
            )}
        </Grid>
    );
    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title" onClose={onClose} maxWidth="md">
            <DialogTitle id="customized-dialog-title">
                <Stack direction="row">
                    <Typography variant="h3" flex={1} marginRight={5}>
                        <FormattedMessage id={getStepTitle(linkupStep)} />
                    </Typography>
                    <Close onClick={onClose} />
                </Stack>
            </DialogTitle>

            <DialogContent sx={{}} dividers>
                {getStepContent(linkupStep)}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="error" autoFocus onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LinkupPopup;
