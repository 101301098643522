import { SyntheticEvent, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface MainTabsProps<T> {
    headers: MainTabsHeader[];
    defaultIndex?: number;
    state?: T;
}

export interface MainTabsHeader {
    label: string;
    route: string;
}

const MainTabs = <T,>({ headers, defaultIndex = 0, state }: MainTabsProps<T>) => {
    const [value, setValue] = useState(defaultIndex);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    return (
        <Tabs
            value={value}
            variant="scrollable"
            onChange={handleChange}
            sx={{
                px: 1,
                pb: 2,
                '& a': {
                    minWidth: 10,
                    px: 1,
                    py: 1.5,
                    mr: 2.25,
                    color: 'grey.600',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                '& a.Mui-selected': {
                    color: 'primary.main'
                },
                '& a > svg': {
                    marginBottom: '0px !important',
                    mr: 1.25
                }
            }}
        >
            {headers.map((header, index) => (
                <Tab
                    sx={{ textTransform: 'none' }}
                    component={Link}
                    to={header.route}
                    label={<FormattedMessage id={header.label} />}
                    {...a11yProps(index)}
                    key={`tab${index}`}
                    state={state}
                />
            ))}
        </Tabs>
    );
};

export default MainTabs;
