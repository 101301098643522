import { Container, Grid, Link, Box } from '@mui/material';
import { gridSpacing } from '../../../store/constant';
import { styled, useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import React, { CSSProperties } from 'react';
import useGlobalConfig from '../../../hooks/useGlobalConfig';

export interface FooterWrapperProps {
    children: React.ReactNode | string;
    sx: CSSProperties;
}

const FooterLink = styled(Link)({
    color: 'black',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none !important',
    opacity: '0.8',
    '& svg': {
        fontsize: '1.125rem',
        marginRight: 8
    },
    '&:hover': {
        opacity: '1'
    }
});

const FooterWrapper = ({ children, sx }: FooterWrapperProps) => (
    <Box component="div" sx={{ ...sx }}>
        {children}
    </Box>
);

const Footer = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { isLoggedIn } = useAuth();
    const { globalVariable } = useGlobalConfig();
    const handleClick = (link: string) => {
        navigate(`${isLoggedIn ? '/user' : ''}/${link}`, { replace: true });
    };
    const greymode = !!window.config.GREY_LANDING_PAGE && window.location.pathname === '/login';
    return (
        <>
            <FooterWrapper
                sx={{
                    padding: '35px 0',
                    color: theme.palette.common.black,
                    background: greymode ? 'rgb(211,211,211)' : `rgb(${globalVariable.homeThemeVariable?.headerColor})`,
                    [theme.breakpoints.down('md')]: {
                        textAlign: 'center'
                    },
                    position: 'relative'
                }}
            >
                <Container>
                    <Grid
                        container
                        alignItems="center"
                        spacing={gridSpacing}
                        sx={{ display: 'flex', justifyContent: { md: 'center', lg: 'flex-end' } }}
                    >
                        <Grid item>
                            <FooterLink onClick={() => handleClick('copyright')} underline="hover" sx={{ cursor: 'pointer' }}>
                                <FormattedMessage id="footer.footer-link.copyright" />
                            </FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink onClick={() => handleClick('disclaimer')} underline="hover" sx={{ cursor: 'pointer' }}>
                                <FormattedMessage id="footer.footer-link.disclaimer" />
                            </FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink onClick={() => handleClick('privacy-policy')} underline="hover" sx={{ cursor: 'pointer' }}>
                                <FormattedMessage id="footer.footer-link.privacy-policy" />
                            </FooterLink>
                        </Grid>
                    </Grid>
                </Container>
            </FooterWrapper>
        </>
    );
};

export default Footer;
