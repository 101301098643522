import { FormControl, FormHelperText, TextField, FormLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import { useFormikContext } from 'formik';
import { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

interface DateFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const DateField = memo((props: DateFieldProps) => {
    const { name, value = null, metadata, onChange, disabled } = props;

    const { getFieldMeta, setFieldValue } = useFormikContext();

    const onValueChange = (date: any) => {
        const newValue = date;
        onChange(newValue);
    };

    useEffect(() => {
        if (!value && metadata.defaultValue) {
            setFieldValue(name, metadata.defaultValue);
        }
    }, []);
    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    mask="____-__-__"
                    inputFormat="yyyy-MM-dd"
                    value={value}
                    onChange={onValueChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: 'YYYY-MM-DD'
                            }}
                        />
                    )}
                    disabled={disabled}
                />
                {/* <DateTimePicker value={new Date()} onChange={onValueChange} renderInput={(params) => <TextField {...params} />} /> */}
            </LocalizationProvider>
            {Boolean(getFieldMeta(name).error) && (
                <FormHelperText error id={name}>
                    <FormattedMessage id={getFieldMeta(name).error} />
                </FormHelperText>
            )}
        </FormControl>
    );
});

export { DateField };
