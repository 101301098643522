import { memo, useEffect, useState } from 'react';
import { FormControl, OutlinedInput, FormLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';

interface StringFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const StringField = memo((props: StringFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: any) => {
        const newValue = event.target.value;
        setValue(newValue);
        setChanged(true);
    };

    const [_value, setValue] = useState<any>(value);
    const [changed, setChanged] = useState<boolean>(false);

    const onBlur = (event: React.FocusEvent<any>) => {
        if (changed) {
            onChange(_value);
            setChanged(false);
        }
    };

    const inputProps = {
        maxLength: metadata.maxLength
    };

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <FormControl fullWidth>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <Grid item sm={metadata.width || 12}>
                <OutlinedInput
                    id={name}
                    name={name}
                    value={_value}
                    onChange={onValueChange}
                    onBlur={onBlur}
                    rows={metadata.rows}
                    multiline={!!metadata.rows}
                    inputProps={inputProps}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { StringField };
