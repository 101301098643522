// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
// auth provider
import { KeycloakProvider as AuthProvider } from 'contexts/KeycloakContext';
import { ConfirmDialogProvider } from 'contexts/ConfirmDialogContext';
import { LoadingProvider } from 'contexts/LoadingContext';
import { ApiInterceptor } from 'contexts/ApiInterceptor';
import 'themes/fontello/css/fontello-reform.css';
import { GlobalVariableProvider } from './contexts/GlobalVariableContext';
import { ApiClientProvider } from './contexts/ApiClientContext';
import { IamsmartContextProvider } from 'contexts/IamsmartContext';
import { DialogProvider } from 'contexts/DialogContext';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <LoadingProvider>
                    <DialogProvider>
                        <ConfirmDialogProvider>
                            <NavigationScroll>
                                <AuthProvider>
                                    <ApiClientProvider>
                                        <GlobalVariableProvider>
                                            <IamsmartContextProvider>
                                                <Routes />
                                                <Snackbar />
                                            </IamsmartContextProvider>
                                        </GlobalVariableProvider>
                                    </ApiClientProvider>
                                </AuthProvider>
                            </NavigationScroll>
                        </ConfirmDialogProvider>
                    </DialogProvider>
                </LoadingProvider>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
