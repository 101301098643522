// material-ui
import { useEffect, useState } from 'react';
// project imports
import { useLoading } from 'hooks/useLoading';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import MainTable, { MainTableHeader } from 'ui-component/tables/MainTable';
import useApiClient from '../../../hooks/useApiClient';
import useConfig from '../../../hooks/useConfig';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
// ==============================|| SAMPLE PAGE ||============================== //

const AccountsPage = () => {
    const loading = useLoading();
    const navigate = useNavigate();
    const { userService } = useApiClient();
    const { locale } = useConfig();
    const [accountTypeFieldName, setAccountTypeFieldName] = useState<string>('user_type_en');

    useEffect(() => {
        switch (locale) {
            case 'en':
                setAccountTypeFieldName('user_type_en');
                break;
            case 'zh-HK':
                setAccountTypeFieldName('user_type_tc');
                break;
            case 'zh-CN':
                setAccountTypeFieldName('user_type_sc');
                break;
            default:
                setAccountTypeFieldName('user_type_en');
        }
    }, [locale]);

    const onRowSelected = (row: any) => navigate('/create-home-user', { state: { id: row.id } });

    const doUpdateStatus = async (id: number, status: 'ACTIVE' | 'INACTIVE') => {
        try {
            loading(true);
            await userService.updateStatus(
                {
                    status
                },
                id
            );
            getAccounts();
        } finally {
            loading(false);
        }
    };
    const accountsHeader: MainTableHeader[] = [
        { id: 'login_id', label: 'account.management.login-id' },
        { id: 'user_name_eng', label: 'account.management.account-name' },
        { id: accountTypeFieldName, label: 'account.management.role' },
        { id: 'status', label: 'account.management.status', type: 'value', selectionType: 'ACCOUNT_STATUS' },
        { id: 'created_date', label: 'account.management.creation-date', type: 'date' },
        {
            id: 'deactivate',
            label: 'action',
            type: 'actions',
            actions: [
                {
                    label: 'account.management.deactivate',
                    handler: (item: any) => {
                        updateStatus(item.id, item.status);
                    },
                    condition: (item: any) => item.status === 'ACTIVE'
                },
                {
                    label: 'account.management.activate',
                    handler: (item: any) => {
                        updateStatus(item.id, item.status);
                    },
                    condition: (item: any) => item.status === 'INACTIVE'
                },
                {
                    label: 'account.management.reset',
                    handler: (item: any) => {
                        resetPassword(item.id);
                    },
                    condition: () => true
                }
            ]
        }
    ];
    const updateStatus = (id: number, status: string) => {
        dialog.setDialog({
            title: 'confirmation',
            cancelBtn: 'no',
            actionBtn: 'yes',
            content: status === 'ACTIVE' ? 'account.deactivate.confirm' : 'account.activate.confirm',
            onAction: () => {
                if (status === 'ACTIVE') {
                    doUpdateStatus(id, 'INACTIVE');
                } else {
                    doUpdateStatus(id, 'ACTIVE');
                }
            }
        });
    };
    const [accounts, setAccounts] = useState([]);
    const [queryModel, setQueryModel] = useState<any>({});
    const [rowCount, setRowCount] = useState<number>(0);
    const dialog = useConfirmDialog();

    const getAccounts = async () => {
        try {
            loading(true);
            const { home_accounts, total } = await userService.getUsers(queryModel);
            if (home_accounts) {
                setAccounts(home_accounts);
            }
            setRowCount(total);
        } finally {
            loading(false);
        }
    };

    useEffect(() => {
        getAccounts();
    }, [queryModel]);
    const sayHello = () => {
        navigate('/create-home-user', {});
    };

    const resetPassword = async (id: any) => {
        console.log(`reset password of user ID ${id}`);
        try {
            loading(true);
            const result = await userService.resetPassword(id);
        } finally {
            loading(false);
        }
    };

    return (
        <MainCard title="menu-list.account.management" contentSX={{ textAlign: 'end' }}>
            <Button onClick={sayHello} variant="contained" color="error" sx={{ mb: 2 }}>
                +
            </Button>
            <MainTable
                onSelect={onRowSelected}
                headers={accountsHeader}
                rows={accounts}
                rowCount={rowCount}
                onSort={(sortModel) => {
                    console.log('===sortModel===', sortModel);

                    // if (sortModel.length > 0) {
                    //     const { field, sort } = sortModel[0];
                    //     setQueryModel((prev: any) => ({ ...prev, ...{ orderBy: field, order: sort } }));
                    // }
                }}
            />
        </MainCard>
    );
};

export default AccountsPage;
