import { useLoading } from '../../../../hooks/useLoading';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from '../../../../store/constant';
import { Add } from '@mui/icons-material';
import MainTable, { MainTableHeader } from '../../../../ui-component/tables/MainTable';
import React, { useEffect, useState } from 'react';
import PlainTextPopup from '../../../../ui-component/popup/PlainTextPopup';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useApiClient from '../../../../hooks/useApiClient';
import useAuth from '../../../../hooks/useAuth';

const AccountAssociationPage = () => {
    const loading = useLoading();
    const [associatedAccounts, setAssociatedAccounts] = useState([]);
    const [popupName, setPopupName] = useState<string>('');
    const [userId, setUserId] = useState<number>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [usernameForSwitching, setUsernameForSwitching] = useState<string>('');
    const context = useAuth();
    const { userService } = useApiClient();
    const [otpDisabled, setOTPDisabled] = useState(true);
    const associationCreationPopupName = 'associationPopup';
    const switchAccountConfirmationPopupName = 'switchAccountConfirmationPopup';
    const [timer, setTimer] = useState(0);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            verificationCode: ''
        },
        onSubmit: (values) => {}
    });

    useEffect(() => {
        const { userProfile } = context.user || {};
        const { userId: id } = userProfile || {};

        setUserId(id);
    }, []);

    useEffect(() => {
        if (userId) {
            fetchAssociatedAccounts(userId);
        }
    }, [userId]);

    const associatedAccountHeaders: MainTableHeader[] = [
        { id: 'login_id', label: 'account-association.table.associated-account' },
        { id: 'created_date', label: 'account-association.table.creation-date', type: 'date' },
        {
            id: 'action',
            label: 'action',
            type: 'actions',
            actions: [
                {
                    label: 'account-association.table.action.switch',
                    handler: (item: any, event: MouseEvent) => {
                        console.log(item);

                        const { login_id: loginId } = item || {};

                        if (loginId) {
                            setUsernameForSwitching(loginId);
                            setPopupName(switchAccountConfirmationPopupName);
                        }
                    }
                },
                {
                    label: 'account-association.table.action.delete',
                    handler: (item: any, event: MouseEvent) => {
                        const { id } = item;

                        deleteAssociatedAccount(id);
                    }
                }
            ]
        }
    ];

    const handleClick = (buttonType: string) => {
        if (buttonType === 'openCreationPopup') {
            setPopupName(associationCreationPopupName);
        }
        if (buttonType === 'closeCreationPopup') {
            setPopupName('');
        }
        if (buttonType === 'showPassword') {
            setShowPassword(!showPassword);
        }
        if (buttonType === 'associateAccount') {
            const { username, password, verificationCode } = formik.values;

            const requestBody = {
                username,
                password,
                verification_code: verificationCode
            };
            if (userId) {
                associateAccount(userId, requestBody);
            }
        }
        if (buttonType === 'switchAccount') {
            console.log('switch account');
            setPopupName('');

            if (context) {
                const { switchAccount } = context;
                switchAccount(usernameForSwitching);
            }
        }
    };

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault();
    };

    const fetchAssociatedAccounts = async (id: number) => {
        try {
            loading(true);
            const response = await userService.getAssociatedAccounts(id);
            if (response) {
                setAssociatedAccounts(response);
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    };

    const associateAccount = async (id: number, requestBody: any) => {
        try {
            loading(true);
            const updatedAssociatedAccounts = await userService.createAssociatedAccount(id, requestBody);
            if (updatedAssociatedAccounts) {
                setAssociatedAccounts(updatedAssociatedAccounts);
            }
            await context.refreshToken();
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
            setPopupName('');
        }
    };

    const deleteAssociatedAccount = async (id: number) => {
        try {
            if (userId) {
                loading(true);
                const params = {
                    revoke_user_id: id
                };
                const result = await userService.deleteAssociatedAccount(userId, params);
                if (result === 'success') {
                    await fetchAssociatedAccounts(userId);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    };

    const getEmailOtp = () => {
        const { username } = formik.values;

        userService.getOtp(username);
        setTimer(60);
        setOTPDisabled(false);
    };

    useEffect(() => {
        timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
    }, [timer]);

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={10} />
                <Grid item xs={2}>
                    <Button variant="contained" color="error" onClick={() => handleClick('openCreationPopup')}>
                        <Add />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MainTable headers={associatedAccountHeaders} rows={associatedAccounts} />
                </Grid>
            </Grid>
            <PlainTextPopup
                open={popupName === associationCreationPopupName}
                onClose={() => handleClick('closeCreationPopup')}
                onAction={() => handleClick('associateAccount')}
                actionBtn="login"
            >
                <form>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="account-association.creation.username" />
                                </InputLabel>
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                />
                                {formik.errors.username && <FormHelperText error>{formik.errors.username}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                <InputLabel required>
                                    <FormattedMessage id="account-association.creation.password" />
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClick('showPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {formik.errors.password && <FormHelperText error>{formik.errors.password}</FormHelperText>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing} sx={{ alignItems: 'center' }}>
                                <Grid item xs={8}>
                                    <Stack>
                                        <InputLabel required>
                                            <FormattedMessage id="account-association.creation.verification-code" />
                                        </InputLabel>
                                        <TextField
                                            required
                                            fullWidth
                                            id="verificationCode"
                                            name="verificationCode"
                                            value={formik.values.verificationCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
                                            disabled={otpDisabled}
                                        />

                                        {formik.errors.verificationCode && (
                                            <FormHelperText error>{formik.errors.verificationCode}</FormHelperText>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack>
                                        <Button disabled={timer > 0} variant="contained" onClick={getEmailOtp}>
                                            {timer > 0 ? `${timer}s` : <FormattedMessage id="account-association.creation.otp" />}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </PlainTextPopup>
            <PlainTextPopup
                open={popupName === switchAccountConfirmationPopupName}
                onClose={() => handleClick('closeCreationPopup')}
                onAction={() => handleClick('switchAccount')}
                actionBtn="yes"
                title="account-association.switch.title"
                cancelEnabled
                contentSx={{ width: { xs: 300 }, height: { xs: 100 } }}
            >
                <Typography variant="h3">
                    <FormattedMessage id="account-association.switch.message" values={{ username: usernameForSwitching.toUpperCase() }} />
                </Typography>
            </PlainTextPopup>
        </>
    );
};

export default AccountAssociationPage;
