import { isNil, isEmpty } from 'lodash';
import moment from 'moment';

const charsetRe = {
    alphanumeric: /^[\x00-\x7F]*$/,
    alphabets: /^[ A-Za-z ]*$/,
    number: /^[0-9.-]*$/,
    digits: /^[0-9]*$/,
    chinese:
        /^([\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FEF\uF900-\uFA6D\uFA70-\uFAD9]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|\uD87E[\uDC00-\uDE1D])*$/,
    chinesenumeric: /^[\u4E00-\u9FFF0-9\-()（）\s]*$/,
    timeRegex: /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/ // HH:MM AM/PM format
};
const hkidPattern = /^([A-Z]{1,2})([0-9]{6})$/;
// Support for RFC2822
const emailPattern =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const validator = (value: any, metadata: any, dependsField?: any, staffPerson: any[] = [], index?: number) => {
    console.info(`validator value:${value} metadata:${JSON.stringify(metadata)}`);
    const errors = [];

    // check required
    if (metadata.required) {
        console.log(`Required Value-${metadata.name}-${value}`);
        // untick checkbox value = false not undefined / null
        if (metadata.type === 'number') {
            console.log(`Required Value-Is Number-${metadata.name}-${value}`);
            if (isNil(value)) {
                console.log(`Required Value-Is Number-Is Nil-${metadata.name}-${value}`);
                errors.push('error.required');
            }

            // hkid handle later
        } else if (metadata.type !== 'Hkid' && !value) {
            if (typeof value === 'number') {
                if (value === null || value < 0) {
                    errors.push('error.required');
                }
            } else if (
                (metadata.check === 'uploadAtLeastOneFile' && value === undefined && dependsField === undefined) ||
                metadata.check !== 'uploadAtLeastOneFile'
            ) {
                errors.push('error.required');
            }
        } else if (metadata.type === 'OptionList' && typeof value === 'object') {
            errors.push('error.required');
        }
    }

    if (metadata.type === 'Hkid') {
        if (!value) {
            console.info(`empty hkid`);
            if (metadata.required) {
                errors.push('error.id.required');
                errors.push('error.checkDigit.required');
            }
        } else {
            console.info(`not empty hkid`);
            const openParIndex = value.indexOf('(');
            const closeParIndex = value.indexOf(')');
            let id;
            let checkDigit;

            if (openParIndex > 0) {
                id = (<string>value).substring(0, openParIndex);
            }
            if (openParIndex > -1 && closeParIndex > 0 && openParIndex + 1 < closeParIndex) {
                checkDigit = (<string>value).substring(openParIndex + 1, closeParIndex);
            }
            console.info(`digested id:${id} checkDigit:${checkDigit}`);

            if (!id) {
                if (metadata.required) {
                    errors.push('error.id.required');
                }
            } else {
                if (id.length < 7) {
                    errors.push('error.id.minLength');
                }
                if (!charsetRe.alphanumeric.test(id)) {
                    errors.push('error.id.alphanumeric');
                }
            }

            if (!checkDigit) {
                if (metadata.required) {
                    errors.push('error.checkDigit.required');
                }
            } else if (!charsetRe.alphanumeric.test(checkDigit)) {
                errors.push('error.checkDigit.alphanumeric');
            }

            if (id && checkDigit && !checkHKIDCheckDigit(id, checkDigit)) {
                errors.push('error.checkDigit.invalid');
            }

            if (id && checkDigit && checkHKIDCheckDigit(id, checkDigit)) {
                const allHkid: string[] = [];
                staffPerson
                    .filter((fi, i) => i !== index)
                    .map((item) => {
                        if (item?.otherIdentityDoc && item?.otherIdentityDoc.length > 0) {
                            allHkid.push(item?.otherIdentityDoc);
                            const insertIndex = item?.otherIdentityDoc.length - 1;
                            allHkid.push(`${item?.otherIdentityDoc.slice(0, insertIndex)}(${item?.otherIdentityDoc.slice(insertIndex)})`);
                        }
                        if (item?.hkidNo && item?.hkidNo.length > 0) {
                            allHkid.push(item?.hkidNo);
                        }
                    });
                if (metadata.name === 'hkidNo' && allHkid.includes(value)) {
                    errors.push('error.checkDigit.same');
                }
            }
        }
    }

    if (metadata.name === 'otherIdentityDoc' && value && typeof index === 'number') {
        const currentHkidNo = staffPerson[index]?.hkidNo ?? '';
        const newCurrentHkidNo = currentHkidNo.replace(/[()]/g, '');
        const allOtherIdentity: string[] = [];
        staffPerson
            .filter((fi, i) => i !== index)
            .map((item) => {
                if (item?.otherIdentityDoc && item?.otherIdentityDoc.length > 0) {
                    allOtherIdentity.push(item?.otherIdentityDoc);
                }
                if (item?.hkidNo && item?.hkidNo.length > 0) {
                    allOtherIdentity.push(item?.hkidNo);
                    allOtherIdentity.push(item?.hkidNo.replace(/[()]/g, ''));
                }
            });
        if (allOtherIdentity.includes(value) || currentHkidNo === value || value === newCurrentHkidNo) {
            errors.push('error.noSame');
        }
    }

    if (metadata.check === 'digits' && value && !charsetRe.digits.test(value)) {
        errors.push('error.digitOnly');
    }

    if (metadata.check === 'email' && value && !emailPattern.test(value)) {
        errors.push('error.email.format');
    }

    if (metadata.check === 'chinese' && value && !charsetRe.chinese.test(value)) {
        errors.push('error.chineseOnly');
    }

    if (metadata.check === 'chinesenumeric' && value && !charsetRe.chinesenumeric.test(value)) {
        errors.push('error.chinesenumeric');
    }

    if (metadata.check === 'totalHour') {
        console.log(value);
        if (!value) {
            errors.push('sh.error.totalHour');
        } else if (metadata.checkTotalMin) {
            if (Number(value) < metadata.checkTotalMin) {
                errors.push('sh.error.totalHour');
            }
        }
    }

    if (metadata.check === 'english' && value && !charsetRe.alphabets.test(value)) {
        errors.push('error.englishOnly');
    }

    if (metadata.check === 'alphanumeric' && value && !charsetRe.alphanumeric.test(value)) {
        errors.push('error.alphanumericOnly');
    }

    if (value && metadata.length) {
        if (value.length !== metadata.length) {
            errors.push(`error.length.${metadata.length}`);
        }
    }

    if (metadata.type === 'Time' && value && JSON.stringify(value) !== '{}') {
        const formattedTime = moment(new Date(value)).format('hh:mm A');
        if (!charsetRe.timeRegex.test(formattedTime)) {
            errors.push('error.validTimeFormat');
        }
    }

    if (metadata.check === 'onOrBeforeToday' && value) {
        const date = moment(value);
        console.info(`onOrBeforeToday compare date:${date.toISOString()} now:${moment().utc().toISOString()}`);
        if (date.isValid() && !date.isSameOrBefore(moment().utc())) {
            errors.push('error.onOrBeforeToday');
        }
    }

    if (metadata.check === 'onOrAfterToday' && value) {
        const date = moment(value).startOf('day');
        console.info(`onOrBeforeToday compare date:${date.toISOString()} now:${moment().utc().toISOString()}`);
        if (date.isValid() && date.isAfter(moment().utc().startOf('day'))) {
            errors.push('error.onOrAfterToday');
        }
    }

    if (metadata.check === 'checkDateAfterSpecificDate' && value && dependsField) {
        const date = moment(value);
        const dependsDate = moment(dependsField);

        console.log('In checkDateAfterSpecificDate', date, dependsDate);
        if (date.isValid() && dependsDate.isValid() && date.isBefore(dependsDate)) {
            errors.push('error.onOrAfter');
        }
    }

    if (metadata.check === 'checkEqualToTypingPassword' && value && dependsField) {
        const confirmPassword = value;
        const setUpPassword = dependsField;

        console.log('In checkEqualToTypingPassword', confirmPassword, setUpPassword);
        if (confirmPassword !== setUpPassword) {
            errors.push('error.checkEqualToTypingPassword');
        }
    }

    if (metadata.check === 'checkMoreThanSpecificDependsField' && value && dependsField) {
        if (typeof Number(value) == 'number' && typeof Number(dependsField) == 'number' && Number(value) > Number(dependsField)) {
            if (metadata.checkMsg) {
                errors.push(metadata.checkMsg);
            } else {
                errors.push('error.checkMoreThanSpecificDependsField');
            }
        }
    }

    if (metadata.check === 'checkLessThanOrEqualToSpecificDependsField' && value && dependsField) {
        if (typeof Number(value) == 'number' && typeof Number(dependsField) == 'number' && Number(value) <= Number(dependsField)) {
            if (metadata.checkMsg) {
                errors.push(metadata.checkMsg);
            } else {
                errors.push('error.checkLessThanOrEqualToSpecificDependsField');
            }
        }
    }

    if (metadata.check === 'exceedWeeklyWorkingHoursOrNot' && value) {
        if (typeof Number(value) === 'number' && Number(value) > 24 * 7) {
            errors.push('error.exceedWeeklyWorkingHours');
        }
    }
    return errors.join(',');
};

const checkHKIDCheckDigit = (hkid: string, checkDigit: string) => {
    let valid = true;

    if (!isEmpty(hkid) && !isEmpty(checkDigit)) {
        const matchArray = hkid.toUpperCase().match(hkidPattern);

        if (matchArray === null) {
            valid = false;
        } else {
            const charPart = matchArray[1];
            const numPart = matchArray[2];

            // calculate the checksum for character part
            const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let checkSum = 0;
            if (charPart.length === 2) {
                checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
                checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
            } else {
                checkSum += 9 * 36;
                checkSum += 8 * (10 + strValidChars.indexOf(charPart));
            }

            // calculate the checksum for numeric part
            for (let i = 0, j = 7; i < numPart.length; i += 1, j -= 1) {
                checkSum += j * parseInt(numPart.charAt(i), 10);
            }

            // verify the check digit
            const remaining = checkSum % 11;
            const verify = remaining === 0 ? 0 : 11 - remaining;

            valid = verify.toString() === checkDigit || (verify === 10 && checkDigit === 'A');
        }
    }

    return valid;
};
