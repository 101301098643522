import { DialogContentProps } from '@mui/material/DialogContent/DialogContent';
import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle';
import { useState, createContext, FC, Dispatch, SetStateAction } from 'react';

import { ConfirmDialog } from 'ui-component/popup/ConfirmDialog/ConfirmDialog';

export interface ConfirmPopupContextProps {
    setDialog: Dispatch<SetStateAction<ConfirmPopupProps | undefined>>;
}

export interface ConfirmPopupProps {
    onClose?: () => void;
    cancelBtn?: string;
    onCancel?: () => void;
    title: string;
    titleSx?: DialogTitleProps['sx'];
    content: string;
    contentSx?: DialogContentProps['sx'];
    contentParams?: any;
    onAction?: () => void;
    actionBtn?: string;
}

const ConfirmDialogContext = createContext<ConfirmPopupContextProps | undefined>(undefined);

const ConfirmDialogProvider: FC = ({ children }) => {
    const [dialog, setDialog] = useState<ConfirmPopupProps>();

    const onClose = async () => {
        setDialog(undefined);
        if (dialog?.onClose) {
            await dialog.onClose();
        }
    };

    const onAction = async () => {
        setDialog(undefined);
        if (dialog?.onAction) {
            await dialog.onAction();
        }
    };

    const onCancel = async () => {
        setDialog(undefined);
        if (dialog?.onCancel) {
            await dialog.onCancel();
        }
    };

    return (
        <ConfirmDialogContext.Provider value={{ setDialog }}>
            {children}
            {dialog && (
                <ConfirmDialog
                    open
                    cancelBtn={dialog.cancelBtn}
                    onCancel={onCancel}
                    onClose={onClose}
                    title={dialog.title}
                    titleSx={dialog.titleSx}
                    content={dialog.content}
                    contentSx={dialog.contentSx}
                    contentParams={dialog.contentParams}
                    actionBtn={dialog.actionBtn}
                    onAction={onAction}
                />
            )}
        </ConfirmDialogContext.Provider>
    );
};

export { ConfirmDialogProvider, ConfirmDialogContext };
