import { ISelectionValue } from './selection-value';

export interface ISelection {
    code?: string;
    id?: number;
    nameEn?: string;
    nameTc?: string;
    nameSc?: string;
    allowEdit?: boolean;
    updatedBy?: string;
    updatedDate?: Date;
    values?: ISelectionValue[];
}

export class Selection implements ISelection {
    constructor(
        public code?: string,
        public id?: number,
        public nameEn?: string,
        public nameTc?: string,
        public nameSc?: string,
        public allowEdit?: boolean,
        public updatedBy?: string,
        public updatedDate?: Date,
        public values?: ISelectionValue[]
    ) {
        this.id = id;
        this.code = code;
        this.nameEn = nameEn;
        this.nameTc = nameTc;
        this.nameSc = nameSc;
        this.allowEdit = allowEdit;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
        this.values = values;
    }
}
