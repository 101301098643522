import React, { useState, useEffect } from 'react';
import flatten from 'flat';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';
import useConfig from 'hooks/useConfig';

// load locales files
const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'zh-HK':
            return import('utils/locales/zh-HK.json');
        case 'zh-CN':
            return import('utils/locales/zh-CN.json');
        default:
            return import('utils/locales/en.json');
    }
};

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
    children: React.ReactNode;
    overrideLocale?: string;
    noEngLocale?: boolean;
}

const Locales = ({ children, overrideLocale, noEngLocale = false }: LocalsProps) => {
    const { locale: _locale } = useConfig();
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();
    const locale = noEngLocale && _locale === 'en' ? 'zh-HK' : _locale;
    useEffect(() => {
        loadLocaleData(locale).then((d: any) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={locale} defaultLocale="zh-HK" messages={flatten(messages)} onError={() => {}}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
