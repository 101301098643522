// material-ui
import { Box } from '@mui/material';

// project import
import AuthCard, { AuthCardProps } from '../../../ui-component/cards/AuthCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }: AuthCardProps) => (
    <AuthCard
        sx={{
            maxWidth: { xs: 600, md: 800 },
            width: { xs: 300, md: 600 },
            margin: { xs: 1, md: 3 },
            borderRadius: 0,
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%'
            }
        }}
        headerSX={{
            backgroundColor: '#0A79A3'
        }}
        headerTextSX={{
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        }}
        content={false}
        {...other}
    >
        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </AuthCard>
);

export default AuthCardWrapper;
