import React, { useEffect, useRef, useState } from 'react';
import useApiClient from 'hooks/useApiClient';
import { useLoading } from 'hooks/useLoading';
import useAuth from 'hooks/useAuth';
import { Grid, Typography } from '@mui/material';
import { ValidationWizard } from 'ui-component/forms-wizard/ValidationWizard/ValidationWizard';

interface HomeInfo {
    id: number;
    lorch_no: string;
    name_chi: string;
    name_en: string;
    address_en: string;
    address_chi: string;
    office_phone_no: string;
    fax_no: string;
    email: string;
}

interface SHHome {
    lorchdNo?: string;
    rchChiName?: string;
    rchEngName?: string;
    rchEngAddr?: string;
    rchChiAddr?: string;
    rchTel?: string;
    rchFax?: string;
    rchEmail?: string;
}

interface Props {
    homeId: number;
    appType: 'SER' | 'CSH' | 'SH';
    homeInfo?: HomeInfo;
}

const UnstructuredFormDisplay = ({ homeId, appType, homeInfo }: Props) => {
    // const location = useLocation();
    const { applicationService } = useApiClient();
    const loading = useLoading();
    // const homeInfo = useRef<propState>(location.state as propState);
    // const { homeId } = homeInfo.current;
    const [data, setData] = useState<any>(null);
    const [metadata, setMetadata] = useState(undefined);
    const [hasForm, setHasForm] = useState<boolean>(true);
    const { user } = useAuth();
    const formId = `${appType}_${user!.home!.homeType}`;

    const shHomeInfo: SHHome = {
        lorchdNo: homeInfo?.lorch_no,
        rchChiName: homeInfo?.name_chi,
        rchEngName: homeInfo?.name_en,
        rchEngAddr: homeInfo?.address_en,
        rchChiAddr: homeInfo?.address_chi,
        rchTel: homeInfo?.office_phone_no,
        rchFax: homeInfo?.fax_no,
        rchEmail: homeInfo?.email
    };

    useEffect(() => {
        const fetchSERformData = async () => {
            try {
                loading(true);
                const response: any = await applicationService.getUnstructuredRecordOfHomeById(homeId, appType);
                if (response.length == 0) {
                    setHasForm(false);
                }
                if (response.length > 0) {
                    const dataContent = JSON.parse(response[0].data_content);
                    if (appType === 'SH' && !dataContent?.values?.rchInfoA) {
                        if (dataContent) {
                            dataContent.values.rchInfoA = shHomeInfo;
                        }
                    }
                    setData(dataContent.values);
                }
            } catch (error) {
                console.error(error);
            } finally {
                loading(false);
            }
        };

        const loadFile = async () => {
            try {
                loading(true);
                let file = await import(`forms/${formId}.ts`);
                file = file[formId];
                setMetadata(file);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSERformData();
        loadFile();
    }, []);

    return (
        <Grid container spacing={2} sx={{ mt: 'auto' }}>
            <Grid item>
                {!hasForm && !data && <Typography variant="body1">Nil</Typography>}
                {data && metadata && <ValidationWizard preview metadata={metadata} data={data} formId={formId} />}
            </Grid>
        </Grid>
    );
};

export default UnstructuredFormDisplay;
