import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentProps,
    DialogTitle,
    DialogTitleProps,
    Grid,
    Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { useEffect } from 'react';

export interface ConfirmPopupProps {
    open: boolean;
    onClose: () => void;
    cancelBtn?: string;
    onCancel?: () => void;
    title?: string;
    titleSx?: DialogTitleProps['sx'];
    content: string;
    contentSx?: DialogContentProps['sx'];
    contentParams?: any;
    onAction: () => void;
    actionBtn?: string;
    contentVarient?:
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'overline';
}

const ConfirmDialog = ({
    open,
    onClose,
    cancelBtn,
    onCancel,
    title,
    titleSx,
    content,
    contentSx,
    contentParams,
    onAction,
    actionBtn,
    contentVarient
}: ConfirmPopupProps) => {
    const closeDialog = (_event: object, reason: string) => {
        if (reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title" sx={{ minWidth: 250 }} onClose={closeDialog}>
            {title && (
                <DialogTitle id="customized-dialog-title" sx={{ ...titleSx }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormattedMessage id={title} />
                        <Close style={{ cursor: 'pointer', margin: 'auto 0' }} onClick={onClose} />
                    </div>
                </DialogTitle>
            )}

            <DialogContent sx={{ ...contentSx }} dividers>
                <Typography variant={contentVarient}>
                    <FormattedMessage id={content} values={{ ...contentParams }} />
                </Typography>
            </DialogContent>
            <Grid container justifyContent="flex-end">
                {cancelBtn && (
                    <DialogActions>
                        <Button variant="contained" color="error" autoFocus sx={{ textTransform: 'none' }} onClick={onCancel}>
                            <FormattedMessage id={cancelBtn} />
                        </Button>
                    </DialogActions>
                )}
                {actionBtn && (
                    <DialogActions>
                        <Button variant="contained" color="primary" autoFocus sx={{ textTransform: 'none' }} onClick={onAction}>
                            <FormattedMessage id={actionBtn} />
                        </Button>
                    </DialogActions>
                )}
            </Grid>
        </Dialog>
    );
};

export { ConfirmDialog };
