import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Typography, Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { base64Decode } from 'utils/crypto';

function SelectUser() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const decoded = code ? base64Decode(code) : '';
    const object = decoded ? JSON.parse(decoded) : {};
    const ids = object.ids;

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <MainCard>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="h1" fontWeight="100">
                                        <FormattedMessage id="iamsmart.selectAccount" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12}>
                                    <form method="POST" action={`${window.config.AUTH_SERVICE_DOMAIN}/api/selectUser`}>
                                        <Grid sx={{ display: { xs: 'flex' }, flexDirection: 'column', alignItems: 'center' }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue={ids[0]}
                                                name="target"
                                            >
                                                {ids.map((id: string) => (
                                                    <FormControlLabel value={id} control={<Radio />} label={id} />
                                                ))}
                                            </RadioGroup>
                                            <Box sx={{ mt: 3 }}>
                                                <Button type="submit" variant="contained" fullWidth size="large" color="primary">
                                                    <FormattedMessage id="submit" />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectUser;
