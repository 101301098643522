import { forwardRef, useImperativeHandle, ChangeEvent, useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    FormHelperText,
    OutlinedInput,
    Grid,
    Select,
    MenuItem,
    SelectChangeEvent,
    Stack,
    TextareaAutosize
} from '@mui/material';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    ADI,
    getRegionList,
    getDistrictList,
    getSubDistrictList,
    getBlockType,
    getUnitType,
    toChiFullAddress,
    toEnFullAddress,
    loadLocaleData
} from 'types/ADI';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { Pattern } from '../../../../constants/Pattern';
import useConfig from 'hooks/useConfig';

interface ADIFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const ErrorDisplay = ({ err, disabled }: { err: string | undefined; disabled: boolean }) => {
    if (err && !disabled) {
        return (
            <FormHelperText error>
                <FormattedMessage id={err} defaultMessage={err} />
            </FormHelperText>
        );
    }
    return <></>;
};

const ADIField = forwardRef((props: ADIFieldProps, ref) => {
    const { name, value, metadata, onChange, disabled } = props;
    const intl = useIntl();
    const [regionList, setRegionList] = useState<any[]>([]);
    const [districtList, setDistrictList] = useState<any[]>([]);
    const [subDistrictList, setSubDistrictList] = useState<any[]>([]);
    const [translation, setTranslation] = useState<any>(undefined);
    const { locale } = useConfig();
    const [changed, setChanged] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        async onValidate() {
            const error = await formik.validateForm();
            if (Object.keys(error).length > 0) {
                return 'error';
            }
            return '';
        }
    }));

    const langValidation = () =>
        metadata.locale !== 'en'
            ? Yup.string().matches(Pattern.chineseAndNumber, 'error.chineseOnly')
            : Yup.string().matches(Pattern.alphanumeric, 'error.alphanumericOnly');

    function requiredValidation(err: string): any {
        return metadata.required ? Yup.string().required(err) : Yup.string();
    }
    const requiredErr = 'error.required';
    const validationSchema = Yup.object().shape(
        {
            region: requiredValidation(requiredErr),
            district: requiredValidation(requiredErr),
            subDistrict: requiredValidation(requiredErr),
            streetNo: Yup.string().when('streetName', {
                is: (sn: string) => !isEmpty(sn),
                then: Yup.string().required(requiredErr)
            }),
            streetName: langValidation().when(['estateName', 'buildingName', 'lotNo'], {
                is: (en: string, bn: string, ln: string) => isEmpty(en) && isEmpty(bn) && isEmpty(ln),
                then: requiredValidation('address.lot/street/estate/building')
            }),
            phase: Yup.string(),
            estateName: langValidation().when(['streetName', 'buildingName', 'lotNo'], {
                is: (sn: string, bn: string, ln: string) => isEmpty(sn) && isEmpty(bn) && isEmpty(ln),
                then: requiredValidation('address.lot/street/estate/building')
            }),
            blockType: Yup.string(),
            blockTypeOther: langValidation().when('blockType', {
                is: (bt: string) => bt === 'Other',
                then: Yup.string().required(requiredErr)
            }),
            blockNo: Yup.string().when('blockType', {
                is: (bt: string) => !isEmpty(bt),
                then: Yup.string().required(requiredErr)
            }),
            buildingName: langValidation().when(['streetName', 'estateName', 'lotNo'], {
                is: (sn: string, en: string, ln: string) => isEmpty(sn) && isEmpty(en) && isEmpty(ln),
                then: requiredValidation('address.lot/street/estate/building')
            }),
            floor: Yup.string(),
            unitType: Yup.string(),
            unitTypeOther: langValidation().when('unitType', {
                is: (ut: string) => ut === 'Other',
                then: Yup.string().required(requiredErr)
            }),
            unitNo: Yup.string().when('unitType', {
                is: (ut: string) => !isEmpty(ut),
                then: Yup.string().required(requiredErr)
            }),
            lotNo: (metadata.locale === 'en' ? langValidation() : Yup.string()).when(['streetName', 'estateName', 'buildingName'], {
                is: (sn: string, en: string, bn: string) => isEmpty(sn) && isEmpty(en) && isEmpty(bn),
                then: requiredValidation('address.lot/street/estate/building')
            })
        },
        [
            ['streetName', 'estateName'],
            ['streetName', 'buildingName'],
            ['estateName', 'buildingName'],
            ['lotNo', 'estateName'],
            ['lotNo', 'buildingName'],
            ['lotNo', 'streetName']
        ]
    );

    const formik = useFormik<ADI>({
        initialValues: typeof value === 'string' || !value ? new ADI() : value,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            console.log(values);
        }
    });

    useEffect(() => {
        const loadTranslation = async () => {
            let l = metadata.locale;
            if (metadata.locale !== 'en' && locale !== 'en') {
                l = locale;
            }
            const t = await loadLocaleData(l);
            setTranslation(t);
        };
        loadTranslation();
    }, []);

    useEffect(() => {
        const loadRegionList = async () => {
            const regions = await getRegionList(translation);
            setRegionList(regions);
        };
        if (translation) {
            loadRegionList();
        }
    }, [translation]);

    useEffect(() => {
        const loadDistrictList = async () => {
            const districts = await getDistrictList(formik.values.region, translation);
            setDistrictList(districts);
        };
        if (translation) {
            loadDistrictList();
        }
    }, [formik.values.region, translation]);

    useEffect(() => {
        const loadSubDistrictList = async () => {
            const subDistricts = await getSubDistrictList(formik.values.district, translation);
            setSubDistrictList(subDistricts);
        };
        if (translation) {
            loadSubDistrictList();
        }
    }, [formik.values.district, translation]);

    const onValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        formik.setFieldTouched(event.target.name, false, false);
        formik.handleChange(event);
        if (event.target.name === 'region') {
            formik.setFieldValue('district', '');
            formik.setFieldValue('subDistrict', '');
        }
        if (event.target.name === 'district') {
            formik.setFieldValue('subDistrict', '');
        }
        setChanged(true);
    };

    const onBlur = (event: React.FocusEvent<any>) => {
        formik.handleBlur(event);
        if (changed) {
            const fullAddress = generateFullAddress(formik.values);
            formik.setFieldValue('fullAddress', fullAddress);
            const newValue: any = {
                ...formik.values,
                fullAddress
            };
            onChange(newValue);
        }
        setChanged(false);
    };

    const generateFullAddress = (newValue: any) => {
        if (metadata.locale === 'en') {
            return toEnFullAddress(newValue, translation);
        }
        return toChiFullAddress(newValue, translation);
    };

    return (
        <>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <Stack spacing={1}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.region" />
                            </FormLabel>
                            <Select
                                id={`${name}.region`}
                                name="region"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.region}
                                displayEmpty
                                disabled={disabled}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <FormattedMessage id="address.region" /> ...
                                </MenuItem>
                                {regionList.map((opt) => (
                                    <MenuItem value={opt.value} key={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <ErrorDisplay err={formik.errors.region} disabled={disabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.district" />
                            </FormLabel>
                            <Select
                                id={`${name}.district`}
                                name="district"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.district}
                                displayEmpty
                                disabled={disabled}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <FormattedMessage id="address.district" /> ...
                                </MenuItem>
                                {formik.values.region &&
                                    districtList.map((opt) => (
                                        <MenuItem value={opt.value} key={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <ErrorDisplay err={formik.errors.district} disabled={disabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.subDistrict" />
                            </FormLabel>
                            <Select
                                id={`${name}.subDistrict`}
                                name="subDistrict"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.subDistrict}
                                displayEmpty
                                disabled={disabled}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <FormattedMessage id="address.subDistrict" /> ...
                                </MenuItem>
                                {formik.values.district &&
                                    subDistrictList.map((opt) => (
                                        <MenuItem value={opt.value} key={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <ErrorDisplay err={formik.errors.subDistrict} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.streetNo" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.streetNo`}
                                name="streetNo"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.streetNo}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.streetNo} disabled={disabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.streetName" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.streetName`}
                                name="streetName"
                                value={formik.values.streetName}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.streetName} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.phase" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.phase`}
                                name="phase"
                                value={formik.values.phase}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.estateName" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.estateName`}
                                name="estateName"
                                value={formik.values.estateName}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.estateName} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <FormattedMessage id="address.lotNo" />
                        </FormLabel>
                        <OutlinedInput
                            id={`${name}.lotNo`}
                            name="lotNo"
                            value={formik.values.lotNo}
                            onChange={onValueChange}
                            onBlur={onBlur}
                            disabled={disabled}
                        />
                        <ErrorDisplay err={formik.errors.lotNo} disabled={disabled} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.blockType" />
                            </FormLabel>
                            <Select
                                id={`${name}.blockType`}
                                name="blockType"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.blockType}
                                displayEmpty
                                disabled={disabled}
                            >
                                <MenuItem value="">
                                    <FormattedMessage id="pleaseChoose" />
                                </MenuItem>
                                {translation &&
                                    getBlockType(translation).map((opt) => (
                                        <MenuItem value={opt.value} key={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <ErrorDisplay err={formik.errors.blockType} disabled={disabled} />
                        </FormControl>

                        {formik.values.blockType === 'Other' && (
                            <FormControl>
                                <FormLabel>&nbsp;</FormLabel>
                                <OutlinedInput
                                    id={`${name}.blockTypeOther`}
                                    name="blockTypeOther"
                                    value={formik.values.blockTypeOther}
                                    onBlur={onBlur}
                                    onChange={onValueChange}
                                    disabled={disabled}
                                    placeholder={intl.formatMessage({ id: 'pleaseSpecify' })}
                                />
                                <ErrorDisplay err={formik.errors.blockTypeOther} disabled={disabled} />
                            </FormControl>
                        )}
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.blockNo" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.blockNo`}
                                name="blockNo"
                                value={formik.values.blockNo}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.blockNo} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.buildingName" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.buildingName`}
                                name="buildingName"
                                value={formik.values.buildingName}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.buildingName} disabled={disabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.floor" />
                            </FormLabel>
                            <Stack direction="row" spacing={1}>
                                <OutlinedInput
                                    id={`${name}.floor`}
                                    name="floor"
                                    value={formik.values.floor}
                                    onChange={onValueChange}
                                    onBlur={onBlur}
                                    disabled={disabled}
                                />
                                <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                    <FormattedMessage id="address./F" />
                                </span>
                            </Stack>
                            <ErrorDisplay err={formik.errors.floor} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.unitType" />
                            </FormLabel>
                            <Select
                                id={`${name}.unitType`}
                                name="unitType"
                                onChange={onValueChange}
                                onBlur={onBlur}
                                value={formik.values.unitType}
                                displayEmpty
                                disabled={disabled}
                            >
                                <MenuItem value="">
                                    <FormattedMessage id="pleaseChoose" />
                                </MenuItem>
                                {translation &&
                                    getUnitType(translation).map((opt) => (
                                        <MenuItem value={opt.value} key={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <ErrorDisplay err={formik.errors.unitType} disabled={disabled} />
                        </FormControl>
                        {formik.values.unitType === 'Other' && (
                            <FormControl>
                                <FormLabel>&nbsp;</FormLabel>
                                <OutlinedInput
                                    id={`${name}.unitTypeOther`}
                                    name="unitTypeOther"
                                    value={formik.values.unitTypeOther}
                                    onBlur={onBlur}
                                    onChange={onValueChange}
                                    disabled={disabled}
                                    placeholder={intl.formatMessage({ id: 'pleaseSpecify' })}
                                />
                                <ErrorDisplay err={formik.errors.unitTypeOther} disabled={disabled} />
                            </FormControl>
                        )}
                        <FormControl>
                            <FormLabel>
                                <FormattedMessage id="address.unitNo" />
                            </FormLabel>
                            <OutlinedInput
                                id={`${name}.unitNo`}
                                name="unitNo"
                                value={formik.values.unitNo}
                                onChange={onValueChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                            <ErrorDisplay err={formik.errors.unitNo} disabled={disabled} />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <FormattedMessage id="address.fullAddress" />
                        </FormLabel>
                        <TextareaAutosize
                            id={`${name}.fullAddress`}
                            name="fullAddress"
                            disabled
                            value={formik.values.fullAddress}
                            minRows={2}
                            className="MuiOutlinedInput-notchedOutline"
                            style={{ width: '80%' }}
                        />
                    </FormControl>
                </Grid>
            </Stack>
        </>
    );
});

export { ADIField };
