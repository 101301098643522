import { $getRoot, $getSelection } from 'lexical';
import { useEffect, useState } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Box } from '@mui/system';
import Toolbar from './Toolbar';
import { CssBaseline } from '@mui/material';
import styled from '@emotion/styled';
import lexicalEditorTheme from './theme';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import LockPlugin from './component/LockPlugin';
import InitPlugin from './component/InitPlugin';
import ImagesPlugin from './component/ImagePlugin';
// import {  } from '@lexical/clipboard';
import './index.css';

import { ImageNode } from './component/ImageUtils/imageNode';
import DragDropPaste from './component/DragDropPastePlugin';

import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import TableCellResizer from './component/TableCellResizer';
import TableActionMenuPlugin from './component/TableActionMenuPlugin';
import { HeadingNode } from '@lexical/rich-text';
import LexicalLocales from './LexicalLocales';
import { FormattedMessage } from 'react-intl';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import FloatingLinkEditorPlugin from './component/FloatingLinkEditorPlugin';
import { validateUrl } from './tool/url';
import { CAN_USE_DOM } from './tool/canUseDOM';

export const MuiContentEditable = styled(ContentEditable)({
    minHeight: 200,
    width: '100%',
    borderRadius: 10,
    padding: 10,
    position: 'relative',
    outline: 'none',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderStyle: 'solid',
    overflowX: 'auto'
});

export const placeHolderSx = {
    position: 'absolute',
    top: 15,
    left: 10,
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none'
};

// eslint-disable-next-line @typescript-eslint/no-shadow
function MyOnChangePlugin({ onChange }: { onChange: any }) {
    const [editor] = useLexicalComposerContext();
    useEffect(
        () =>
            editor.registerUpdateListener(({ editorState }) => {
                onChange(editorState);
            }),
        [editor, onChange]
    );
    return null;
}
// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
    throw error;
}

function TextLexical({
    editorState,
    onChange,
    disabled,
    toolbarHidden
}: {
    editorState: string;
    onChange: (editorState: any) => void;
    disabled?: boolean;
    toolbarHidden?: boolean;
}) {
    const [defaultValue, setDefaultValue] = useState<any>(null);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | any>(null);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
    const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };
    useEffect(() => {
        if (defaultValue === null && editorState) {
            setDefaultValue(editorState);
        }
    }, [defaultValue, editorState]);

    useEffect(() => {
        const updateViewPortWidth = () => {
            const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

            if (isNextSmallWidthViewport !== isSmallWidthViewport) {
                setIsSmallWidthViewport(isNextSmallWidthViewport);
            }
        };
        updateViewPortWidth();
        window.addEventListener('resize', updateViewPortWidth);

        return () => {
            window.removeEventListener('resize', updateViewPortWidth);
        };
    }, [isSmallWidthViewport]);
    const initialConfig = {
        namespace: 'Editor',
        nodes: [ImageNode, TableNode, TableCellNode, TableRowNode, HeadingNode, ListItemNode, ListNode, LinkNode],
        theme: lexicalEditorTheme,
        onError
    };

    return (
        <LexicalLocales>
            <CssBaseline />
            <LexicalComposer initialConfig={initialConfig}>
                {!toolbarHidden && <Toolbar disabled={disabled} setIsLinkEditMode={setIsLinkEditMode} />}
                <RichTextPlugin
                    contentEditable={
                        <div className="editor" ref={onRef}>
                            <MuiContentEditable />
                        </div>
                    }
                    placeholder={
                        <Box sx={placeHolderSx}>
                            <FormattedMessage id="lexicalTip" />
                        </Box>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <MyOnChangePlugin onChange={onChange} />
                <DragDropPaste />
                <HistoryPlugin />
                <ImagesPlugin />
                <ListPlugin />
                <LinkPlugin validateUrl={validateUrl} />

                <TablePlugin hasCellMerge hasCellBackgroundColor />
                <TableCellResizer />
                {!isSmallWidthViewport && (
                    <>
                        <FloatingLinkEditorPlugin
                            isLinkEditMode={isLinkEditMode}
                            setIsLinkEditMode={setIsLinkEditMode}
                            disabled={disabled}
                        />
                        {!disabled && <TableActionMenuPlugin cellMerge />}
                    </>
                )}
                <LockPlugin disabled={disabled} />
                <InitPlugin editorState={defaultValue || null} />
            </LexicalComposer>
        </LexicalLocales>
    );
}

export default TextLexical;
