import { FormControl, FormHelperText, TextField, FormLabel, Box } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import { useFormikContext } from 'formik';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface RenderEventDateTimeFieldProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const RenderEventDateTimeField = memo((props: RenderEventDateTimeFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;
    const onValueChange = (date: any) => {
        const newValue = date;
        onChange(newValue);
    };
    console.log(value);

    return (
        <FormControl>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {value.length > 0 &&
                    value.map((item: any) => (
                        <div key={item.seq}>
                            <DateTimePicker
                                mask="____-__-__ __:__ _M"
                                inputFormat="yyyy-MM-dd hh:mm aa"
                                value={item.event_date_time}
                                onChange={onValueChange}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={disabled}
                            />
                            {value.length > 1 && <Box sx={{ mb: 0.7 }} />}
                        </div>
                    ))}
            </LocalizationProvider>
        </FormControl>
    );
});

export { RenderEventDateTimeField };
