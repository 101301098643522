import { Button, Dialog, DialogActions, DialogContent, DialogContentProps, DialogTitle, DialogTitleProps, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReactElement } from 'react';
import { gridSpacing } from '../../../store/constant';
import { FormattedMessage } from 'react-intl';

export interface PlainTextPopupProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    titleSx?: DialogTitleProps['sx'];
    contentSx?: DialogContentProps['sx'];
    children: ReactElement;
    onAction?: () => void;
    actionBtn?: string;
    cancelEnabled?: boolean;
}

const PlainTextPopup = ({
    open,
    onClose,
    title,
    titleSx,
    contentSx,
    children,
    onAction,
    actionBtn,
    cancelEnabled
}: PlainTextPopupProps) => (
    <Dialog open={open} aria-labelledby="customized-dialog-title" onClose={onClose}>
        {title && (
            <DialogTitle id="customized-dialog-title" sx={{ ...titleSx }}>
                <Grid container>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <FormattedMessage id={title} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Close onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitle>
        )}

        <DialogContent sx={{ ...contentSx }} dividers>
            {children}
        </DialogContent>
        <DialogActions>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {cancelEnabled && (
                        <Button variant="contained" color="error" onClick={onClose}>
                            <FormattedMessage id="account-association.switch.cancel" />
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {actionBtn && onAction && (
                    <Button variant="contained" color="primary" autoFocus onClick={onAction}>
                        <FormattedMessage id={actionBtn} />
                    </Button>
                )}
            </Grid>
        </DialogActions>
    </Dialog>
);

export default PlainTextPopup;
