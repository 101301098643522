import { Box, Button, FormLabel, FormControl, Stack, Typography } from '@mui/material';
import { memo, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import OrganizationSignaturePopup from './OrganizationSignaturePopup';
import { format } from 'date-fns';
import { Signature, SignResult } from 'types/form';
import { useFormikContext } from 'formik';
import { FormContext } from './FormContext';

interface OrganizationSignSectionProps {
    onSignClick: () => Promise<{ hkic: string; application?: any; applicationObj?: any }> | undefined;
    onSignSuccess: (v: any) => void;
    onSignCancel: () => void;
    getHash: (values: any) => Promise<string>;
    metadata?: Signature;
}

const OrganizationSignatureSection = memo((props: OrganizationSignSectionProps) => {
    const { onSignClick, onSignSuccess, onSignCancel, metadata, getHash } = props;
    const [signPopup, setSignPopup] = useState(false);
    const [result, setResult] = useState<SignResult | undefined>(undefined);
    const { values, validateForm } = useFormikContext();
    const { appType } = useContext(FormContext);

    const cancelSign = () => {
        setResult(undefined);
        onSignCancel();
    };

    const onSuccess = (signResult: SignResult) => {
        setResult(signResult);
        onSignSuccess(signResult);
    };

    const onSign = async () => {
        let data: any = await onSignClick();
        if (!data && metadata) {
            const dataToSignCallback = metadata.dataToSign;
            const dataToSign = dataToSignCallback?.({ values });
            data = dataToSign;
        }
        return getHash(data);
    };

    return (
        <FormControl fullWidth sx={{ alignItems: 'start' }}>
            <FormLabel id="signature">
                <Typography variant="h3" gutterBottom sx={{ mb: 2 }}>
                    <FormattedMessage id="signature.organizationSign" />
                </Typography>
            </FormLabel>
            {result ? (
                <Stack>
                    <Box
                        mb={2}
                        pl={1}
                        p={2}
                        sx={{
                            backgroundColor: '#f6f6f6'
                        }}
                    >
                        <Stack>
                            <Typography>
                                <FormattedMessage id="signature.signedBy" values={{ CN: result.commonName }} />
                            </Typography>
                            <Typography>
                                <FormattedMessage id="date:" />
                                {` ${format(new Date(result.timestamp), 'yyyy-MM-dd HH:mm:ss')}`}
                            </Typography>
                        </Stack>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        {/* <Button
                            variant="contained"
                            onClick={() => {
                                downloadCert(result.cert);
                            }}
                        >
                            <FormattedMessage id="cert" />
                        </Button> */}
                        <Button variant="contained" onClick={cancelSign}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Stack spacing={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={() => {
                            setSignPopup(true);
                        }}
                    >
                        <FormattedMessage id="sign" />
                    </Button>
                </Stack>
            )}
            <OrganizationSignaturePopup
                onSign={onSign}
                open={signPopup}
                onClose={() => {
                    setSignPopup(false);
                }}
                onSuccess={onSuccess}
            />
        </FormControl>
    );
});

export { OrganizationSignatureSection };
