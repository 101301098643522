import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Stack,
    TextField,
    FormHelperText
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEvent, useState } from 'react';
import Path from 'path';
import { xmlSignDetachedFiles } from 'utils/xmlsig';
import { CertificateType } from 'utils/certAuthority';
import { useLoading } from '../../../hooks/useLoading';

export interface OrganizationSignaturePopupProps {
    open: boolean;
    onClose?: () => void;
    onSuccess?: (result: any) => void;
    onSign: () => Promise<string>;
}

const OrganizationSignaturePopup = ({ open, onClose, onSuccess, onSign }: OrganizationSignaturePopupProps) => {
    const intl = useIntl();
    const [password, setPassword] = useState<string>('');
    const [keystore, setKeystore] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');
    const loading = useLoading();

    const errorMsgMap: any = {
        passwordIncorrect: 'signature.passwordIncorrect',
        privatePublicKeysNotMatch: 'signature.privatePublicKeysNotMatch',
        certExpired: 'signature.certExpired',
        certNotValidYet: 'signature.certNotValidYet',
        serverTimeFailed: 'error.system',
        RCA_NOT_SUPPORT: 'signature.rcaNotSupport',
        CERTIFICATE_TYPE_NOT_SUPPORT: 'signature.certTypeNotSupport',
        ID_TYPE_NOT_MATCH: 'signature.idTypeNotSupport',
        ID_NOT_MATCH: 'signature.idNotMatch'
    };

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFileError('');
        setError('');
        const files = event.target.files;
        const filesNumber = files?.length || 0;
        const file = files?.item(0);
        // currently only support single file upload
        if (!files || !file || filesNumber > 1) {
            // gov-form-core multiple files dragged into single file upload results in no response
            setKeystore(undefined);
            return;
        }
        const extName = Path.extname(file.name);
        if (extName !== '.p12') {
            setFileError(intl.formatMessage({ id: 'error.file.extension' }, { format: '.p12' }));
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                const dataUrl = reader.result as string;
                setKeystore(dataUrl.split(',')[1]);
            }
        };
    };

    const close = () => {
        setPassword('');
        setError('');
        setKeystore(undefined);
        setFileError('');
        if (onClose) {
            onClose();
        }
    };

    const onSignClick = async () => {
        loading(true);
        const supportConfig = {
            certificateTypes: [CertificateType.ORGANIZATIONAL]
        };
        const hash = await onSign();
        if (hash) {
            xmlSignDetachedFiles({}, hash, keystore, password, supportConfig)
                .catch((err) => {
                    if (err.message) {
                        const errorMsg = errorMsgMap[err.message];
                        if (errorMsg) {
                            setError(errorMsg);
                        }
                    }
                })
                .then((signResult) => {
                    if (signResult) {
                        if (onSuccess) {
                            onSuccess(signResult);
                            close();
                        }
                    }
                });
        }
        loading(false);
    };
    return (
        <Dialog open={open} aria-labelledby="customized-dialog-title">
            <DialogTitle id="customized-dialog-title" sx={{}}>
                <Grid container>
                    <Grid item lg={10} md={10} sm={10} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <FormattedMessage id="signature" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Close onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent sx={{}} dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                                <FormattedMessage id="signature.keystore" />
                            </Typography>
                            <TextField id="document" name="document" type="file" onChange={onFileChange} inputProps={{ accept: '.p12' }} />
                            {fileError && <FormHelperText error>{fileError}</FormHelperText>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                                <FormattedMessage id="signature.keystorePassword" />
                            </Typography>
                            <TextField
                                id="password"
                                name="password"
                                value={password}
                                type="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            {error && (
                                <FormHelperText error>
                                    <FormattedMessage id={error} defaultMessage={error} />
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="inherit" autoFocus onClick={close}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    autoFocus
                    onClick={onSignClick}
                    disabled={!keystore || !(password.trim().length > 0)}
                >
                    <FormattedMessage id="sign" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrganizationSignaturePopup;
