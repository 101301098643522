// Port from the following files in EGIS client authentication RM
// authenticator.js
import { md, pki } from 'node-forge';
import { getCertFromBase64Der, signId } from 'utils/crypto';

const CertificateType = {
    PERSONAL: 'PERSONAL',
    MINOR: 'MINOR',
    ORGANIZATIONAL: 'ORGANIZATIONAL',
    ENCIPHERMENT: 'ENCIPHERMENT',
    SERVER: 'SERVER',
    GOVERNMENT: 'GOVERNMENT'
};

const IDType: any = {
    HKID: 'HKID',
    PASSPORT: 'PASSPORT',
    BRN: 'BRN'
};

class CertAuthority {
    issuerCertDerB64: any;

    constructor(issuerCertDerB64: any) {
        this.issuerCertDerB64 = issuerCertDerB64;
    }

    static getDNSNameInSubjectAltNames = (publicCert: any) => {
        let dnsName;
        publicCert.extensions.forEach((extension: { name: string; altNames: any[] }) => {
            if (extension.name === 'subjectAltName') {
                extension.altNames.forEach((altName: { type: number; value: any }) => {
                    if (altName.type === 2) {
                        dnsName = altName.value;
                    }
                });
            }
        });

        return dnsName;
    };

    static matchId = (privateKey: any, publicCert: any, id: string) => {
        const dnsName = CertAuthority.getDNSNameInSubjectAltNames(publicCert);
        return (dnsName && dnsName === signId(privateKey, id)) || false;
    };

    isIssuerOf = (cert: { isIssuer: (arg0: pki.Certificate) => any }) =>
        this.issuerCertDerB64.reduce((result: any, certB64: string) => result || cert.isIssuer(getCertFromBase64Der(certB64)), false);

    getCertificateType: (cert: any) => string | undefined = () => undefined;

    checkId: (...args: any[]) => boolean = () => false;

    static check = (
        privateKey: pki.PrivateKey | null | undefined,
        publicCert: any,
        supportConfig: { RCAs: any[]; certificateTypes: string | any[]; id: { [x: string]: any } }
    ) => {
        const ca = supportConfig.RCAs.reduce(
            (foundCA: any, supportedCA: { isIssuerOf: (arg0: any) => any }) =>
                foundCA || (supportedCA.isIssuerOf(publicCert) && supportedCA),
            false
        );
        if (!ca) {
            throw new Error('RCA_NOT_SUPPORT');
        }

        const certType = ca.getCertificateType(publicCert);
        if (supportConfig.certificateTypes.indexOf(certType) < 0) {
            throw new Error('CERTIFICATE_TYPE_NOT_SUPPORT');
        }

        let idType: any;
        if (supportConfig.id) {
            let id;
            Object.keys(IDType).forEach((idTypeName: any) => {
                if (!idType) {
                    id = supportConfig.id[IDType[idTypeName].toLowerCase()];
                    if (id) {
                        idType = IDType[idTypeName];
                    }
                }
            });
            if (idType && !ca.checkId(privateKey, publicCert, idType, id)) {
                throw new Error('ID_NOT_MATCH');
            }
        }
    };
}

export default CertAuthority;
export { CertificateType, IDType };
