import { Backdrop, CircularProgress } from '@mui/material';

export interface LoadingProps {
    open: boolean;
}

const Loading = ({ open }: LoadingProps) => (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1 }} open={open}>
        <CircularProgress color="inherit" />
    </Backdrop>
);

export { Loading };
