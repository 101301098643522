import { Button, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import IamSmartIcon from 'assets/images/icons/iamSmart.png';
import { SxProps } from '@mui/system';

interface IamSmartButtonProps {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    sx?: SxProps;
}

const buttonTextSx = {
    fontSize: '16.2px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    textOverflow: 'ellipsis',
    fontFamily: 'Noto Sans SC, Arial, Helvetica, sans-serif'
};

const IamSmartButton = ({ label, onClick, disabled, sx }: IamSmartButtonProps) => (
    <Button
        sx={{
            backgroundColor: '#2b7367',
            border: 'none',
            borderRadius: '10px',
            color: '#FFF',
            cursor: 'pointer',
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Noto Sans SC, Arial, Helvetica, sans-serif',
            paddingLeft: '8.75px',
            paddingRight: '8.75px',
            paddingTop: '4.375px',
            paddingBottom: '4.375px',
            ':hover': {
                backgroundColor: '#2b7367'
            },
            textTransform: 'none',
            ...sx
        }}
        onClick={onClick}
        disabled={disabled}
    >
        <img src={IamSmartIcon} style={{ marginRight: '8.75px' }} />
        <Typography sx={buttonTextSx}>
            <FormattedMessage id={label} defaultMessage={label} />
        </Typography>
    </Button>
);

export default IamSmartButton;
