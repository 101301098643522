import { createContext } from 'react';

export type ContextProps = {
    formId: string;
    appType: string;
    saveAsDraft: (values: any, jump?: boolean) => void;
    getUnstructuredFormHash: (values: any) => Promise<string>;
};

const FormContext = createContext<ContextProps>({
    formId: '',
    appType: '',
    saveAsDraft: () => {},
    getUnstructuredFormHash(values: any): Promise<string> {
        throw new Error('Function not implemented.');
    }
});

export { FormContext };
