import useConfig from 'hooks/useConfig';
import { KeyedObject } from 'types';
import { SelectionTypeCode } from 'types/selectionType';
import useGlobalConfig from '../hooks/useGlobalConfig';
import _ from 'lodash';

interface SelectionValueProps extends KeyedObject {
    type: SelectionTypeCode;
    id?: number;
    value?: string;
    noEngLocale?: boolean;
}

const SelectionValue = ({ type, id, value, noEngLocale }: SelectionValueProps) => {
    const { locale: _locale } = useConfig();
    const locale = noEngLocale && _locale === 'en' ? 'zh-HK' : _locale;
    const { getOptionByTypeAndId, getOptionByTypeAndValue } = useGlobalConfig();

    const getValueByLang = (lang: string) => {
        let selectionValue;
        if (id) {
            selectionValue = getOptionByTypeAndId(type, id);
        }
        if (value) {
            selectionValue = getOptionByTypeAndValue(type, value);
        }

        if (!selectionValue) {
            return id || value;
        }
        switch (lang) {
            case 'en':
                return selectionValue?.displayValueEn;
            case 'zh-HK':
                return selectionValue?.displayValueTc;
            case 'zh-CN':
                return selectionValue?.displayValueSc;
            default:
                return selectionValue?.displayValueEn;
        }
    };

    return <>{getValueByLang(locale)}</>;
};

export { SelectionValue };
