import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    $getSelection,
    COMMAND_PRIORITY_HIGH,
    CommandPayloadType,
    isSelectionCapturedInDecoratorInput,
    LexicalCommand,
    LexicalEditor,
    PASTE_COMMAND,
    PasteCommandType
} from 'lexical';
import { useEffect } from 'react';
import { $insertDataTransferForRichText } from '@lexical/clipboard';
import { DRAG_DROP_PASTE, eventFiles } from '@lexical/rich-text';

// export function eventFiles(event: DragEvent | PasteCommandType): [boolean, Array<File>, boolean] {
//     let dataTransfer: null | DataTransfer = null;
//     if (event instanceof DragEvent) {
//         dataTransfer = event.dataTransfer;
//     } else if (event instanceof ClipboardEvent) {
//         dataTransfer = event.clipboardData;
//     }

//     if (dataTransfer === null) {
//         return [false, [], false];
//     }

//     const types = dataTransfer.types;
//     const hasFiles = types.includes('Files');
//     const hasContent = types.includes('text/html') || types.includes('text/plain');
//     return [hasFiles, Array.from(dataTransfer.files), hasContent];
// }

function onPasteForRichText(event: CommandPayloadType<typeof PASTE_COMMAND>, editor: LexicalEditor): void {
    event.preventDefault();
    editor.update(
        () => {
            const selection = $getSelection();
            const clipboardData = event instanceof InputEvent || event instanceof KeyboardEvent ? null : event.clipboardData;
            if (clipboardData != null && selection !== null) {
                // 提取 HTML 格式的剪贴板数据
                const html = clipboardData.getData('text/html');
                if (html) {
                    // 将 Word 的 HTML 格式转换为适合编辑器的格式
                    const sanitizedHtml = convertWordHtmlToEditorFormat(html);

                    if (sanitizedHtml) {
                        // 创建 DataTransfer 对象
                        const dataTransfer = new DataTransfer();
                        dataTransfer.setData('text/html', sanitizedHtml);
                        // 将 DataTransfer 对象传递给编辑器
                        $insertDataTransferForRichText(dataTransfer, selection, editor);
                        return;
                    }
                }
                // 默认处理
                $insertDataTransferForRichText(clipboardData, selection, editor);
            }
        },
        {
            tag: 'paste'
        }
    );
}

function convertWordHtmlToEditorFormat(html: string): string {
    // 使用 DOMParser 解析 HTML 字符串
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // 清理和轉換 Word 特有的 HTML 結構
    // 例如，刪除冗余的樣式和注釋，轉換特定標籤等
    cleanUpWordHtml(doc.body);

    // 返回轉換後的 HTML 字符串
    return doc.body.innerHTML;
}

function cleanUpWordHtml(element: HTMLElement): void {
    // 遞歸地處理元素及其子元素
    element.querySelectorAll('*').forEach((node) => {
        // 刪除無用的屬性
        node.removeAttribute('class');
        node.removeAttribute('style');
        node.removeAttribute('lang');

        // 轉換特定標籤或進行其他清理操作
        if (node.tagName === 'O:P') {
            node.remove();
        }
    });
}

const InitPlugin = ({ editorState }: { editorState: any }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (editorState) {
            try {
                editor.setEditorState(editor.parseEditorState(editorState));
            } catch (error) {
                try {
                    editor.update(() => {
                        const root = $getRoot();
                        root.clear();
                        const paragraphNode = $createParagraphNode();
                        const textNode = $createTextNode(editorState);
                        paragraphNode.append(textNode);
                        root.append(paragraphNode);
                    });
                } catch (err: any) {
                    console.error(err);
                }
            }
        }
    }, [editorState, editor]);

    useEffect(
        () =>
            editor.registerCommand(
                PASTE_COMMAND,
                (event) => {
                    const [, files, hasTextContent] = eventFiles(event);

                    if (files.length > 0 && !hasTextContent) {
                        editor.dispatchCommand(DRAG_DROP_PASTE, files);
                        return true;
                    }

                    // if inputs then paste within the input ignore creating a new node on paste event
                    if (isSelectionCapturedInDecoratorInput(event.target as Node)) {
                        return false;
                    }

                    const selection = $getSelection();
                    if (selection !== null) {
                        onPasteForRichText(event, editor);
                        return true;
                    }

                    return false;
                },
                COMMAND_PRIORITY_HIGH
            ),
        [editor]
    );

    return <></>;
};

export default InitPlugin;
