import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import useApiClient from 'hooks/useApiClient';
import { Box, Button, TextField, Typography, Grid, Divider, Stack } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import PlainTextPopup from 'ui-component/popup/PlainTextPopup';
import { Mail } from '@mui/icons-material';
import MainCard from 'ui-component/cards/MainCard';

const validationSchema = Yup.object().shape({
    form_questions: Yup.array().of(
        Yup.object().shape({
            question_tc: Yup.string().when('question_required', {
                is: true,
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string()
            }),
            question_en: Yup.string().when('question_required', {
                is: true,
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string()
            })
        })
    )
});

interface propState {
    formId: number;
    applicationId?: number;
    preview?: boolean;
}

interface IForm {
    form_title_en?: string;
    form_title_tc?: string;
    form_questions?: {
        id?: number;
        question_no?: number;
        question_tc?: string;
        question_en?: string;
        question_required?: boolean;
        answer?: string;
    }[];
    home_id_list?: number[];
}

const AdhocForm = () => {
    // const [loading, setLoading] = useState(true);
    const loading = useLoading();
    const { locale } = useConfig();
    const location = useLocation();
    const navigate = useNavigate();
    const { applicationService } = useApiClient();
    const [form, setForm] = useState<any | null>(null);
    const [error, setError] = useState(null);
    const [popupName, setPopupName] = useState('');
    const [answers, setAnswers] = useState<string[]>([]);
    const { formId, preview, applicationId } = (location.state as propState) || {};

    const handleSubmit = async (values: any) => {
        console.log('Submitting...');
        const submitAdhocForm = async () => {
            try {
                loading(true);
                const data: any = convertAdhocFormDataToRequest();

                const result = await applicationService.submitAdhocFormById(formId, data);
                result && setPopupName('submitted-successful');
            } catch (e) {
                console.error(e);
            } finally {
                loading(false);
            }
        };

        function convertAdhocFormDataToRequest() {
            const unstructureDatas: any[] = [];

            const obj = form;

            const updatedQuestions = obj.form_questions.map((question: any, index: number) => ({
                ...question,
                answer: values?.answers?.[index]?.trim()
            }));

            obj.form_questions = updatedQuestions;

            // console.log('obj', obj);
            const encodedValues = Buffer.from(JSON.stringify({ values: obj })).toString('base64');

            unstructureDatasBuilder(encodedValues, 'values', 'RECORD_REPLACEALL');

            function unstructureDatasBuilder(
                encodedData: string,
                data_type_key: string,
                update_type: 'APPLICATION' | 'RECORD_REPLACEALL' | 'RECORD_APPEND'
            ) {
                unstructureDatas.push({
                    data_content: encodedData,
                    data_type_key,
                    update_type
                });
            }
            console.log('Built values', obj);
            return {
                applicationType: 'ADHOC',
                unstructureDatas,
                signature_count: 0
            };
        }

        submitAdhocForm();
    };

    const handleClosePopup = () => {
        navigate('/', { replace: true });
    };

    useEffect(() => {
        const fetchAdhocApplication = async (appId: number) => {
            try {
                loading(true);
                const response: any = await applicationService.getUnstructuredApplicationById(appId);
                const { application_data } = response;
                const existingData = JSON.parse(Buffer.from(application_data[0].data_content, 'base64').toString());
                setForm(existingData.values);
            } catch (err) {
                console.error(err);
            } finally {
                loading(false);
            }
        };
        const fetchAdhocForm = async () => {
            try {
                loading(true);
                const response: any = await applicationService.getAdhocFormById(formId);
                setForm(response);
            } catch (err) {
                console.error(err);
            } finally {
                loading(false);
            }
        };
        if (applicationId) {
            fetchAdhocApplication(applicationId);
        } else {
            fetchAdhocForm();
        }
    }, []);

    const { form_title_en, form_title_tc, form_questions } = form || {};
    const title = locale === 'zh-HK' ? form_title_tc : form_title_en;
    return (
        <>
            {form && (
                <MainCard title="custom.title" titleValue={{ custom: title }}>
                    <Box p={2}>
                        {!applicationId && (
                            <Formik initialValues={{ answers }} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                {({ values, errors, touched, handleChange, handleBlur }) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {(locale === 'en' || locale === 'zh-CN') &&
                                                    form_questions.map(
                                                        (
                                                            { id, question_no, question_en, question_tc, question_required }: any,
                                                            index: number
                                                        ) => (
                                                            <Grid item sm={12} md={7} key={id} mb={2}>
                                                                <Typography variant="h4" gutterBottom>
                                                                    {question_en}
                                                                    {question_required ? ' *' : ''}
                                                                </Typography>
                                                                <Field
                                                                    sx={{ width: '100%' }}
                                                                    as={TextField}
                                                                    name={`answers[${index}]`}
                                                                    required={question_required}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.answers[index]}
                                                                    disabled={preview}
                                                                    multiline
                                                                    // error={touched.form_questions?.[question_no - 1]?.question_en && Boolean(errors.form_questions?.[question_no - 1]?.question_en)}
                                                                    // helperText={touched.form_questions?.[question_no - 1]?.question_en && errors.form_questions?.[question_no - 1]?.question_en}
                                                                />
                                                            </Grid>
                                                        )
                                                    )}
                                                {locale === 'zh-HK' &&
                                                    form_questions.map(
                                                        (
                                                            { id, question_no, question_en, question_tc, question_required }: any,
                                                            index: number
                                                        ) => (
                                                            <Grid item sm={12} md={7} key={id} mb={2}>
                                                                <Typography variant="h4" gutterBottom>
                                                                    {question_tc}
                                                                    {question_required ? ' *' : ''}
                                                                </Typography>
                                                                <Field
                                                                    sx={{ width: '100%' }}
                                                                    as={TextField}
                                                                    name={`answers[${index}]`}
                                                                    // id={`form_questions[${question_no - 1}].question_tc`}
                                                                    required={question_required}
                                                                    // onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.answers[index]}
                                                                    disabled={preview}
                                                                    multiline
                                                                    // error={touched.form_questions?.[question_no - 1]?.question_en && Boolean(errors.form_questions?.[question_no - 1]?.question_en)}
                                                                    // helperText={touched.form_questions?.[question_no - 1]?.question_en && errors.form_questions?.[question_no - 1]?.question_en}
                                                                />
                                                            </Grid>
                                                        )
                                                    )}
                                            </Grid>
                                            {!preview && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                                                        <Stack spacing={1} direction="row">
                                                            <Button variant="contained" type="submit" color="primary">
                                                                <FormattedMessage id="ad-hoc-form.creation.submit" />
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="inherit"
                                                                onClick={() => {
                                                                    navigate('/user/home-task-submission', {});
                                                                }}
                                                            >
                                                                <FormattedMessage id="ad-hoc-form.creation.cancel" />
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        )}
                        {applicationId && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {form_questions.map(
                                        ({ id, question_no, question_en, question_tc, question_required, answer }: any, index: number) => (
                                            <Grid item sm={12} md={7} key={id} mb={2} sx={{ mb: 3 }}>
                                                <Typography variant="h4" gutterBottom>
                                                    {locale === 'zh-HK' ? question_tc : question_en}
                                                </Typography>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    name={`form_questions[${index}].answer`}
                                                    required={question_required}
                                                    value={answer}
                                                    disabled
                                                    multiline
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <PlainTextPopup
                            open={popupName === 'submitted-successful'}
                            title=""
                            onClose={handleClosePopup}
                            onAction={handleClosePopup}
                            actionBtn="close"
                            contentSx={{ width: 600, textAlign: 'center' }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Mail fontSize="large" color="primary" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3" color="#12A1FD">
                                        <FormattedMessage
                                            id="application.submitted.message2"
                                            values={{
                                                action: (
                                                    <FormattedMessage id={popupName === 'submitted-successful' ? 'submitted' : 'saved'} />
                                                )
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PlainTextPopup>
                    </Box>
                </MainCard>
            )}
        </>
    );
};

export default AdhocForm;
