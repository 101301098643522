import { Grid, IconButton } from '@mui/material';
import { ToolBarProvider } from './ToolBarContext';

type ToolbarProps = {
    disabled?: boolean;
    setIsLinkEditMode: (value: any) => void;
};

const Toolbar = ({ disabled, setIsLinkEditMode }: ToolbarProps) => (
    <Grid container sx={{ backgroundColor: '#fff', width: '100%', padding: '10px 0px' }}>
        <ToolBarProvider disabled={disabled} setIsLinkEditMode={setIsLinkEditMode}>
            {({ pluginsList, FontSizeComp, FontColorComp, FontBgColorComp, ImageComp, TableComp }: any) => (
                <>
                    {pluginsList.map((plugin: any) => (
                        <IconButton
                            disabled={disabled || plugin.disabled}
                            key={plugin.id}
                            onClick={() => {
                                plugin.onClick && plugin.onClick();
                            }}
                        >
                            {plugin.Icon()}
                            {/* < /> */}
                        </IconButton>
                    ))}
                    <FontSizeComp />
                    {FontColorComp}
                    {FontBgColorComp}
                    {ImageComp}
                    {TableComp}
                </>
            )}
        </ToolBarProvider>
    </Grid>
);

export default Toolbar;
