const acceptedNumberPattern = /^[-.0-9]*$/;
const numberPattern = /^[-]{0,1}[0-9]+(\.[0-9]+){0,1}$/;

const number = (value: any) => {
    let result;
    if (typeof value === 'string') {
        console.info(`number checking string`);
        if (numberPattern.test(value.trim())) {
            console.info(`number string matched number pattern`);
            result = Number(value);
        }
    } else if (typeof value === 'number') {
        result = value;
    }

    return result;
};

export { number, acceptedNumberPattern };
