import { useSearchParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

// project imports
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { GuardProps } from 'types';
import { IamsmartContext } from 'contexts/IamsmartContext';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const p = parts.pop();
        if (p) {
            return p.split(';').shift();
        }
    }
    return null;
};
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, login } = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { onChangeLocale } = useConfig();
    const { openLinkupPopup } = useContext(IamsmartContext);

    useEffect(() => {
        const lang = searchParams.get('lang');
        if (lang) {
            switch (lang) {
                case 'zh_HK':
                    onChangeLocale('zh-HK');
                    break;
                case 'zh_CN':
                    onChangeLocale('zh-CN');
                    break;
                case 'en':
                    onChangeLocale('en');
            }
        }
        const error = searchParams.get('error');
        if (error && !(error === 'D40000' && window.opener === null)) {
            navigate(`/error?error=${error}`);
            return;
        }
        if (!isLoggedIn) {
            const c = searchParams.get('c');
            if (c) {
                navigate(`/login?c=${c}`);
            } else {
                login();
            }
        } else {
            const eraseCookie = (name: string) => {
                document.cookie = `${name}=; Max-Age=-99999999;`;
            };

            const cookie = getCookie('isLinkup');
            console.log(cookie);
            if (cookie && isLoggedIn) {
                openLinkupPopup(1);
            }
            if (isLoggedIn) {
                eraseCookie('isLinkup');
            }
        }
    }, [isLoggedIn, login, searchParams, navigate]);

    if (isLoggedIn) {
        return children;
    }

    return null;
};

export default AuthGuard;
