import React, { useContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Container, CssBaseline, Grid, Stack, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';

import Footer from './Footer';
// assets
import useAuth from '../../hooks/useAuth';
import { GlobalVariableContext } from '../../contexts/GlobalVariableContext';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px + 220px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px + 240px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 260,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px + 220px)`
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px + 240px)`
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const GreyWrapper = ({ grey = false, children }: any) => {
    if (grey) {
        return <GreyFilter>{children}</GreyFilter>;
    }
    return <>{children}</>;
};

const GreyFilter = styled('div')({ filter: 'grayscale(100%)' });

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { globalVariable } = useContext(GlobalVariableContext);
    const { container } = useConfig();
    const { isLoggedIn } = useAuth();

    React.useEffect(() => {
        if (isLoggedIn) {
            dispatch(openDrawer(!matchDownMd));
        } else {
            dispatch(openDrawer(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    const greymode = !!window.config.GREY_LANDING_PAGE && window.location.pathname === '/login';

    return (
        <GreyWrapper grey={greymode}>
            <Grid container>
                <Grid item xs={12}>
                    <CssBaseline />
                    {/* header */}
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            backgroundColor: greymode ? 'rgb(211,211,211)' : `rgb(${globalVariable.homeThemeVariable?.headerColor})`,
                            transition: drawerOpen ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        {header}
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    <Stack sx={{ justifyContent: 'space-between' }}>
                        {isLoggedIn && <Sidebar />}
                        {/* main content */}
                        <Main theme={theme} open={drawerOpen}>
                            {/* breadcrumb */}
                            {container && (
                                <Container maxWidth="lg">
                                    <Outlet />
                                </Container>
                            )}
                            {!container && (
                                <>
                                    <Outlet />
                                </>
                            )}
                        </Main>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </GreyWrapper>
    );
};

export default MainLayout;
