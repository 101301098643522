import { Grid, Button, TextField } from '@mui/material';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldRenderer } from './FieldRenderer';
import useConfig from 'hooks/useConfig';
import { Condition, NumberCondition } from 'types/form';
import useDialog from 'hooks/useDialog';
import { FormContext } from './FormContext';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';

interface RenderQuestionProps {
    name: string;
    question: Question;
    disabled: boolean;
    appType?: string;
}

export interface Question {
    name: string;
    type: string;
    questions?: Question[]; // question list like can +/- multiple addr list
    addedQuestion?: Question[]; // nested question trigger by an option
    required?: boolean | Condition;
    condition?: ({ values, index }: { values: any; index?: number }) => boolean;
    checkLocale?: (locale: string) => boolean;
    conditionWithLocale?: ({ locale, values, index }: { locale: string; values: any; index?: number }) => boolean;
    for?: string;
    max?: number | NumberCondition;
    inlineWidth?: number;
    dependsField?: string;
    check?: string;
    disabled: boolean | Condition;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
}

const RenderQuestion = ({ name, question, disabled = false, appType }: RenderQuestionProps) => {
    // console.log('RenderQuestion');
    const questionName = `${name}.${question.name}`;
    const { setFieldValue, values, isValid, validateForm } = useFormikContext();
    const [shown, setShown] = useState(true);
    const { locale } = useConfig();
    const [update, setUpdate] = useState(0);
    const { setDialog } = useDialog();
    const { saveAsDraft } = useContext(FormContext);
    // console.info(`RenderQuestion name:${name} metadata:${JSON.stringify(metadata)}`);
    const confirmDialog = useConfirmDialog();

    const isQuestionDisabled = useMemo(() => {
        if (typeof question.disabled === 'function') {
            return question.disabled({ values });
        }
        return !!question.disabled;
    }, [question, values]);
    const questionMax = useMemo(() => {
        if (typeof question.max === 'function') {
            return question.max({ values });
        }
        return question.max;
    }, [question, values]);
    const newMetaData = {
        ...question,
        required: typeof question.required === 'function' ? question.required({ values }) : question.required || false
    };
    // console.log(newMetaData);
    // console.log(typeof question.max === 'function' && question.max({ values }));
    // console.log(questionMax && questionMax);
    useEffect(() => {}, [values]);
    useEffect(() => {
        if (question.condition) {
            // console.info(`calculate condition for ${questionName}`);
            const condition = question.condition({ values });
            // console.info(`result:${condition}`);
            setShown(condition);

            if (!condition) {
                // setFieldValue(questionName, undefined, false);
            }
        }
    }, [question, questionName, setFieldValue, values]);

    useEffect(() => {
        if (question.checkLocale) {
            // console.info(`calculate condition for ${questionName}`);
            const checkLocale = question.checkLocale(locale);
            // console.info(`result:${checkLocale}`);
            setShown(checkLocale);
        }
    }, [question, questionName, setFieldValue, locale]);

    useEffect(() => {
        if (question.conditionWithLocale) {
            // console.info(`calculate condition for ${questionName}`);
            const conditionWithLocale = question.conditionWithLocale({ locale, values });
            console.info(`result:${conditionWithLocale}`);
            setShown(conditionWithLocale);
        }
    }, [question, questionName, setFieldValue, locale]);

    // useEffect(() => {
    //     if (question.condition || question.checkLocale) {
    //         const condition = !(question.condition?.({ values }) === false || question.checkLocale?.(locale) === false);
    //         console.log(condition);
    //         console.info(`result:${condition}`);
    //         setShown(condition);
    //     }
    // }, [locale, question, questionName, setFieldValue, values]);

    useEffect(() => {
        if (question.type === 'list') {
            if (shown) {
                validateForm();
                const fieldArrayValue = getIn(values, questionName);
                console.info(`fieldArrayValue name:${questionName} values:${JSON.stringify(fieldArrayValue)}`);
                if (question.required && (!fieldArrayValue || fieldArrayValue.length < 1)) {
                    console.info('add default element for field array');
                    setFieldValue(`${questionName}.0`, {}, false);
                }
            }
        }
    }, [question.type, question.required, questionName, setFieldValue, values, shown]);

    if (!shown) {
        return null;
    }

    if (question.type === 'list') {
        const fieldArrayValue: any[] = getIn(values, questionName);
        // console.log('fieldArrayValue', fieldArrayValue);
        return (
            <FieldArray name={questionName}>
                {({ pop, push, remove }) => (
                    <Grid item container spacing={1} xs={12} sm={12}>
                        {/* {fieldArrayValue?.length == 0 && (() => push({}))} */}
                        {fieldArrayValue?.length >= 0 &&
                            fieldArrayValue.map((field, index) => (
                                <Grid
                                    key={index}
                                    sx={{
                                        borderWidth: 1,
                                        borderColor: '#ddd',
                                        borderStyle: 'solid',
                                        marginBottom: 1,
                                        paddingBottom: 2,
                                        marginTop: 1.5
                                    }}
                                    item
                                    container
                                    xs={12}
                                    sm={12}
                                >
                                    <div style={{ width: '100%' }}>{`#${index + 1}`}</div>
                                    {question.questions?.map((q: any, i) => {
                                        const listQuestionName = `${questionName}.${index}.${q.name}`;
                                        const listQuestionDependsFieldName =
                                            q.check === 'checkDateAfterSpecificDate' && q.dependsField
                                                ? `${questionName}.${index}.${q.dependsField}`
                                                : undefined;

                                        const newMetadata = {
                                            ...q,
                                            required: typeof q.required === 'function' ? q.required({ values, index }) : q.required || false
                                        };
                                        return (
                                            // <Grid item xs={12} sm={12}>
                                            <Grid
                                                key={`${i}_${index}`}
                                                item
                                                container
                                                pl={3}
                                                pt={3}
                                                xs={q.inlineWidth || 12}
                                                sm={q.inlineWidth || 12}
                                            >
                                                {(!q.condition || q.condition?.({ values, index })) && (
                                                    <FieldRenderer
                                                        index={index}
                                                        name={listQuestionName}
                                                        parentName={name}
                                                        metadata={newMetadata}
                                                        disabled={disabled}
                                                        dependsFieldName={listQuestionDependsFieldName}
                                                        variant={q.variant}
                                                    />
                                                )}
                                            </Grid>
                                        );
                                    })}
                                    {!disabled && (!question.required || fieldArrayValue?.length > 1) && (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                setDialog({
                                                    title: 'deletion',
                                                    onConfirm: () => {
                                                        remove(index);
                                                        setUpdate(update + 1);
                                                    }
                                                });
                                            }}
                                            sx={{ my: 3, ml: 1 }}
                                        >
                                            <FormattedMessage id="delete" />
                                        </Button>
                                    )}
                                </Grid>
                            ))}
                        {/* {fieldArrayValue?.length > 1 && !disabled && (
                            <Button variant="contained" color="error" onClick={() => pop()} sx={{ my: 3, ml: 1 }}>
                                <FormattedMessage id="delete" />
                            </Button>
                        )} */}
                        {(!fieldArrayValue || (questionMax && fieldArrayValue.length < questionMax)) && !disabled && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    appType === 'SER' && saveAsDraft(values, false);
                                    if (isValid) {
                                        push({});
                                        // await saveUnstructureApplicationToRequest(values);
                                    } else {
                                        confirmDialog?.setDialog({
                                            title: 'error',
                                            titleSx: { color: 'red' },
                                            content: 'error.dataValidation',
                                            cancelBtn: 'ok'
                                        });
                                    }
                                }}
                                sx={{ my: 3, ml: 1 }}
                            >
                                <FormattedMessage id={appType === 'TE' ? 'add-new' : 'add'} />
                            </Button>
                        )}
                    </Grid>
                )}
            </FieldArray>
        );
    }

    const width = question.inlineWidth || 12;

    return (
        <Grid item sm={width}>
            <FieldRenderer
                name={questionName}
                parentName={name}
                metadata={newMetaData}
                disabled={disabled || isQuestionDisabled}
                dependsFieldName={question.dependsField}
                variant={question.variant}
            />
        </Grid>
    );
};

export { RenderQuestion };
