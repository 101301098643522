import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, Typography } from '@mui/material';
import { memo, ChangeEvent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import useConfig from 'hooks/useConfig';
import { ErrorDisplay } from './util';
import { RenderQuestion, Question } from '../RenderQuestion';
import { GlobalVariableContext } from '../../../../contexts/GlobalVariableContext';
import { SelectionValue } from '../../../SelectionValue';
import { SelectionTypeCode } from '../../../../types/selectionType';
import { ISelectionValue } from 'types/global-variable/selection-value';

interface RadioProps {
    name: string;
    value: any;
    metadata: {
        labelKey: string;
        options: RadioOptionType[];
        required?: boolean;
        noEngLocale?: boolean;
        masterConfigKey?: SelectionTypeCode;
        addedQuestion?: Question;
        messageKey?: string;
        onValueChange?: (value: any, helper: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void }) => void;
    };
    onChange: (v: any) => void;
    parentName: string;
    disabled: boolean;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
}

interface RadioOptionType {
    value: string;
    labelKey: {
        [key: string]: string;
    };
    addedQuestion?: Question;
    disabled?: boolean;
}

const RadioField = memo((props: RadioProps) => {
    const { name, value, metadata, onChange, parentName, disabled, variant } = props;
    const { getOptionsByType } = useContext(GlobalVariableContext);
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const { locale } = useConfig();
    let availableOptions: ISelectionValue[] | undefined;
    if (metadata.masterConfigKey) {
        availableOptions = getOptionsByType(metadata.masterConfigKey);
    }
    const isSingleOption = availableOptions?.length === 1;

    const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        onChange(newValue);
        metadata.onValueChange?.(newValue, { setFieldValue });
    };

    return (
        <FormControl fullWidth>
            <FormLabel id={name}>
                <Typography variant={variant}>
                    <FormattedMessage id={metadata.labelKey} />
                    {metadata.required && (
                        <>
                            <span> </span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                    )}
                </Typography>
                {metadata.messageKey && (
                    <Typography variant={variant} gutterBottom sx={{ mb: 2 }}>
                        <FormattedMessage id={metadata.messageKey} />
                    </Typography>
                )}
            </FormLabel>
            <RadioGroup name={name} onChange={onValueChange} value={value}>
                {metadata.masterConfigKey &&
                    getOptionsByType(metadata.masterConfigKey)?.map((opt) => {
                        if (metadata.masterConfigKey) {
                            if (metadata.addedQuestion && metadata.addedQuestion?.for === opt.dataValue) {
                                return (
                                    <>
                                        <FormControlLabel
                                            value={opt.dataValue}
                                            key={opt.dataValue}
                                            control={<Radio disabled={disabled} />}
                                            label={
                                                <SelectionValue
                                                    type={metadata.masterConfigKey}
                                                    value={opt.dataValue}
                                                    noEngLocale={metadata.noEngLocale}
                                                />
                                            }
                                            sx={{ width: 'fit-content' }}
                                        />
                                        <RenderQuestion name={parentName} question={metadata.addedQuestion} disabled={disabled} />
                                    </>
                                );
                            }
                            return (
                                <FormControlLabel
                                    value={opt.dataValue}
                                    key={opt.dataValue}
                                    control={<Radio disabled={disabled} />}
                                    label={
                                        <SelectionValue
                                            type={metadata.masterConfigKey}
                                            value={opt.dataValue}
                                            noEngLocale={metadata.noEngLocale}
                                        />
                                    }
                                    sx={{ width: 'fit-content' }}
                                />
                            );
                        }
                        return <></>;
                    })}

                {!metadata.masterConfigKey &&
                    metadata.options?.map((opt: RadioOptionType) => {
                        if (opt.addedQuestion) {
                            return (
                                <>
                                    <FormControlLabel
                                        value={opt.value}
                                        key={opt.value}
                                        control={<Radio disabled={disabled} />}
                                        label={opt.labelKey[locale]}
                                        disabled={opt.disabled}
                                        sx={{ width: 'fit-content' }}
                                    />
                                    <RenderQuestion name={parentName} question={opt.addedQuestion} disabled={disabled} />
                                </>
                            );
                        }
                        return (
                            <FormControlLabel
                                value={opt.value}
                                key={opt.value}
                                control={<Radio disabled={disabled} />}
                                label={opt.labelKey[locale]}
                                disabled={opt.disabled}
                                sx={{ width: 'fit-content' }}
                            />
                        );
                    })}
                {!isSingleOption && Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
            </RadioGroup>
        </FormControl>
    );
});

export { RadioField };
