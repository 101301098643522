import React, { useState, createContext, FC, Dispatch, SetStateAction } from 'react';
import RenderQuestionDeleteDialog from 'ui-component/forms-wizard/ValidationWizard/RenderQuestionDeleteDialog';

export interface DialogContextProps {
    setDialog: Dispatch<SetStateAction<DialogProps | undefined>>;
}

export interface DialogProps {
    title: string;
    onClose?: () => void;
    onConfirm?: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

const DialogProvider: FC = ({ children }) => {
    const [dialog, setDialog] = useState<DialogProps>();

    const onClose = async () => {
        setDialog(undefined);
        if (dialog?.onClose) {
            await dialog.onClose();
        }
    };

    const onConfirm = async () => {
        setDialog(undefined);
        if (dialog?.onConfirm) {
            await dialog.onConfirm();
        }
    };

    return (
        <DialogContext.Provider value={{ setDialog }}>
            {children}
            {dialog && <RenderQuestionDeleteDialog title={dialog.title} open onClose={onClose} onConfirm={onConfirm} />}
        </DialogContext.Provider>
    );
};

export { DialogProvider, DialogContext };
