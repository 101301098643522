import { ISelection } from './selection';
import { ISigner } from './signer';
import { IUserRole } from './user-role';
import { IHomeThemeVariable } from './home-theme-variable';
import { IApplicationType } from './application-type';

export interface IGlobalVariable {
    selections?: ISelection[];
    signers?: ISigner[];
    userRolesWithoutAdmin?: IUserRole[];
    userRolesWithAdmin?: IUserRole[];
    homeThemeVariable?: IHomeThemeVariable;
    applicationTypes?: IApplicationType[];
}

export class GlobalVariable implements IGlobalVariable {
    constructor(
        public selections?: ISelection[],
        public signers?: ISigner[],
        public userRolesWithoutAdmin?: IUserRole[],
        public userRolesWithAdmin?: IUserRole[],
        public homeThemeVariable?: IHomeThemeVariable,
        public applicationTypes?: IApplicationType[]
    ) {
        this.selections = selections;
        this.signers = signers;
        this.userRolesWithoutAdmin = userRolesWithoutAdmin;
        this.userRolesWithAdmin = userRolesWithAdmin;
        this.homeThemeVariable = homeThemeVariable;
        this.applicationTypes = applicationTypes;
    }
}
