import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Stack, Typography } from '@mui/material';

interface LabelProps {
    metadata: {
        variant:
            | 'body1'
            | 'body2'
            | 'button'
            | 'caption'
            | 'h1'
            | 'h2'
            | 'h3'
            | 'h4'
            | 'h5'
            | 'h6'
            | 'inherit'
            | 'overline'
            | 'subtitle1'
            | 'subtitle2';
        labelKey: string;
        link?: {
            labelKey: string;
            url: string;
        };
        img?: string;
        color?: string;
        sx?: any;
    };
}

const LabelField = memo((props: LabelProps) => {
    const { metadata } = props;
    const intl = useIntl();

    return (
        <>
            {metadata.labelKey && (
                <Typography variant={metadata.variant} color={metadata.color} sx={metadata.sx}>
                    <FormattedMessage id={metadata.labelKey} />
                </Typography>
            )}

            <Stack>
                {metadata.link && (
                    <Link href={intl.formatMessage({ id: metadata.link.url })} target="new">
                        <FormattedMessage id={metadata.link.labelKey} />
                    </Link>
                )}
                {metadata.img && <img src={metadata.img} style={{ width: 'fit-content' }} />}
            </Stack>
        </>
    );
});

export { LabelField };
