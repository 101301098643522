import { useState, useEffect, ChangeEvent } from 'react';
import { Box, FormControl, FormLabel, OutlinedInput, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';

interface HkidFieldProps {
    name: string;
    value: string;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
}

const HkidField = (props: HkidFieldProps) => {
    const { name, value, metadata, onChange, disabled } = props;
    const [id, setId] = useState('');
    const [checkDigit, setCheckDigit] = useState('');

    const { getFieldMeta, setFieldTouched } = useFormikContext();

    useEffect(() => {
        if (value) {
            const openParIndex = value.indexOf('(');
            const closeParIndex = value.indexOf(')');

            if (openParIndex > 0 && closeParIndex > 0 && closeParIndex > openParIndex + 1) {
                const tempId = value.substring(0, openParIndex);
                const tempCheckDigit = value.substring(openParIndex + 1, closeParIndex);
                setId(tempId.toUpperCase());
                setCheckDigit(tempCheckDigit.toUpperCase());
            }
        } else {
            setId('');
            setCheckDigit('');
            setFieldTouched(name, false);
        }
    }, [value]);

    const onIdChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value.toUpperCase();
        setId(newValue);
        onChange(`${newValue}(${checkDigit})`);
    };

    const onCheckDigitChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value.toUpperCase();
        setCheckDigit(newValue);
        onChange(`${id}(${newValue})`);
    };

    return (
        <>
            <FormLabel id={name}>
                <FormattedMessage id={metadata.labelKey} />
                {metadata.required && (
                    <>
                        <span> </span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </FormLabel>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <FormControl>
                        <OutlinedInput
                            id={name}
                            name={name}
                            value={id}
                            onChange={onIdChange}
                            inputProps={{ maxLength: 8 }}
                            disabled={disabled}
                            fullWidth
                        />
                    </FormControl>
                </Grid>
                <div>&nbsp;(&nbsp;</div>
                <Grid item xs={1}>
                    <FormControl>
                        <OutlinedInput
                            id={`${name}-checkDigit`}
                            name={`${name}-checkDigit`}
                            value={checkDigit}
                            onChange={onCheckDigitChange}
                            inputProps={{ maxLength: 1 }}
                            disabled={disabled}
                            fullWidth
                        />
                    </FormControl>
                </Grid>
                <div>&nbsp;)</div>
            </Grid>
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </>
    );
};

export { HkidField };
