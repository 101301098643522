import flatten from 'flat';

export type District =
    | 'Central And Western District'
    | 'Eastern District'
    | 'Southern District'
    | 'Wan Chai District'
    | 'Kowloon City District'
    | 'Kwun Tong District'
    | 'Sham Shui Po District'
    | 'Wong Tai Sin District'
    | 'Yau Tsim Mong District'
    | 'Islands District'
    | 'Kwai Tsing District'
    | 'North District'
    | 'Sai Kung District'
    | 'Sha Tin District'
    | 'Tai Po District'
    | 'Tsuen Wan District'
    | 'Tuen Mun District'
    | 'Yuen Long District';

export type Region = 'HK' | 'KLN' | 'NT';

export class ADI {
    region: Region | '' = '';

    district: District | '' = '';

    subDistrict: string = '';

    streetNo: string = '';

    streetName: string = '';

    phase: string = '';

    estateName: string = '';

    blockType: string = '';

    blockTypeOther: string = '';

    blockNo: string = '';

    buildingName: string = '';

    floor: string = '';

    unitType: string = '';

    unitTypeOther: string = '';

    unitNo: string = '';

    lotNo: string = '';

    fullAddress: string = '';
}

type Locale = 'zh-HK' | 'zh-CN' | 'en';

export const toEnFullAddress = (adi: ADI, translation: any) => {
    let address = '';
    if (adi.unitType) {
        if (adi.unitType !== 'Other') {
            const unitType = getUnitType(translation).find((t) => adi.unitType === t.value)?.label;
            address += unitType || '';
        } else {
            address += adi.unitTypeOther;
        }
        address += ` ${adi.unitNo}, `;
    }
    if (adi.floor) {
        address += `${adi.floor}/F, `;
    }

    if (adi.blockType) {
        if (adi.blockType !== 'Other') {
            const blockType = getBlockType(translation).find((t) => adi.blockType === t.value)?.label;
            address += blockType || '';
        } else {
            address += adi.blockTypeOther;
        }
        address += ` ${adi.blockNo}, `;
    }

    if (adi.buildingName) {
        address += `${adi.buildingName}, `;
    }

    if (adi.estateName) {
        address += `${adi.estateName}`;
        if (adi.phase) {
            address += `(Phase ${adi.phase})`;
        }
        address += ', ';
    }

    if (adi.streetName) {
        address += `${adi.streetNo} ${adi.streetName}, `;
    }

    if (adi.lotNo) {
        address += `${adi.lotNo}, `;
    }

    if (adi.district && adi.subDistrict) {
        const subDistrict = getSubDistrictList(adi.district, translation).find((r) => r.value === adi.subDistrict)?.label;
        address += subDistrict || '';
        address += ', ';
    }

    const region = getRegionList(translation).find((r) => r.value === adi.region)?.label;
    address += region || '';
    return address;
};

export const toChiFullAddress = (adi: ADI, translation: any) => {
    let address = '';
    const region = getRegionList(translation).find((r) => r.value === adi.region)?.label;
    address += region || '';
    if (adi.district && adi.subDistrict) {
        const subDistrict = getSubDistrictList(adi.district, translation).find((r) => r.value === adi.subDistrict)?.label;
        address += subDistrict || '';
    }
    if (adi.lotNo) {
        address += `${adi.lotNo}`;
    }
    if (adi.streetName) {
        address += `${adi.streetName}${adi.streetNo}號`;
    }
    if (adi.estateName) {
        address += `${adi.estateName}`;
        if (adi.phase) {
            address += `${adi.phase}期`;
        }
    }
    if (adi.buildingName) {
        address += `${adi.buildingName}`;
    }
    if (adi.blockType) {
        if (adi.blockType !== 'Other') {
            const blockType = getBlockType(translation).find((t) => adi.blockType === t.value)?.label;
            address += blockType || '';
        } else {
            address += adi.blockTypeOther;
        }
        address += `${adi.blockNo} `;
    }
    if (adi.floor) {
        address += `${adi.floor}樓`;
    }
    if (adi.unitType) {
        address += adi.unitNo;
        if (adi.unitType !== 'Other') {
            const unitType = getUnitType(translation).find((t) => adi.unitType === t.value)?.label;
            address += unitType || '';
        } else {
            address += adi.unitTypeOther;
        }
    }

    return address;
};

export const toServerObject = (adi: ADI) => {
    const obj = {
        floor: adi.floor,
        region: adi.region,
        block_descriptor: adi.blockType === 'Other' ? adi.blockTypeOther : adi.blockType,
        block_number: adi.blockNo,
        building_name: adi.buildingName,
        estate_name: adi.estateName,
        lot_information: adi.lotNo,
        street_name: adi.streetName,
        sub_district: adi.subDistrict,
        unit_descriptor: adi.unitType === 'Other' ? adi.unitTypeOther : adi.unitType,
        unit_number: adi.unitNo,
        district: adi.district,
        building_number: adi.streetNo,
        phase: adi.phase
    };
    return obj;
};

export const toFormObject = (obj: any, fullAddress: string) => {
    const adi = new ADI();
    adi.fullAddress = fullAddress;
    adi.region = obj.region ? obj.region : '';
    adi.district = obj.district ? obj.district : '';
    adi.subDistrict = obj.sub_district || '';
    adi.streetNo = obj.building_number || '';
    adi.streetName = obj.street_name || '';
    adi.phase = obj.phase || '';
    adi.estateName = obj.estate_name || '';
    if (obj.block_descriptor) {
        if (['Block', 'Tower', 'House'].indexOf(obj.block_descriptor) === -1) {
            adi.blockType = 'Other';
            adi.blockTypeOther = obj.block_descriptor;
        } else {
            adi.blockType = obj.block_descriptor;
        }
    } else {
        adi.blockType = '';
    }
    adi.blockNo = obj.block_number || '';
    adi.buildingName = obj.building_name || '';
    adi.floor = obj.floor || '';

    if (obj.unit_descriptor) {
        if (['Flat', 'Shop', 'Suite', 'Room'].indexOf(obj.unit_descriptor) === -1) {
            adi.unitType = 'Other';
            adi.unitTypeOther = obj.unit_descriptor;
        } else {
            adi.unitType = obj.unit_descriptor;
        }
    } else {
        adi.unitType = '';
    }

    adi.unitNo = obj.unit_number || '';
    adi.lotNo = obj.lot_information || '';
    return adi;
};

export const getRegionList = (translation: any) => {
    const prefix = 'regions';
    const options = [
        { value: 'HK', label: 'Hong Kong' },
        { value: 'KLN', label: 'Kowloon' },
        { value: 'NT', label: 'New Territories' }
    ];
    return options.map((data) => {
        data.label = translation[`${prefix}.${data.label}`];
        return data;
    });
};

export const getDistrictList = (region: Region | '', translation: any) => {
    if (region === '') {
        return [];
    }

    const prefix = 'districts';
    const options = {
        HK: [
            { value: 'Central And Western District', label: 'Central And Western District' },
            { value: 'Eastern District', label: 'Eastern District' },
            { value: 'Southern District', label: 'Southern District' },
            { value: 'Wan Chai District', label: 'Wan Chai District' }
        ],
        KLN: [
            { value: 'Kowloon City District', label: 'Kowloon City District' },
            { value: 'Kwun Tong District', label: 'Kwun Tong District' },
            { value: 'Sham Shui Po District', label: 'Sham Shui Po District' },
            { value: 'Wong Tai Sin District', label: 'Wong Tai Sin District' },
            { value: 'Yau Tsim Mong District', label: 'Yau Tsim Mong District' }
        ],
        NT: [
            { value: 'Islands District', label: 'Islands District' },
            { value: 'Kwai Tsing District', label: 'Kwai Tsing District' },
            { value: 'North District', label: 'North District' },
            { value: 'Sai Kung District', label: 'Sai Kung District' },
            { value: 'Sha Tin District', label: 'Sha Tin District' },
            { value: 'Tai Po District', label: 'Tai Po District' },
            { value: 'Tsuen Wan District', label: 'Tsuen Wan District' },
            { value: 'Tuen Mun District', label: 'Tuen Mun District' },
            { value: 'Yuen Long District', label: 'Yuen Long District' }
        ]
    };
    return options[region].map((data) => {
        data.label = translation[`${prefix}.${data.label}`];
        return data;
    });
};

export const getSubDistrictList = (district: District | '', translation: any) => {
    if (district === '') {
        return [];
    }

    const prefix = 'districts';
    const options = {
        'Central And Western District': [
            { value: 'Admiralty', label: 'Admiralty' },
            { value: 'Central', label: 'Central' },
            { value: 'Kennedy Town', label: 'Kennedy Town' },
            { value: 'Mid-Level', label: 'Mid-Level' },
            { value: 'Sai Ying Pun', label: 'Sai Ying Pun' },
            { value: 'Shek Tong Tsui', label: 'Shek Tong Tsui' },
            { value: 'Sheung Wan', label: 'Sheung Wan' },
            { value: 'The Peak', label: 'The Peak' }
        ],
        'Eastern District': [
            { value: 'Chai Wan', label: 'Chai Wan' },
            { value: 'North Point', label: 'North Point' },
            { value: 'Quarry Bay', label: 'Quarry Bay' },
            { value: 'Sai Wan Ho', label: 'Sai Wan Ho' },
            { value: 'Shau Kei Wan', label: 'Shau Kei Wan' }
        ],
        'Southern District': [
            { value: 'Aberdeen', label: 'Aberdeen' },
            { value: 'Ap Lei Chau', label: 'Ap Lei Chau' },
            { value: 'Chung Hom Kok', label: 'Chung Hom Kok' },
            { value: 'Deep Water Bay', label: 'Deep Water Bay' },
            { value: 'Pok Fu Lam', label: 'Pok Fu Lam' },
            { value: 'Repulse Bay', label: 'Repulse Bay' },
            { value: 'Shek O', label: 'Shek O' },
            { value: 'Stanley', label: 'Stanley' },
            { value: 'Tai Tam', label: 'Tai Tam' },
            { value: 'Wong Chuk Hang', label: 'Wong Chuk Hang' }
        ],
        'Wan Chai District': [
            { value: 'Causeway Bay', label: 'Causeway Bay' },
            { value: 'Happy Valley', label: 'Happy Valley' },
            { value: 'Tai Hang', label: 'Tai Hang' },
            { value: 'Wan Chai', label: 'Wan Chai' }
        ],
        'Kowloon City District': [
            { value: 'Ho Man Tin', label: 'Ho Man Tin' },
            { value: 'Hung Hom', label: 'Hung Hom' },
            { value: 'Kowloon City', label: 'Kowloon City' },
            { value: 'Kowloon Tong', label: 'Kowloon Tong' },
            { value: 'Ma Tau Wai', label: 'Ma Tau Wai' },
            { value: 'To Kwa Wan', label: 'To Kwa Wan' }
        ],
        'Kwun Tong District': [
            { value: 'Cha Kwo Ling', label: 'Cha Kwo Ling' },
            { value: 'Kowloon Bay', label: 'Kowloon Bay' },
            { value: 'Kwun Tong', label: 'Kwun Tong' },
            { value: 'Lam Tin', label: 'Lam Tin' },
            { value: 'Ngau Tau Kok', label: 'Ngau Tau Kok' },
            { value: 'Sau Mau Ping', label: 'Sau Mau Ping' },
            { value: 'Yau Tong', label: 'Yau Tong' }
        ],
        'Sham Shui Po District': [
            { value: 'Cheung Sha Wan', label: 'Cheung Sha Wan' },
            { value: 'Lai Chi Kok', label: 'Lai Chi Kok' },
            { value: 'Sham Shui Po', label: 'Sham Shui Po' },
            { value: 'Shek Kip Mei', label: 'Shek Kip Mei' },
            { value: 'Tai Kok Tsui', label: 'Tai Kok Tsui' },
            { value: 'Yau Yat Chuen', label: 'Yau Yat Chuen' }
        ],
        'Wong Tai Sin District': [
            { value: 'Choi Hung', label: 'Choi Hung' },
            { value: 'Diamond Hill', label: 'Diamond Hill' },
            { value: 'Lok Fu', label: 'Lok Fu' },
            { value: 'Ngau Chi Wan', label: 'Ngau Chi Wan' },
            { value: 'San Po Kong', label: 'San Po Kong' },
            { value: 'Tsz Wan Shan', label: 'Tsz Wan Shan' },
            { value: 'Wang Tau Hom', label: 'Wang Tau Hom' },
            { value: 'Wong Tai Sin', label: 'Wong Tai Sin' }
        ],
        'Yau Tsim Mong District': [
            { value: 'Jordan', label: 'Jordan' },
            { value: 'Mong Kok', label: 'Mong Kok' },
            { value: 'Tsim Sha Tsui', label: 'Tsim Sha Tsui' },
            { value: 'Yau Ma Tei', label: 'Yau Ma Tei' }
        ],
        'Islands District': [
            { value: 'Chek Lap Kok Lantau Island', label: 'Chek Lap Kok Lantau Island' },
            { value: 'Cheung Chau', label: 'Cheung Chau' },
            { value: 'Cheung Sha Lantau Island', label: 'Cheung Sha Lantau Island' },
            { value: 'Chi Ma Wan Lantau Island', label: 'Chi Ma Wan Lantau Island' },
            { value: 'Discovery Bay Lantau Island', label: 'Discovery Bay Lantau Island' },
            { value: 'Hei Ling Chau', label: 'Hei Ling Chau' },
            { value: 'Lamma Island', label: 'Lamma Island' },
            { value: 'Lantau Island', label: 'Lantau Island' },
            { value: 'Mui Wo Lantau Island', label: 'Mui Wo Lantau Island' },
            { value: 'Ngong Ping Lantau Island', label: 'Ngong Ping Lantau Island' },
            { value: 'Peng Chau', label: 'Peng Chau' },
            { value: 'Shek Pik Lantau Island', label: 'Shek Pik Lantau Island' },
            { value: 'Tai O Lantau Island', label: 'Tai O Lantau Island' },
            { value: 'Tong Fuk Lantau Island', label: 'Tong Fuk Lantau Island' },
            { value: 'Tung Chung Lantau Island', label: 'Tung Chung Lantau Island' }
        ],
        'Kwai Tsing District': [
            { value: 'Kwai Chung', label: 'Kwai Chung' },
            { value: 'Tsing Yi', label: 'Tsing Yi' }
        ],
        'North District': [
            { value: 'Fanling', label: 'Fanling' },
            { value: 'Kwan Tei Fanling', label: 'Kwan Tei Fanling' },
            { value: 'Kwu Tung', label: 'Kwu Tung' },
            { value: 'Ping Che', label: 'Ping Che' },
            { value: 'Sha Tau Kok', label: 'Sha Tau Kok' },
            { value: 'Sheung Shui', label: 'Sheung Shui' },
            { value: 'Ta Kwu Ling', label: 'Ta Kwu Ling' }
        ],
        'Sai Kung District': [
            { value: 'Clear Water Bay', label: 'Clear Water Bay' },
            { value: 'Hang Hau Sai Kung', label: 'Hang Hau Sai Kung' },
            { value: 'Sai Kung', label: 'Sai Kung' },
            { value: 'Tseung Kwan O', label: 'Tseung Kwan O' }
        ],
        'Sha Tin District': [
            { value: 'Fo Tan Sha Tin', label: 'Fo Tan Sha Tin' },
            { value: 'Kau To Shan', label: 'Kau To Shan' },
            { value: 'Ma Liu Shui', label: 'Ma Liu Shui' },
            { value: 'Ma On Shan', label: 'Ma On Shan' },
            { value: 'Sha Tin', label: 'Sha Tin' },
            { value: 'Siu Lek Yuen Sha Tin', label: 'Siu Lek Yuen Sha Tin' },
            { value: 'Tai Wai Sha Tin', label: 'Tai Wai Sha Tin' }
        ],
        'Tai Po District': [
            { value: 'Lam Tsuen Tai Po', label: 'Lam Tsuen Tai Po' },
            { value: 'Shuen Wan Tai Po', label: 'Shuen Wan Tai Po' },
            { value: 'Tai Po', label: 'Tai Po' },
            { value: 'Tai Po Kau', label: 'Tai Po Kau' },
            { value: 'Tai Wo', label: 'Tai Wo' },
            { value: 'Ting Kok Tai Po', label: 'Ting Kok Tai Po' }
        ],
        'Tsuen Wan District': [
            { value: 'Lo Wai Tsuen Wan', label: 'Lo Wai Tsuen Wan' },
            { value: 'Ma Wan', label: 'Ma Wan' },
            { value: 'Sham Tseng', label: 'Sham Tseng' },
            { value: 'Ting Kau', label: 'Ting Kau' },
            { value: 'Tsuen Wan', label: 'Tsuen Wan' }
        ],
        'Tuen Mun District': [
            { value: 'Fu Tei Tuen Mun', label: 'Fu Tei Tuen Mun' },
            { value: 'Lam Tei Tuen Mun', label: 'Lam Tei Tuen Mun' },
            { value: 'San Hui Tuen Mun', label: 'San Hui Tuen Mun' },
            { value: 'Siu Lam Tuen Mun', label: 'Siu Lam Tuen Mun' },
            { value: 'So Kwun Wat', label: 'So Kwun Wat' },
            { value: 'Tai Lam', label: 'Tai Lam' },
            { value: 'Tuen Mun', label: 'Tuen Mun' }
        ],
        'Yuen Long District': [
            { value: 'Ha Tsuen Yuen Long', label: 'Ha Tsuen Yuen Long' },
            { value: 'Hung Shui Kiu', label: 'Hung Shui Kiu' },
            { value: 'Kam Tin Yuen Long', label: 'Kam Tin Yuen Long' },
            { value: 'Lau Fau Shan', label: 'Lau Fau Shan' },
            { value: 'Pat Heung Yuen Long', label: 'Pat Heung Yuen Long' },
            { value: 'Ping Shan Yuen Long', label: 'Ping Shan Yuen Long' },
            { value: 'San Tin Yuen Long', label: 'San Tin Yuen Long' },
            { value: 'Shek Kong Yuen Long', label: 'Shek Kong Yuen Long' },
            { value: 'Tam Mei Yuen Long', label: 'Tam Mei Yuen Long' },
            { value: 'Tin Shui Wai', label: 'Tin Shui Wai' },
            { value: 'Tai Tong Yuen Long', label: 'Tai Tong Yuen Long' },
            { value: 'Yuen Long', label: 'Yuen Long' }
        ]
    };
    return options[district].map((data) => {
        data.label = translation[`${prefix}.${data.label}`];
        return data;
    });
};

export const getBlockType = (translation: any) =>
    [
        { value: 'Block', label: 'address.Block' },
        { value: 'Tower', label: 'address.Tower' },
        { value: 'House', label: 'address.House' },
        { value: 'Other', label: 'address.Other' }
    ].map((t) => ({
        value: t.value,
        label: translation[t.label]
    }));

export const getUnitType = (translation: any) =>
    [
        { value: 'Flat', label: 'address.Flat' },
        { value: 'Shop', label: 'address.Shop' },
        { value: 'Suite', label: 'address.Suite' },
        { value: 'Room', label: 'address.Room' },
        { value: 'Other', label: 'address.Other' }
    ].map((t) => ({
        value: t.value,
        label: translation[t.label]
    }));

export const loadLocaleData = async (locale: Locale) => {
    let json;
    switch (locale) {
        case 'zh-HK':
            json = await import('utils/locales/zh-HK.json');
            break;
        case 'zh-CN':
            json = await import('utils/locales/zh-CN.json');
            break;
        default:
            json = await import('utils/locales/en.json');
    }
    const { address, districts, regions } = json.default;
    return flatten({
        address,
        districts,
        regions
    });
};
