import { Grid, Stack, InputLabel, TextField, TextareaAutosize, Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useApiClient from 'hooks/useApiClient';
import { useLoading } from 'hooks/useLoading';
import { Key, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { Notice } from 'types/notice-details';
import MainCard from 'ui-component/cards/MainCard';
import MainTable from 'ui-component/tables/MainTable';
import TextLexical from 'ui-component/text-lexical';

const NoticePage = () => {
    const location = useLocation();
    const [notice, setNotice] = useState<any>(null);
    const { id } = (location.state as any) || {};
    const loading = useLoading();
    const navigate = useNavigate();
    const { systemService } = useApiClient();

    const onBack = async () => {
        navigate(-1);
    };

    useEffect(() => {
        if (id) {
            getNoticeById();
        }
    }, []);

    const getNoticeById = () => {
        const loadData = async () => {
            try {
                loading(true);
                const response: Notice = await systemService.getNoticeById(id);
                if (response) {
                    setNotice(response);
                    if (!response.read_date || response.read_date == null) {
                        await systemService.markNoticeRead(id);
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                loading(false);
            }
        };
        loadData();
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={12}>
                    <MainCard title="notice.header" contentSX={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="notice.title" /> :
                                </Typography>
                                <Typography variant="body2">{notice?.notice_title}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="notice.messageContent" /> :
                                </Typography>

                                <Grid sx={{ width: '90%' }}>
                                    <TextLexical toolbarHidden disabled editorState={notice?.notice_content} onChange={() => {}} />
                                </Grid>

                                {/* <Typography variant="body2">{notice?.notice_content}</Typography> */}
                            </Stack>
                            <Stack>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="notice.noticeAttachment" /> :
                                </Typography>

                                {notice?.notice_attachment.map((att: any, index: Key | null | undefined) => (
                                    <div key={index}>
                                        {att.doc_name}
                                        <Button
                                            onClick={async () => {
                                                console.log(att);
                                                if (att.id) {
                                                    const doc = await systemService.getDocumentContentById(att.id);
                                                    const a = document.createElement('a'); // Create <a>
                                                    a.href = `data:image/${doc.doc_extension};base64,${doc.file_content}`; // Image Base64 Goes here
                                                    a.download = `${doc.doc_name}.${doc.doc_extension}`; // File name Here
                                                    a.click(); // Downloaded file
                                                }
                                            }}
                                        >
                                            <FormattedMessage id="download" />
                                        </Button>
                                    </div>
                                ))}
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Button variant="contained" type="submit" color="error" size="large" onClick={onBack}>
                                    <FormattedMessage id="back" />
                                </Button>
                            </Stack>
                        </Stack>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};
export default NoticePage;
