import { FormControl, Select, SelectChangeEvent, MenuItem, FormLabel, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { memo, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useConfig from 'hooks/useConfig';
import { ErrorDisplay } from './util';
import { GlobalVariableContext } from '../../../../contexts/GlobalVariableContext';
import { SelectionValue } from '../../../SelectionValue';

interface DropdownProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
}

interface DropdownOptionType {
    value: any;
    labelKey: {
        [key: string]: string;
    };
}

const Dropdown = memo((props: DropdownProps) => {
    const { name, value = '', metadata, onChange, disabled, variant } = props;
    const { getOptionsByType } = useContext(GlobalVariableContext);
    const { getFieldMeta } = useFormikContext();
    const { locale } = useConfig();

    const onValueChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    return (
        <FormControl>
            <FormLabel id={name}>
                <Typography variant={variant}>
                    <FormattedMessage id={metadata.labelKey} />
                    {metadata.required && (
                        <>
                            <span> </span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                    )}
                </Typography>
            </FormLabel>
            <Select labelId={`${name}-label`} id={name} onChange={onValueChange} value={value} displayEmpty disabled={disabled}>
                <MenuItem value="">
                    <FormattedMessage id="pleaseChoose" />
                </MenuItem>
                {metadata.masterConfigKey &&
                    getOptionsByType(metadata.masterConfigKey)?.map((opt) => (
                        <MenuItem value={opt.dataValue} key={opt.dataValue}>
                            <SelectionValue type={metadata.masterConfigKey} value={opt.dataValue} noEngLocale={metadata.noEngLocale} />
                        </MenuItem>
                    ))}
                {!metadata.masterConfigKey &&
                    metadata.options?.map((opt: DropdownOptionType) => (
                        <MenuItem value={opt.value} key={opt.value}>
                            {opt.labelKey[locale]}
                        </MenuItem>
                    ))}
            </Select>
            {getFieldMeta(name).touched && Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { Dropdown };
