import { AxiosRequestConfig, Method, ResponseType } from 'axios';

export interface ICustomRequestConfig extends AxiosRequestConfig {
    url: string;
    publicApi: boolean;
    pathVariables?: any[];
}

export class CustomRequestConfig implements ICustomRequestConfig {
    constructor(
        public url: string,
        public method: Method = 'GET',
        public pathVariables?: any[],
        public data?: any,
        public params?: any,
        public publicApi: boolean = false,
        public responseType?: ResponseType
    ) {
        this.url = url;
        this.method = method;
        this.pathVariables = pathVariables;
        this.data = data;
        this.params = params;
        this.publicApi = publicApi;
        this.responseType = responseType;
    }
}
