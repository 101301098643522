import moment from 'moment';
import {
    getCertFieldValue,
    sign,
    getPublicCerts,
    getBase64DerPublicCert,
    getPrivateKeyFromP12,
    getPublicCertForPrivateKey,
    checkCertValidity,
    getRdn,
    signWithBytes,
    verify,
    getCertFromBase64Der
} from './crypto';
import { getServerDateTime } from './date';
import HKPostCA from './HKPostCA';
import HKPostTrialCA from './hkPostTrialCA';
import DigiSignCA from './digiSignCA';
import DigiSignTrialCA from './digiSignTrialCA';
import CertAuthority from './certAuthority';

const RCAs = [new HKPostCA(undefined), new DigiSignCA(undefined)];
if (`${window.config.ENV}` !== 'PRD') {
    RCAs.push(new HKPostTrialCA(undefined));
    RCAs.push(new DigiSignTrialCA(undefined));
}

/**
 *
 * @param {*} referenceItems A list of detached file contents to be signed, e.g.
 * [
 *   {
 *     fileName: 'file name relative to the signature file',
 *     content: 'base64 encoded content',
 *    },
 * ];
 * @param {*} systemInfo An object with any system info to be signed also
 * @param {*} p12B64 Base64 encoded keystore with private key
 * @param {*} password Keystore password
 */
const xmlSignDetachedFiles = (
    systemInfo: any,
    hash: string,
    p12B64: any,
    password: any,
    supportConfig: any,
    signWithHashBytes: boolean = false
) =>
    new Promise((resolve, reject) => {
        // Extract public certificates
        let publicCerts: any;
        try {
            publicCerts = getPublicCerts(p12B64, password);
        } catch (err) {
            console.warn(err);
            throw new Error('passwordIncorrect');
        }

        // Get private key
        const privateKey = getPrivateKeyFromP12(p12B64, password);

        // Check private key matches with one of public cert
        const matchedPublicCert = getPublicCertForPrivateKey(privateKey, publicCerts);
        if (matchedPublicCert === null) {
            throw new Error('privatePublicKeysNotMatch');
        }

        getServerDateTime()
            .then((serverDt) => {
                console.log(serverDt);
                try {
                    // Check cert dates
                    checkCertValidity(matchedPublicCert, serverDt);

                    // Check CA, cert type, id
                    const supports = {
                        RCAs,
                        ...supportConfig
                    };
                    CertAuthority.check(privateKey, matchedPublicCert, supports);
                } catch (err) {
                    console.error(err);
                    reject(err);
                }

                const signDt = moment(serverDt).format('YYYY-MM-DD HH:mm:ss');
                // Prepare sign info with client system info and signing info, and add to list of files for signing
                const signInfo = {
                    ...systemInfo,
                    userAgent: navigator.userAgent,
                    method: 'file',
                    signDt,
                    issuer: getCertFieldValue(matchedPublicCert.issuer, 'CN'),
                    sn: matchedPublicCert.serialNumber,
                    subjectCN: getCertFieldValue(matchedPublicCert.subject, 'CN'),
                    subjectEA: getCertFieldValue(matchedPublicCert.subject, 'E'),
                    rdn: getRdn(matchedPublicCert.subject)
                };

                const signature = signWithHashBytes ? signWithBytes(privateKey, hash) : sign(privateKey, hash);
                const certB64Der = getBase64DerPublicCert(matchedPublicCert);

                resolve({
                    cert: certB64Der,
                    timestamp: serverDt.getTime(),
                    signature,
                    commonName: getCertFieldValue(matchedPublicCert.subject, 'CN')
                });
            })
            .catch((err) => {
                console.warn(err);
                reject(err);
            });
    });

const verifySignature = (cert: string, hash: string, signature: string) => verify(getCertFromBase64Der(cert), hash, signature);

export { xmlSignDetachedFiles, verifySignature };
