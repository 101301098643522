// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, Link, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import { useDispatch, useSelector } from 'store';

// assets
import useAuth from '../../../hooks/useAuth';
import { Person } from '@mui/icons-material';
import { openDrawer } from '../../../store/slices/menu';
import { IconMenu2 } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import ContactSection from './ContactSection';
import NewApplicationMenuSection from './NewApplicationMenuSection';
import { useNavigate } from 'react-router-dom';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {isLoggedIn && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                )}
                <Box m={2} pt={3} />

                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* header search */}
            <Grid
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                {!isLoggedIn && (
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: 2, cursor: 'pointer' }}>
                        <NewApplicationMenuSection />
                    </Box>
                )}

                <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: 2, cursor: 'pointer' }}>
                    <ContactSection />
                </Box>

                {/* live customization & localization */}

                <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: 2, cursor: 'pointer' }}>
                    <LocalizationSection />
                </Box>

                {/* profile */}
                {isLoggedIn ? (
                    <ProfileSection />
                ) : (
                    <Button
                        onClick={() => {
                            navigate('/', { replace: true });
                        }}
                        variant="contained"
                    >
                        <Person />
                        <FormattedMessage id="header.login" />
                    </Button>
                )}
                {/* mobile header */}
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <MobileSection />
                </Box>
            </Grid>
        </>
    );
};

export default Header;
