// Port from the following files in EGIS client authentication RM
// authenticator.js
import { getCertExtensionValue } from 'utils/crypto';
import CertAuthority, { CertificateType, IDType } from './certAuthority';

class DigiSignCA extends CertAuthority {
    constructor(issuerCertDerB64: any) {
        super(
            issuerCertDerB64 || [
                // ID-CERT SIGNING CA CERT 2
                'MIIEFzCCAv+gAwIBAgIEWplcZjANBgkqhkiG9w0BAQUFADB8MQswCQYDVQQGEwJISzExMC8GA1UEChMoRElHSS1TSUdOIENFUlRJRklDQVRJT04gU0VSVklDRVMgTElNSVRFRDEZMBcGA1UECxMQQlJOIDMxMzQ2OTUyLTAwMDEfMB0GA1UEAxMWSUQtQ0VSVCBST09UIENBIENFUlQgMjAeFw0xNDA5MTYxMDE2MTRaFw0yNDA5MTIxMDE1NDRaMH8xCzAJBgNVBAYTAkhLMTEwLwYDVQQKEyhESUdJLVNJR04gQ0VSVElGSUNBVElPTiBTRVJWSUNFUyBMSU1JVEVEMRkwFwYDVQQLExBCUk4gMzEzNDY5NTItMDAwMSIwIAYDVQQDExlJRC1DRVJUIFNJR05JTkcgQ0EgQ0VSVCAyMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApJTW1eA38lDdhQuSl0bb6xXdlVOHehMoYjdhnqzZDcHpIv5IV7PH69cJmDiLCpaKZGFU5NUtXJx2cxPhDbHi8XE+BnjFWTNnt1Wo6JUBCJTbrhL1suvzvlIin+LyAPOvZTv4lcbMn9wtnuKNwK42RO9KRgSnOyPp9xOWg5DSXHH4HjdzW8VBdQV7xIP/XLWGCDfbf2gopFKBG6Ig1gTdnWFBuOkocIGoLCBiN0hm8rZmczXnyHooWpKSZ9u4IR7m0noHLgCgO6N9i11jh//WQht0RvGaxjvlMVOmAUKKnbin7wwfZRxRuRNs3fxsAtH2mt6rnLQs66NLDQgPEvKAGwIDAQABo4GdMIGaMBIGA1UdEwEB/wQIMAYBAf8CAQIwNAYDVR0gBC0wKzApBggrBgEEAcFkATAdMBsGCCsGAQUFBwIBFg93d3cuZGctc2lnbi5jb20wDgYDVR0PAQH/BAQDAgH+MB8GA1UdIwQYMBaAFD78whBPsuOpTKDNRrevXerG6iEcMB0GA1UdDgQWBBTPss1Ok5KoFCefijsSgxXZPmuD9zANBgkqhkiG9w0BAQUFAAOCAQEAQQd9pIw/QsxWgpoZtN2B44UkB/jzcTC1E4mXkQvrcZL+H+9PrUZwKtK+h/nbRQROTIlgk7nBgbTbDaMc4uv66S5UoP/tLlGVkOO7bUb0biRhQlVg15GUlQ3tgkPC599bzZAqyxJawJzZxLeM/IJG05amB/CF4blFLAHAuj3BM/mzl/hVRzmEbZp9PhBDs65CXYmRd5k18+F+bvG4arwGLZC0E9ko59evWsmFVfEaZbQKr0p5+XMwYgcBJAw9zzuikBgdJeN4edi7onHtjIiQFD4ksyPTFlpIq04kTOjx2PI0F5WwsLNW8EnUJmV4r844SRCfAochSNKPjoazuM+dhQ==',
                // ID-CERT SIGNING CA CERT 3
                'MIIFmjCCA4KgAwIBAgIEW8qJIDANBgkqhkiG9w0BAQsFADB8MQswCQYDVQQGEwJISzExMC8GA1UEChMoRElHSS1TSUdOIENFUlRJRklDQVRJT04gU0VSVklDRVMgTElNSVRFRDEZMBcGA1UECxMQQlJOIDMxMzQ2OTUyLTAwMDEfMB0GA1UEAxMWSUQtQ0VSVCBST09UIENBIENFUlQgMzAeFw0xODA1MDIwOTMwMzVaFw0zODA1MDIwNjQwMjBaMH8xCzAJBgNVBAYTAkhLMTEwLwYDVQQKEyhESUdJLVNJR04gQ0VSVElGSUNBVElPTiBTRVJWSUNFUyBMSU1JVEVEMRkwFwYDVQQLExBCUk4gMzEzNDY5NTItMDAwMSIwIAYDVQQDExlJRC1DRVJUIFNJR05JTkcgQ0EgQ0VSVCAzMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt01AUOXPANNGFViCcggVl1zr23ivDauJnPMpQWreCtEZuoneZX8d+Ex1GCNWQFy2p7t1RV9UNT2DlVWuYId0aU18gyu+Esrm7iLKG/wn3CXzjfjMn+MY0SjAlz8GnLtURm4NlfCt68Z/Uv5p8HZ5Kv6HvE/0UIPDePLba2PQ1K8l8wllQhnHH0LGnxZzhQ0ZN4blARUkrItMxOfrSWpXMxgxpcNkbZt+qx9wgWxyB34FfCc/PKEvxGjhkhrIVokq6kf2dPGDR6aH3oyimpyB38+66u5yJy83tVIwZ3D887J1ZyZ4IpFwigYWztw2oJ5+QoFX2PyymnSFaKCgifWwMQIDAQABo4IBHzCCARswEgYDVR0TAQH/BAgwBgEB/wIBAjA0BgNVHSAELTArMCkGCCsGAQQBwWQBMB0wGwYIKwYBBQUHAgEWD3d3dy5kZy1zaWduLmNvbTBFBggrBgEFBQcBAQQ5MDcwNQYIKwYBBQUHMAKGKWh0dHA6Ly93d3cuZGctc2lnbi5jb20vY2FjZXJ0L3Jvb3RjYTMuY2VyMA4GA1UdDwEB/wQEAwIB/jAfBgNVHSMEGDAWgBTR6DLImNspfJh67TUq2JyuJY6tIzA4BgNVHR8EMTAvMC2gK6AphidodHRwOi8vd3d3LmRnLXNpZ24uY29tL0NSTDMvUm9vdENhMy5jcmwwHQYDVR0OBBYEFJ/OGmTD/gyqP6B0QeEqy4aM4skYMA0GCSqGSIb3DQEBCwUAA4ICAQBFoYOi9RxmpbaDAPhFnVLCG1Dt06irZb7nXuwxTqsjitANKv65I5PVFJ854skPZQD/sajRIOHeHrOCCLwSUW3qXytkxe0z7yznEuckMVytKOMp+RRb9AqjxD8armZPj2pMZiVKp0U3Jp+AQoOY+ZtAJPpZ3nTCaZUil5vPiFRuHENblOioxulcIwCyluNLsrDxwQunrp8UxJ8a/0CFVyQWxqpR734yDg4CCP+GvmtQeX/UNhXSkiSyV3EzkJGJ3BHtB4zhf/hUlWgnXJxAFo8DshSdW5R45pvIC/BkNBA4dObz87qzEIuKn2VCYVlsoOZBu781zzI3NPvj+MBXQq2Pj9T0N9qr45NVPvljgliohPMAwJVWP21+i/g3Vbj6oPlfaUMjno8E1M5KI9P2VASbkAP/cmwz/TaV0vuTR6OjDEKfn/2baw42AR/vMYgJB+wyzV2sh5JmLKVIpNpHNsBE1vZHSgunIRGvzvlOk+h/AHTB8XJpOBDwN0Xa0EqK86erxIP4F8WWJwbaODcB4YpKGobm1rex2x7vRGvO9Jx1i5f+rBpCWknghNeQh3K0P7UWz+60IKQeuDuaB5uabKEYmJmkdekgxN/qyknKyXKJRVBwZBe0ejUVom5VOf9SdWEfXoauN9FuNWo/udUUYB6yQ2Kc9ZbceMn/h9xWWlnQQw==',
                // ID-CERT SIGNING CA CERT - GOVT
                'MIIEIzCCAwugAwIBAgIEWgDFoDANBgkqhkiG9w0BAQUFADCBgTELMAkGA1UEBhMCSEsxMTAvBgNVBAoTKERJR0ktU0lHTiBDRVJUSUZJQ0FUSU9OIFNFUlZJQ0VTIExJTUlURUQxGTAXBgNVBAsTEEJSTiAzMTM0Njk1Mi0wMDAxJDAiBgNVBAMTG0lELUNFUlQgUk9PVCBDQSBDRVJUIC0gR09WVDAeFw0xMjExMDcwOTMxNTdaFw0zMjExMDcwNzU2MDZaMIGEMQswCQYDVQQGEwJISzExMC8GA1UEChMoRElHSS1TSUdOIENFUlRJRklDQVRJT04gU0VSVklDRVMgTElNSVRFRDEZMBcGA1UECxMQQlJOIDMxMzQ2OTUyLTAwMDEnMCUGA1UEAxMeSUQtQ0VSVCBTSUdOSU5HIENBIENFUlQgLSBHT1ZUMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsIVfgywOXxSvlj+awdj1HOz2dJEjhJ13ypuF9tBID+kefxPfxIbBbifMnNV8Gu6NMb1GMwA0IqU1u0KqZg2rHJfPQI3Tp88gHpCYckXeqVY7Jby42sgQZLpnlTbBTmfT21I7bGzZjBa3VH+yZ8fQrmXD4qujWTY/69jXvfCWaQ3rR3LXzadylXPRcZmaeGfvnXDbvII3srzA6ZqcnU5nCaWY1qbMXfDser6ZjNUuCJnCQaO8xkGcFViyeu0RRHLXzbcmyOFwp70DWk30v7chgJQlHI2mYHIvp4Dj7JxanfwZYwarY6f1zuaRM1ATjBcehm0Etg0Fk4xWZS2CrBl0kQIDAQABo4GdMIGaMBIGA1UdEwEB/wQIMAYBAf8CAQIwNAYDVR0gBC0wKzApBggrBgEEAcFkATAdMBsGCCsGAQUFBwIBFg93d3cuZGctc2lnbi5jb20wDgYDVR0PAQH/BAQDAgH+MB8GA1UdIwQYMBaAFM3A3zPnwl7o5yM6+wZLw+OWnZeYMB0GA1UdDgQWBBSObS2qdW5dIPBqTTgSZ+axdD0ptjANBgkqhkiG9w0BAQUFAAOCAQEAGLIRucrh2akudxMRyHCw2whpbS5gljICGI5VoKeFYW6XdR9PIfRaPdWL23GMXql0bL8ciJEl1nBVw2Jx+dpYtxfyZCIqAWIQnl6jKoifSxnnMVNYVBhxSHAC4CGnrfAi+eLkppWEQcVDaBFsWH39sAncD5FhyNAdwqTpoWrn1vTMVvPJhTTOT/7JAbIxNf43GzoIrWLW7WVTEfhc1OFrNx5Adc+Y7s6jwlRVaoccw1QjnvBhx+FqmojbqUELuGM5Bi29ra+siGEYhFcILEW6HPV2sgcohxKDX2s7kjWPEUh9zBoygDIEq+/SxRizB5ZQOyAxozbUiso5QhwU57GQlg=='
            ]
        );
    }

    getCertificateType = (cert: any) => {
        let certType;
        cert.subject.attributes.forEach((attribute: any) => {
            const { shortName, value } = attribute;
            if (shortName === 'O') {
                if (/^DS ID-CERT CLASS 1/i.test(value)) {
                    certType = CertificateType.PERSONAL;
                } else if (/^DS ID-CERT CLASS 2/i.test(value)) {
                    certType = CertificateType.ORGANIZATIONAL;
                } else if (/^DS ID-CERT CLASS 3/i.test(value)) {
                    certType = CertificateType.ENCIPHERMENT;
                } else if (/^DS ID-CERT CLASS 5/i.test(value)) {
                    certType = CertificateType.ORGANIZATIONAL;
                } else if (/^DS ID-CERT CLASS 6/i.test(value)) {
                    certType = CertificateType.GOVERNMENT;
                } else if (/^DS ID-CERT CLASS 7/i.test(value)) {
                    certType = CertificateType.GOVERNMENT;
                } else if (/^DS ID-CERT CLASS 8/i.test(value)) {
                    certType = CertificateType.PERSONAL;
                } else if (/^DS ID-CERT CLASS 9/i.test(value)) {
                    certType = CertificateType.ORGANIZATIONAL;
                }
            }
        });

        return certType;
    };

    checkId = (privateKey: any, publicCert: any, idType: string, id: { id: any; checkDigit: any; toUpperCase: () => any; brn: any }) => {
        let matched = false;
        const certType = this.getCertificateType(publicCert);
        if (!certType) {
            return matched;
        }
        switch (certType) {
            case CertificateType.PERSONAL: {
                if (idType !== IDType.HKID && idType !== IDType.PASSPORT) {
                    throw new Error('ID_TYPE_NOT_MATCH');
                }

                let idForCheck;
                if (idType === IDType.HKID) {
                    const foreignIDIndicator = getCertExtensionValue(publicCert, { id: '1.3.6.1.4.1.8420.3' });
                    if (foreignIDIndicator !== null && /P/.test(foreignIDIndicator)) {
                        throw new Error('ID_TYPE_NOT_MATCH');
                    }

                    if (id && id.id && id.checkDigit) {
                        idForCheck = `${id.id}(${id.checkDigit})`.toUpperCase();
                    }
                } else if (idType === IDType.PASSPORT) {
                    const foreignIDIndicator = getCertExtensionValue(publicCert, { id: '1.3.6.1.4.1.8420.3' });
                    if (foreignIDIndicator === null || !/P/.test(foreignIDIndicator)) {
                        throw new Error('ID_TYPE_NOT_MATCH');
                    }

                    if (id) {
                        idForCheck = id.toUpperCase();
                    }
                }

                if (idForCheck) {
                    matched = CertAuthority.matchId(privateKey, publicCert, idForCheck);
                } else {
                    matched = true;
                }

                break;
            }
            case CertificateType.ORGANIZATIONAL: {
                if (idType !== IDType.BRN) {
                    throw new Error('ID_TYPE_NOT_MATCH');
                }

                if (id && id.brn) {
                    const brn = id.brn;
                    const re1 = new RegExp(brn, 'i');
                    let re2: RegExp | null = null;

                    if (brn.length === 11) {
                        re2 = new RegExp(`BRN ${brn.substring(0, 8)}-${brn.substring(8, 11)}`);
                    }

                    publicCert.subject.attributes.forEach((attribute: any) => {
                        const { value } = attribute;
                        if (/^OU=/i.test(value)) {
                            if (re1.test(value)) {
                                matched = true;
                            } else if (re2 !== null && re2.test(value)) {
                                matched = true;
                            }
                        }
                    });
                } else {
                    matched = true;
                }

                break;
            }
        }

        return matched;
    };
}

export default DigiSignCA;
