// material-ui
import { Box, Grid } from '@mui/material';

// project imports

import MainTabs, { MainTabsHeader } from 'ui-component/tabs';
import { useLocation, Outlet } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const tabHeaders: MainTabsHeader[] = [
    { label: 'user-management.tab-name.user-profile', route: '/user-management/user-profile' },
    { label: 'user-management.tab-name.account-association', route: '/user-management/account-association' }
];

const UserManagementPage = () => {
    const { pathname } = useLocation();

    let defaultIndex = 0;
    switch (pathname) {
        case '/user-management/account-association':
            defaultIndex = 1;
            break;
        case '/user-management/user-profile':
        default:
            defaultIndex = 0;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={12}>
                    <MainCard title="user-management.header" contentSX={{ p: 2 }}>
                        <MainTabs headers={tabHeaders} defaultIndex={defaultIndex} />
                        <Outlet />
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserManagementPage;
