import React, { createContext, useEffect, useReducer } from 'react';
import { KeycloakContextType, InitialLoginContextProps } from 'types/auth';
import Keycloak from 'keycloak-js';

import { IAMSMART, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import { UserDetail } from '../types/user/user-detail';

declare global {
    interface Window {
        grecaptcha: any;
    }
}

const config = {
    url: `${window.config.AUTH_SERVICE_DOMAIN}/auth`,
    realm: `${window.config.KEYCLOAK_REALM}`,
    clientId: `${window.config.KEYCLOAK_CLIENT_ID}`
    // redirectUri: window.location.protocol+"//"+window.location.host+"/"
};

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    iamsmart: false
};

const AuthContext = createContext<KeycloakContextType | null>(null);
const keycloak = new Keycloak(config);
// create the context provider, we are using use state to ensure that
// we get reactive values from the context...
export const KeycloakProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const queryString0 = window.location.search;
    const urlParams0 = new URLSearchParams(queryString0);
    const consentId0 = urlParams0.get('c');
    console.log(`consentId = ${consentId0}`);
    if (!consentId0) {
        console.log('setting cookie');
        const date = new Date();
        date.setTime(date.getTime() + 60 * 1000);
        document.cookie = `nonFirstEntry=1; expires=${date}`;
    }
    const eraseCookie = (name: string) => {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    };
    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            const p = parts.pop();
            if (p) {
                return p.split(';').shift();
            }
        }
        return null;
    };

    useEffect(
        () => {
            keycloak
                .init({ checkLoginIframe: false, useNonce: false, timeSkew: 0 })
                .then((authenticated) => {
                    console.info(`keyclock init logined:${authenticated}`);
                    console.log('authenticated tokens', keycloak.idToken);
                    if (keycloak.idToken) {
                        eraseCookie('xx_id_token');
                        document.cookie = `xx_id_token=${keycloak.idToken};`;
                    }

                    // window.alert(authenticated);
                    if (authenticated && keycloak.token) {
                        const user = keycloak.tokenParsed?.other_info ? convertKeycloakInfoToUserInfo() : new UserDetail(keycloak.token);
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user
                            }
                        });
                        setIamsmart(user?.userProfile?.iamsmart ?? false);
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                })
                .catch((err) => {
                    console.info(`keyclock init error:${JSON.stringify(err)}`, err);
                    // window.alert(err);
                    // TODO show error dialog and redirect to root
                });
            let markDirty = false;
            window.document.addEventListener(
                'mousedown',
                () => {
                    markDirty = true;
                },
                true
            );

            window.document.addEventListener(
                'mousemove',
                () => {
                    markDirty = true;
                },
                true
            );
            setInterval(() => {
                // if (keycloak.authenticated) {
                console.log('isTokenExpired=', keycloak.isTokenExpired(0));
                if (markDirty) {
                    refreshToken();
                    markDirty = false;
                } else if (keycloak.isTokenExpired(0)) {
                    logout();
                }
                // }
            }, 60000);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const refreshToken = async () => {
        try {
            await keycloak.updateToken(-1);
            if (keycloak.idToken) {
                eraseCookie('xx_id_token');
                document.cookie = `xx_id_token=${keycloak.idToken};`;
            }
            if (keycloak.token) {
                const user = keycloak.tokenParsed?.other_info ? convertKeycloakInfoToUserInfo() : new UserDetail(keycloak.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });
            }
        } catch (e) {
            console.error('token update failed', e);
            if (keycloak.isTokenExpired(0)) {
                logout();
            }
        }
    };

    const convertKeycloakInfoToUserInfo = () => {
        const token = keycloak.token;
        const { other_info: otherInfo } = keycloak.tokenParsed || { other_info: undefined };
        if (otherInfo && token) {
            const { user_role, user_profile, acl, home } = otherInfo;

            const { functions, menu } = acl || { functions: [] };

            const userProfile = {
                loginId: user_profile?.login_id,
                userId: user_profile?.user_id,
                mobile: user_profile?.mobile,
                email: user_profile?.email,
                userNameEn: user_profile?.user_name_en,
                userNameChi: user_profile?.user_name_chi,
                iamsmart: user_profile?.iamsmart ?? false
            };

            const functionControls = {} as { [key: string]: any };

            functions.forEach((f: { [key: string]: any }) => {
                const key = Object.keys(f)[0];

                functionControls[key] = f[key];
            });

            const menuControls = menu;

            const userHome = { homeId: home?.home_id, homeType: home?.home_type };

            const userRole = {
                userRoleId: user_role?.user_role_id,
                userRoleNameEn: user_role?.user_role_name_en,
                userRoleNameTc: user_role?.user_role_name_tc,
                userRoleNameSc: user_role?.user_role_name_sc,
                managedUserRoles: user_role?.managed_user_roles
            };

            return new UserDetail(token, userProfile, functionControls, menuControls, userHome, userRole);
        }

        return null;
    };

    const logout = async () => {
        let redirectUri = `${window.location.protocol}//${window.location.host}/`;
        if (getCookie('xx_id_token')) {
            redirectUri = `${redirectUri}?xx_id_token=${getCookie('xx_id_token')}`;
        }
        const logoutOptions = {
            redirectUri
        };
        await keycloak.logout(logoutOptions);
        dispatch({
            type: LOGOUT
        });
    };

    const getOtp = (username: String, email: String) => {
        let resolve = (v: any) => {};
        let reject = (v: any) => {};
        const promise = new Promise((resolve0, reject0) => {
            resolve = resolve0;
            reject = reject0;
        });

        fetch(`${window.config.AUTH_SERVICE_DOMAIN}/api/genAndSendOTP`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                Accept: 'application/json'
            },
            // redirect: 'manual',
            /**
             * nr: means no redirect
             * c: consent id, if apply, no cookie will check in server
             * s: login state
             * o: otp
             * */
            body: `username=${username}&email=${email}`
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const body = await response.json();
                    resolve(body);
                    // if (body && body.redirectUrl) {
                    //     window.location.href = body.redirectUrl;
                    // }
                    // return body;
                } else {
                    const body = await response.json();
                    console.log(`login non 200 response:${body.errorObject}`);
                    reject({ message: body.errorObject.errMsg });
                    // window.alert(body.errorObject.errMsg);
                }
            })
            .catch(async (err) => {
                console.error(`login fetch error:${JSON.stringify(err)}`);
            });
        return promise;
    };

    const emailPasswordSignIn = async (email: string, password: string, linkup?: boolean) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const consentId = urlParams.get('c') ?? '';
        let resolve = (v: any) => {};
        let reject = (v: any) => {};
        const promise = new Promise((resolve0, reject0) => {
            resolve = resolve0;
            reject = reject0;
        });
        const url = `${window.config.AUTH_SERVICE_DOMAIN}/api/${linkup ? 'linkup' : 'login'}`;

        const fetch1 = (rt: String) => {
            fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                // redirect: 'manual',
                /**
                 * nr: means no redirect
                 * c: consent id, if apply, no cookie will check in server
                 * */
                body: `username=${email}&password=${encodeURIComponent(password)}&c=${consentId}&nr=true&rt=${rt}`
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        const body = await response.json();
                        resolve(body);
                        // if (body && body.redirectUrl) {
                        //     window.location.href = body.redirectUrl;
                        // }
                        // return body;
                    } else {
                        const body = await response.json();
                        console.log(`login non 200 response:${body.errorObject}`);
                        reject({ message: body.errorObject.errMsg, errCodes: body.errorObject.errCodes });
                        // window.alert(body.errorObject.errMsg);
                    }
                })
                .catch(async (err) => {
                    console.error(`login fetch error:${JSON.stringify(err)}`);
                });
        };
        if (window.config.DISABLE_RECAP) {
            fetch1('');
        } else {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window.config.GOOGLE_RECAP_SIT_KEY, { action: 'submit' }).then((rt: String) => {
                    // Add your logic to submit to your backend server here.
                    fetch1(rt);
                });
            });
        }
        return promise;
    };

    const googleAuthenOtp = async (otp: string, s: string, linkup?: boolean) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const consentId = urlParams.get('c') ?? '';
        let resolve = (v: any) => {};
        let reject = (v: any) => {};
        const promise = new Promise((resolve0, reject0) => {
            resolve = resolve0;
            reject = reject0;
        });
        const url = `${window.config.AUTH_SERVICE_DOMAIN}/api/${linkup ? 'linkup2' : 'login2'}`;
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            // redirect: 'manual',
            /**
             * nr: means no redirect
             * c: consent id, if apply, no cookie will check in server
             * s: login state
             * o: otp
             * */
            body: `o=${otp}&s=${s}&c=${consentId}&nr=true`
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const body = await response.json();
                    resolve(body);
                    // if (body && body.redirectUrl) {
                    //     window.location.href = body.redirectUrl;
                    // }
                    // return body;
                } else {
                    const body = await response.json();
                    console.log(`login non 200 response:${body.errorObject}`);
                    reject({ message: body.errorObject.errMsg });
                    // window.alert(body.errorObject.errMsg);
                }
            })
            .catch(async (err) => {
                console.error(`login fetch error:${JSON.stringify(err)}`);
            });
        return promise;
    };

    const switchAccount = (targetUser: string) => {
        if (keycloak.token) {
            const goWin = window.open('about:blank', '_go');
            // const iframe = document.createElement('iframe');
            // iframe.id = '_go';
            // iframe.name = '_go';
            const form = document.createElement('form');
            const hidden1 = document.createElement('input');
            hidden1.type = 'hidden';
            hidden1.value = targetUser;
            hidden1.name = 'target';
            form.appendChild(hidden1);
            const hidden2 = document.createElement('input');
            hidden2.type = 'hidden';
            hidden2.value = keycloak.token;
            hidden2.name = 'token';
            form.appendChild(hidden2);
            form.action = `${window.config.AUTH_SERVICE_DOMAIN}/api/preSwitch`;
            form.method = 'POST';
            form.target = '_go';
            document.body.appendChild(form);
            // document.body.appendChild(iframe);
            form.submit();
            document.body.removeChild(form);
            setTimeout(() => {
                logout();
                goWin?.close();
            }, 3000);
        }
    };

    const setIamsmart = (bool: boolean) => {
        dispatch({
            type: IAMSMART,
            payload: bool
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login: () => {
                    setTimeout(() => {
                        const options = { idpHint: `${window.config.KEYCLOAK_IDP_HINT}`, redirectUri: window.location.origin };
                        keycloak.login(options);
                    }, 1);
                },
                logout,
                keycloak,
                resetPassword: () => {},
                emailPasswordSignIn,
                register: async () => {},
                signIn: async () => {},
                googleAuthenOtp,
                switchAccount,
                getOtp,
                refreshToken,
                setIamsmart
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
