import { FormHelperText } from '@mui/material';
import { FormattedMessage } from 'react-intl';
// import { memo } from 'react';

interface ErrorDisplayProps {
    error: string | undefined;
    name: string;
}

const ErrorDisplay = ({ error, name }: ErrorDisplayProps) => {
    if (!error) {
        return null;
    }

    const errors = error.split(',');
    return errors.map((err) => {
        const params: { [key: string]: any } = {};

        if (err.startsWith('error.length')) {
            // message templete = error.length.{length}
            const length = parseInt(err.substring(13), 10);
            params.length = length;
            err = 'error.length';
        }

        return (
            <FormHelperText error id={name}>
                <FormattedMessage id={err} values={params} />
            </FormHelperText>
        );
    });
};

export { ErrorDisplay };
