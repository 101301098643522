import { useContext } from 'react';

import { ApiClientContext } from '../contexts/ApiClientContext';

// ==============================|| AUTH HOOKS ||============================== //

const useApiClient = () => {
    const context = useContext(ApiClientContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useApiClient;
