import { memo, ChangeEvent, useState, useEffect } from 'react';
import { FormControl, FormLabel, TextareaAutosize, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorDisplay } from './util';

interface TextareaProps {
    name: string;
    value: any;
    metadata: any;
    onChange: (v: any) => void;
    disabled: boolean;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
}

const Textarea = memo((props: TextareaProps) => {
    const { name, value, metadata, onChange, disabled, variant } = props;

    const { getFieldMeta } = useFormikContext();

    const onValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setValue(newValue);
        setChanged(true);
    };

    const [_value, setValue] = useState<any>(value);
    const [changed, setChanged] = useState<boolean>(false);

    const onBlur = (event: React.FocusEvent<any>) => {
        if (changed) {
            onChange(_value);
            setChanged(false);
        }
    };
    // useEffect(() => {
    //     if (!value && metadata.defaultValue) {
    //         setValue(metadata.defaultValue);
    //     }
    // }, []);
    const width = metadata.width ? (100 / 12) * metadata.width : 100;

    return (
        <FormControl fullWidth>
            <FormLabel id={name}>
                <Typography variant={variant}>
                    <FormattedMessage id={metadata.labelKey} />
                    {metadata.required && (
                        <>
                            <span> </span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                    )}
                </Typography>
            </FormLabel>
            <Grid item>
                <TextareaAutosize
                    id={name}
                    name={name}
                    value={_value}
                    onChange={onValueChange}
                    onBlur={onBlur}
                    minRows={metadata.rows}
                    className="MuiOutlinedInput-notchedOutline"
                    disabled={disabled}
                    style={{
                        width: `${width}%`
                    }}
                />
            </Grid>
            {Boolean(getFieldMeta(name).error) && ErrorDisplay({ error: getFieldMeta(name).error, name })}
        </FormControl>
    );
});

export { Textarea };
