import { IS_FIREFOX as IS_FIREFOX_ } from './environment';

export const IS_FIREFOX: boolean = IS_FIREFOX_;

export function calculateZoomLevel(element: Element | null): number {
    if (IS_FIREFOX) {
        return 1;
    }
    let zoom = 1;
    while (element) {
        zoom *= Number(window.getComputedStyle(element).getPropertyValue('zoom'));
        element = element.parentElement;
    }
    return zoom;
}
