import { Box, Grid, Stack, Typography } from '@mui/material';
import useApiClient from 'hooks/useApiClient';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { useLoading } from 'hooks/useLoading';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import MainTabs, { MainTabsHeader } from 'ui-component/tabs';
import UnstructuredFormDisplay from './tabs/UnstructuredFormDisplay';

interface HomeInfo {
    id: number;
    lorch_no: string;
    name_chi: string;
    name_en: string;
    address_en: string;
    address_chi: string;
    office_phone_no: string;
    fax_no: string;
    email: string;
}

const tabHeaders: MainTabsHeader[] = [
    { label: 'home-record.tab-name.ser', route: '/home-records/ser' },
    { label: 'home-record.tab-name.SH', route: '/home-records/sh' }
];

const HomeRecords = () => {
    const loading = useLoading();
    const { applicationService } = useApiClient();
    const { user } = useAuth();
    const { home } = user || {};
    const location = useLocation();
    const { locale } = useConfig();
    const [homeInfo, setHomeInfo] = useState<HomeInfo | null>(null);

    const defaultIndex = useMemo(() => {
        switch (location.pathname) {
            case '/home-records/ser':
                return 0;
            case '/home-records/sh':
                return 1;
            default:
                return 0;
        }
    }, [location.pathname]);

    useEffect(() => {
        const fetchHomeLists = async () => {
            loading(true);
            const response: any = await applicationService.getHomeInfo(home!.homeId);
            if (response) {
                const { id, lorch_no, name_chi, name_en, address_en, address_chi, office_phone_no, fax_no, email } = response;
                setHomeInfo({ id, lorch_no, name_chi, name_en, address_en, address_chi, office_phone_no, fax_no, email });
                loading(false);
            }
        };
        fetchHomeLists();
    }, [user]);

    const RenderContent = () => {
        switch (location.pathname) {
            case '/home-records/ser':
                return <UnstructuredFormDisplay homeId={home!.homeId} appType="SER" />;
            case '/home-records/sh':
                return <UnstructuredFormDisplay homeId={home!.homeId} appType="SH" homeInfo={homeInfo || undefined} />;
            default:
                return <UnstructuredFormDisplay homeId={home!.homeId} appType="SER" />;
        }
    };

    const getHomeName = () => (locale === 'en' ? homeInfo?.name_en : homeInfo?.name_chi);

    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={12}>
                    <MainCard title="home-record.header" contentSX={{ p: 2 }}>
                        {homeInfo && (
                            <Stack spacing={1}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage id="home-record.rch-no" /> :
                                    </Typography>
                                    <Typography variant="body2">{homeInfo && homeInfo.lorch_no}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage id="home-record.home-name" /> :
                                    </Typography>
                                    <Typography variant="body2">{getHomeName()}</Typography>
                                </Stack>
                                <MainTabs headers={tabHeaders} defaultIndex={defaultIndex} state={{ homeId: home!.homeId }} />
                                {/* <Outlet /> */}
                                <RenderContent />
                            </Stack>
                        )}
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomeRecords;
